import HasLabel from './HasLabel';

export interface PackagePrice {
    priceTypeID: number;
    packagePrice?: number;
    packagePriceSpecified: boolean;
    unitPrice?: number;
    unitPriceSpecified: boolean;
    caseCount?: number;
    caseCountSpecified: boolean;
    casePrice?: number;
    casePriceSpecified: boolean;
    beginDate: Date;
    endDate?: Date;
    endDateSpecified: boolean;
    priceChangeReasonID?: number;
    priceChangeReasonIDSpecified: boolean;
    packageSize?: number;
    packageSizeSpecified: boolean;
    ncpdpBillingUnit: string;
}

export interface Identifier {
    identifierType: number;
    identifier: string;
}

export interface PackageType {
    packageIdentifier: Identifier[];
    productIdentifier: Identifier[];
    productNameLong: string;
    packageDescription: string;
    ncpdpScriptForm: string;
    ncpdpExceptionalCount: number;
    ncpdpExceptionalCountSpecified: boolean;
    ncpdpBillingUnit: string;
    replacedByPackageID: number;
    replacedByPackageIDSpecified: boolean;
    outerPackageUnit: string;
    innerPackageQuantity: number;
    innerPackageQuantitySpecified: boolean;
    innerPackageUnit: number;
    packageVersion: PackageVersion[];
    description: string;
    preservativeFree: boolean;
    packageDescriptor: string;
    packageSize: number;
    agsBeersCriteriaItem: any;
    unitDoseType: number;
    packageDelivery: any;
}

export interface DrugItem {
    identifier: number;
    version: number;
    quantity: number;
    units: string;
    versionDescription: string;
    imageID: number[];
    manufacturer: {
        identifier: number;
        name: string;
    };
    routeOfAdministration: {
        identifier: number;
        name: string;
    };
    doseForm: {
        identifier: number;
        name: string;
    };
}

export interface PackageVersion {
    packageVersionNumber: number;
    packageVersionDescription: string;
    packageVersionItems: {
        drugItem: DrugItem[];
        nondrugItem: boolean;
    };
    packageOnMarketDate: Date;
    packageOffMarketDate: Date;
    packageOffMarketDateSpecified: boolean;
    packageLotExpiryDate: Date;
    packageLotExpiryDateSpecified: boolean;
    imageID: number;
}

export interface ProductImageType {
    imageId: number;
    imageType: ProductImageEnum;
}

export enum ProductImageEnum {
    Package,
    DrugItem,
    NonDrugItem
}

export interface GSDDPatientAllergy {
    patientId: number,
    allergyType: AllergyType,
    allergyId: number,
    allergyName: string

}

enum AllergyType {
    Class = 0,
    Ingredient = 1
}

export class GSDDPatientAllergy extends HasLabel {
    toString(): string {
        if (this.allergyName) {
            if (this.allergyType == 0)
            {
                return (this.allergyName + " (C)")
            }
            if (this.allergyType == 1) {
                return (this.allergyName + " (I)")
            }
            return this.allergyName
        }
        return ""
    }

}
