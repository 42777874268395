import HasID from "./HasID";
import HasAddress from "./HasAddress";
import HasLabel from "./HasLabel";
import TextUtils from "@/util/TextUtils";

export interface Plan extends HasID, HasAddress {
    bin: string;
    pcn: string;
    vendorId: string;
    providerType: number;
    name: string;
    phoneNumber: string;
    active: boolean;
    offlineCopay?: number | null;
    flags: BillingFlags;
}

export enum BillingFlags {
    //None = 0, // Commented the None option out as it wont ever be explicitly selected, and this way it doesn't show on UI
    PatientResidenceCode = 1 << 0, // 0001 -- the bitshift is unnecessary, but done for consistency
    PharmacyServiceType = 1 << 1,
    UseMailNPI = 1 << 2,
    AllowMultipleFills = 1 << 3,
}

export class Plan extends HasLabel {
    constructor (plan?: Plan) {
        super();

        if (plan) {
            Object.assign(this, plan);
        }
        if (plan?.active == null || plan?.active == undefined) this.active = true;
    }
    toString(): string {
        if (this.name)
            return `${this.name || ''}, BIN: ${this.bin || ''}, PCN: ${this.pcn || ''}`;
        //return `${this.name || ''}, BIN: ${this.bin  || ''}, PCN: ${this.pcn || ''} ${ this.offlineCopay != null ? '(Offline)': ''}`;
        else return '';
    }

    get selectedFlags(): string[] {
        const ret: string[] = [];
        if (this.flags) {
            Plan.flagOptions.forEach((o:any) => {
                if (this.flags & (BillingFlags[o] as any)) ret.push(o);
            })
        }
        return ret;
    }

    set selectedFlags(vals: string[]) {
        this.flags = 0;
        vals.forEach((flag:any) => {
            const flagVal: any = BillingFlags[flag];
            this.flags |= flagVal;
        });
    }

    get selectedFlagsEnums():Array<BillingFlags> {
        return this.selectedFlags?.map<BillingFlags>((f:any) => (BillingFlags[f] as any));
    }

    get doesAllowsMultifill():boolean {
        return this.selectedFlagsEnums?.some(f => f == BillingFlags.AllowMultipleFills);
    }

    static get flagOptions():Array<string>{
        return Object.keys(BillingFlags)
        .filter(val => !isNaN(Number(val)))
        .map(k => BillingFlags[Number(k)]);
    }

    get isOffline():boolean {
        return !isNaN(((this.offlineCopay === null ? undefined : this.offlineCopay) as any));
    }

    get providerTypeDesc():string {
        return Plan.ProviderTypeToString(this.providerType);
    }

    public static ProviderTypeToString(num:Plan.ProviderType):string{
        let res = Plan.ProviderType[num];
        res = TextUtils.camelCaseToNormal(res);

        return res;
    }
}

export namespace Plan {
    export enum ProviderType {
        Unknown, Medicare, Medicaid, Commercial
    }
}
