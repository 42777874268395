
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { Person } from '@/models/Person'
    import { Patient } from '@/models/Patient'
    import { Vendor } from '@/models/Vendor'
    import { Prescriber } from '@/models/Prescriber'
    import SearchComponent from '@/components/SearchComponent.vue';

    @Component({
        name: "NameSearchComponent",
        components:{
            SearchComponent
        }
    })
    export default class NameSearchComponent extends Vue {
        
        // This allows NameSearchComponent to use v-model
        // which allows passing the v-model for the input field
        // Up to whoever wants to consume this component
        @Prop() private value!: string;
        @Prop() private namedPersons!: Person[];
        @Prop() private namedPerson!: Person | null;

        @Prop() private optionLabel!: string;
        @Prop() private disableFields!: boolean;
        @Prop({ default: false }) private searchMode!: boolean;

        @Prop({ default: true }) private showTitle: boolean | undefined;
        @Prop({ default: true }) private showSuffix: boolean | undefined;
        
        private strUrl = '';
        created(){
            if (this.namedPerson instanceof Patient)
                this.strUrl = "/Patient"
            else if (this.namedPerson instanceof Prescriber)
                this.strUrl = "/Prescriber"
            else if (this.namedPerson instanceof Vendor)
                this.strUrl = "/Vendor"
        }

        get personName(): string {
            return this.value;
        }
        set personName(val: string) {
            this.$emit('input', val);
        }

        get selectedPerson(): Person | null {
            return this.namedPerson;
        }

        set selectedPerson(value: Person | null) {
            this.$emit('personUpdate', value);
            this.$emit('personsUpdate', []);
        }

        get nameColumnClass(): string {
            let numberOfColumns = 6;
            if (!this.showTitle || !this.showSuffix) numberOfColumns += 1;
            return `col-${numberOfColumns}`;
        }

        get hasName(){
            return !!(this.namedPerson?.firstName && this.namedPerson.lastName);
        }
    }

