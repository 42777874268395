
    // import { Navbar, NavbarToggleButton } from "src/components";
    //@ts-ignore
    import { ZoomCenterTransition } from "vue2-transitions";
    import ContentFooter from "@/pages/Dashboard/Layout/ContentFooter.vue";
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";

    @Component({
        name: "AuthLayout",
        components: {
            ContentFooter,
            ZoomCenterTransition
        }
    })
    export default class AuthLayout extends Vue {
        @Prop({type:String, default:"black"}) private backgroundColor!: string;
        private menuTransitionDuration = 250;
        private pageTransitionDuration = 200;
        private year = new Date().getFullYear();
        private pageClass = "login-page";

        @Watch("$route")
        routeUpdate() {
            this.setPageClass();
        }

        setPageClass() {
            this.pageClass = `${this.$route.name}-page`.toLowerCase();
        }
    }
