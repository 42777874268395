export interface HasDropDownOption {
    toDropDownOption() : DropDownOption;
}

export class DropDownOption{
    value?:any;
    text?:string;
    constructor(value:any, text?:string){
        this.value = value;
        this.text = text;
    }
}