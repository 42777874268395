<template>
    <div class="wrapper"
         :class="{ 'nav-open': $sidebar.showSidebar }">
        <notifications />
        <side-bar>
            <template #links>
                <!--<user-menu />-->

                <sidebar-item :link="{ name: 'Dashboard', icon: 'now-ui-icons design_app', path: '/dashboard' }" />

                <sidebar-item :link="{ name: 'Customer Service', icon: 'fas fa-headset' }">
                    <sidebar-item :link="{ name: 'Patients', path: '/cphub/patient-dashboard' }" />
                    <sidebar-item :link="{ name: 'Doctors', path: '/cphub/doctor-dashboard' }" />
                    <sidebar-item :link="{ name: 'Drugs', path: '/cphub/drug-dashboard' }" />
                    <sidebar-item :link="{ name: 'Users', path: '/cphub/users' }" />
                    <!--<sidebar-item :link="{ name: 'Opened Cases', path: '/maintenance/case/opened' }" />
                    <sidebar-item :link="{ name: 'Resolved Cases', path: '/maintenance/case/resolved' }" />
                    <sidebar-item :link="{ name: 'Consultation', path: '/maintenance/consultation' }" />  -->
                </sidebar-item>
<!--
                <sidebar-item :link="{ name: 'Processing', icon: 'fas fa-file-prescription' }">
                    <sidebar-item :link="{ name: 'Rx Intake Queue', path: '/cphub/ingestion' }" />
                    <sidebar-item :link="{ name: 'On Hold Queue', path: '/cphub/on-hold-queue' }" />
                    <sidebar-item :link="{ name: 'Print Queue', path: '/cphub/print-queue' }" />
                    <sidebar-item :link="{ name: 'Verification Queue', path: '/cphub/verification-queue' }" />
                    <sidebar-item :link="{ name: 'Patient Insurance', path: '/cphub/patient-insurance-queue'}" />
                    <sidebar-item :link="{ name: 'Patient Allergy Updates', path: '/cphub/patient-web-allergy-update-queue'}" />
                    <sidebar-item :link="{ name: 'Online Order Queue', path: '/cphub/online-order-queue' }" />
                    <sidebar-item :link="{ name: 'Rx Transfers', path: '/cphub/RxTransferQueue'}" />
                </sidebar-item>
                <sidebar-item :link="{ name: 'Errors', icon: 'fas fa-exclamation-triangle' }">
                    <sidebar-item :link="{ name: 'Technician', path: '/cphub/dispense-errors' }" />
                    <sidebar-item :link="{ name: 'Pharmacist', path: '/cphub/pending-errors' }" />
                </sidebar-item>
                <sidebar-item :link="{ name: 'Services', icon: 'fas fa-concierge-bell' }">
                    <sidebar-item :link="{ name: 'Prior Authorization List', path: '/cphub/prior-authorizations' }" />
                    <sidebar-item :link="{ name: 'Consignment Queue', path: '/cphub/consignment-queue' }" />
                    <sidebar-item :link="{ name: 'Incomplete Answers Queue', path: '/cphub/incomplete-answers-queue' }" />
                    <sidebar-item :link="{ name: 'Pending Billing Queue', path: '/cphub/pending-billing-prescriptions-queue' }" />
                    <sidebar-item :link="{ name: 'Reverse Queue', path: '/cphub/reversal-queue' }" />
                </sidebar-item>
                <sidebar-item :link="{ name: 'Reports', icon: 'fas fa-chart-bar' }">
                    <sidebar-item :link="{ name: 'History', path: '/reports/history' }" />
                    <sidebar-item :link="{ name: 'Access', path: '/reports/access' }" />
                    <sidebar-item :link="{ name: 'Reports', path: '/reports/reports' }" />
                    <sidebar-item :link="{ name: 'Inventory', path: '/reports/inventory' }" />
                    <sidebar-item :link="{ name: 'Dispensed', path: '/reports/dispensed' }" />
                </sidebar-item>
                <sidebar-item :link="{ name: 'Maintenance', icon: 'fas fa-tools' }">
                    <sidebar-item :link="{ name: 'Patient', path: '/maintenance/patient' }" />
                    <sidebar-item :link="{ name: 'Doctor', path: '/maintenance/doctor' }" />
                    <sidebar-item :link="{ name: 'Vendor', path: '/maintenance/vendor' }" />
                    <sidebar-item :link="{ name: 'Store', path: '/maintenance/store' }" />
                    <sidebar-item :link="{ name: 'Drug', path: '/maintenance/drug' }" />
                </sidebar-item>
                <sidebar-item :link="{ name: 'Admin', icon: 'fas fa-user-cog' }">
                    <sidebar-item :link="{ name: 'Prescription', path: '/cphub/prescription' }" />
                    <sidebar-item :link="{ name: 'Category', path: '/maintenance/category' }" />
                    <sidebar-item :link="{ name: 'Program', path: '/maintenance/program' }" />
                    <sidebar-item :link="{ name: 'OTC Items', path: '/maintenance/otc-item' }" />
                    <sidebar-item :link="{ name: 'Plan', path: '/maintenance/plan' }" />
                    <sidebar-item :link="{ name: 'Users', path: '/maintenance/users' }" />
                    <sidebar-item :link="{ name: 'Sig Translations', path: '/maintenance/sig-maintenance' }" />
                </sidebar-item> -->

                <!--<sidebar-item :link="{ name: 'Pages', icon: 'now-ui-icons design_image' }">
        <sidebar-item :link="{ name: 'Pricing', path: '/pricing' }" />
        <sidebar-item :link="{ name: 'Timeline', path: '/pages/timeline' }" />
        <sidebar-item :link="{ name: 'Login', path: '/login' }" />
        <sidebar-item :link="{ name: 'Register', path: '/register' }" />
        <sidebar-item :link="{ name: 'Lock Screen', path: '/lock' }" />
        <sidebar-item :link="{ name: 'User Profile', path: '/pages/user' }" />
    </sidebar-item>

    <sidebar-item :link="{ name: 'Components', icon: 'now-ui-icons education_atom' }">
        <sidebar-item :link="{ name: 'Buttons', path: '/components/buttons' }" />
        <sidebar-item :link="{ name: 'Grid System', path: '/components/grid-system' }" />
        <sidebar-item :link="{ name: 'Panels', path: '/components/panels' }" />
        <sidebar-item :link="{ name: 'Sweet Alert', path: '/components/sweet-alert' }" />
        <sidebar-item :link="{ name: 'Notifications', path: '/components/notifications' }" />
        <sidebar-item :link="{ name: 'Icons', path: '/components/icons' }" />
        <sidebar-item :link="{ name: 'Typography', path: '/components/typography' }" />
    </sidebar-item>

    <sidebar-item :link="{ name: 'Tables', icon: 'now-ui-icons design_bullet-list-67' }">
        <sidebar-item :link="{ name: 'Regular Tables', path: '/table-list/regular' }" />
        <sidebar-item :link="{ name: 'Extended Tables', path: '/table-list/extended' }" />
        <sidebar-item :link="{ name: 'Paginated Tables', path: '/table-list/paginated' }" />
    </sidebar-item>

    <sidebar-item :link="{ name: 'Forms', icon: 'now-ui-icons files_single-copy-04' }">
        <sidebar-item :link="{ name: 'Regular Forms', path: '/forms/regular' }" />
        <sidebar-item :link="{ name: 'Extended Forms', path: '/forms/extended' }" />
        <sidebar-item :link="{ name: 'Validation Forms', path: '/forms/validation' }" />
        <sidebar-item :link="{ name: 'Wizard', path: '/forms/wizard' }" />
    </sidebar-item>

    <sidebar-item :link="{ name: 'Widgets', icon: 'now-ui-icons objects_diamond', path: '/widgets' }" />
    <sidebar-item :link="{ name: 'Charts', icon: 'now-ui-icons business_chart-pie-36', path: '/charts' }" />
    <sidebar-item :link="{ name: 'Calendar', icon: 'now-ui-icons media-1_album', path: '/calendar' }" />-->
            
            </template>
        </side-bar>
        <div class="main-panel">
            <router-view name="header" />

            <div :class="{ content: !$route.meta.hideContent }"
                 @click="toggleSidebar">
                <zoom-center-transition :duration="200"
                                        mode="out-in">
                    <!-- your content here -->
                    <router-view />
                </zoom-center-transition>
            </div>
            <content-footer v-if="!$route.meta.hideFooter" />
        </div>
    </div>
</template>
<script>
    /* eslint-disable no-new */
    import PerfectScrollbar from "perfect-scrollbar";
    import "perfect-scrollbar/css/perfect-scrollbar.css";

    function hasElement(className) {
        return document.getElementsByClassName(className).length > 0;
    }

    function initScrollbar(className) {
        if (hasElement(className)) {
            new PerfectScrollbar(`.${className}`);
        } else {
            // try to init it later in case this component is loaded async
            setTimeout(() => {
                initScrollbar(className);
            }, 100);
        }
    }

    import TopNavbar from "./TopNavbar.vue";
    import ContentFooter from "./ContentFooter.vue";
    //import UserMenu from "./Extra/UserMenu.vue";
    import { ZoomCenterTransition } from "vue2-transitions";

    export default {
        components: {
            TopNavbar,
            ContentFooter,
            //UserMenu,
            ZoomCenterTransition
        },
        mounted() {
            const docClasses = document.body.classList;
            const isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                // if we are on windows OS we activate the perfectScrollbar function
                initScrollbar("sidebar");
                initScrollbar("sidebar-wrapper");

                docClasses.add("perfect-scrollbar-on");
            } else {
                docClasses.add("perfect-scrollbar-off");
            }
        },
        methods: {
            toggleSidebar() {
                if (this.$sidebar.showSidebar) {
                    this.$sidebar.displaySidebar(false);
                }
            }
        }
    };
</script>
<style lang="scss">
    $scaleSize: 0.95;
    @keyframes zoomIn95 {
        from    {
            opacity: 0;
            transform: scale3d($scaleSize, $scaleSize, $scaleSize);
        }

        to {
            opacity: 1;
        }
    }

    .main-panel .zoomIn {
        animation-name: zoomIn95;
    }

    @keyframes zoomOut95 {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            transform: scale3d($scaleSize, $scaleSize, $scaleSize);
        }
    }

    .main-panel .zoomOut {
        animation-name: zoomOut95;
    }
</style>
