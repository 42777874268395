import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';
import { Component } from 'vue-property-decorator';

export interface Lock {
    refreshURL: string;
    intervalNumber: NodeJS.Timeout;
    lockedBy: string;
    locked: Date;
}

@Component
export class LockHandler extends Vue {
    created(): void {
        console.log("%c Created LockHandler", "color: purple");
        // do some sort of traffic logging here for ease of bug reporting in future
        window.addEventListener('focus', this.activatedEvent);
        window.addEventListener('blur', this.deactivatedEvent);
    }
    beforeDestroy(): void {
        console.log('%c Destroying LockHandler', 'color: magenta');
        window.removeEventListener('focus', this.activatedEvent);
        window.removeEventListener('blur', this.deactivatedEvent);
        this.clearAllLocks();
    }

    activatedEvent(): void {
        this.hasFocus = true;
    }

    deactivatedEvent(): void {
        this.hasFocus = false;
    }
    private locks: Lock[] = [];
    private hasFocus = true;

    public clearAllLocks(): void {
        this.locks.forEach(lock => {
            this.releaseLockAtURL(lock.refreshURL);
            clearInterval(lock.intervalNumber);
        });
        this.locks = [];

    }

    public addLockWithURL(url: string, refreshInterval: number): Promise<Lock> {
        return this.refreshAtURL(url)
            .then(result => {
                const intervalNumber = setInterval(
                    () => {
                        this.refreshAtURL(url)
                            //.then(result => {
                            //})
                            .catch(error => {
                                if (error.message == `We don't have focus`) {
                                    console.warn(`Window doesn't have focus, not refreshing the lock.`);
                                }
                                if (error.response && error.response.status == 418) {
                                    const lockURL = error.config.url;
                                    console.warn(`Had to release lock at ${lockURL}`);
                                    this.releaseLockAtURL(error.config.url);
                                }
                            });
                    },
                    refreshInterval);
                const lock: Lock = {
                    refreshURL: url,
                    intervalNumber: intervalNumber,
                    lockedBy: result.data.lockedBy,
                    locked: result.data.locked
                };
                this.locks.push(lock);
                return lock;
            })
            .catch(error => {
                throw error;
            });
    }

    async refreshAtURL(url: string): Promise<AxiosResponse<any>> {
        if (!this.hasFocus) {
            throw new Error(`We don't have focus`);
        }
        return axios.post(url, false)
            .then(response => {
                return response;
            })
            .catch(error => {
                throw error;
            });
    }

    releaseLockAtURL(url: string): void {
        this.locks = this.locks.filter((value: Lock) => {
            if (value.refreshURL == url) {
                clearInterval(value.intervalNumber);
            }
            return value.refreshURL != url;
        });

        axios.post(url, true);
    }

}
