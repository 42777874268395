
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import axios from 'axios';
    import { Patient } from '../../models/Patient';
    import { RxTransfer, RxTransferContents, transferStatus } from '../../models/RxTransfer';
    import { DatePicker } from 'element-ui'
    import { Note, EntityType } from '@/models/Note';
    import NotesList from '@/components/Note/NotesList.vue'
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import moment from 'moment'
    import { Prescription } from '../../models/Prescription';

    @Component({
        name: "RxTransferModalComponent",
        components: {
            [DatePicker.name]: DatePicker,
            NotesList
        }
    })

    export default class RxTransferModalComponent extends Vue {
        @Prop() patient!: Patient;
        @Prop() TransferIdDetails!: number;

        private transfer: RxTransfer = { contents: [] as RxTransferContents[], notes: [] as Note[] } as RxTransfer;
        private newDrugName: string = "";
        private newDrugRx: number | null = null;
        private newNote: Note = null as unknown as Note;
        private notes: Note[] = [];

        private fields: any[] = [
            { key: "medicationName", label: "Drug" },
            "rxNumber",
            { key: "remove", label: "" },
        ];

        addToTransferContents() {
            const xfer: RxTransferContents = { medicationName: this.newDrugName, rxNumber: this.newDrugRx } as RxTransferContents;
            this.transfer.contents.push(xfer);
            this.newDrugRx = null;
            this.newDrugName = "";
        }

        created() {
            if (this.TransferIdDetails) {
                this.getExistingTransferDetails()
            }
        }

        getExistingTransferDetails() {
            axios.get(`/RxTransfer/${this.TransferIdDetails}/GetByTransferId`)
                .then(response => {
                    this.transfer = response.data;
                    console.log(this.transfer);
                })
        }

        removeDrugFromTransfer(drug: RxTransferContents) {
            this.transfer.contents = this.transfer.contents.filter(function (el) { return el.medicationName != drug.medicationName })
        }

        get isFormComplete() {

            if (this.transfer.contents.length > 0 && this.transfer.externalPharmacyName && this.transfer.externalPharmacyPhone && this.transfer.inbound != undefined)
                return false;
            else
                return true;
        }

        get editingDisabled() {

            if (this.TransferIdDetails) {
                console.log(this.TransferIdDetails);
                return true;
            }
            else {
                return false;
            }
        }

        saveNote() {
            if (this.newNote) {
                axios.post(`/RxTransfer/${this.TransferIdDetails}/SaveNote/${this.newNote}`).then(response => {
                    console.log("Note Saved")
                    this.getExistingTransferDetails();
                    this.newNote = null as unknown as Note;
                }).catch(error => {
                    console.log(error)
                })
            }
        }

        submitRxTransfer() {
            if (this.newNote) {
                const addedNote: Note = { body: this.newNote } as unknown as Note;
                this.transfer.notes.push(addedNote);
            }
            console.log(typeof (this.transfer.inbound));
            if (this.transfer.inbound as unknown as string === 'true')
                this.transfer.inbound = true;
            else
                this.transfer.inbound = false;

            axios.post(`/RxTransfer/${this.patient.id}/AddToRxTransferQueue`, this.transfer)
                .then(response => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Transfer Created Successfully", NotificationOptions.NotificationTypes.success));
                    this.$router.go(0);
                })
                .catch(() => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Failed To Create Transfer", NotificationOptions.NotificationTypes.danger));
                })
        }

        changeRxTransferStatus(statusId: number, reason: string | null) {
            if (!reason) {
                reason = null;
            }
            axios.post(`/RxTransfer/${this.TransferIdDetails}/${this.transfer.patientId}/ChangeStatus/${statusId}/${reason}`)
            .then(resonse => {
                this.saveNote();
                this.$notification(NotificationOptions.notificationOptionsPreset("Change Saved", NotificationOptions.NotificationTypes.success));
                this.$router.go(0);
            })
            .catch(error => {
                this.$notification(NotificationOptions.notificationOptionsPreset("Failed To Save Change", NotificationOptions.NotificationTypes.danger));
            });
        }

        convertDate(date: string) {
            return moment.utc(date).format("L");
        }

        getRxTransferEnum(status: number) {
            return transferStatus[status];
        }

    }
