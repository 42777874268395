import HasID from './HasID';
import HasAddress from './HasAddress';
import TrackedObject from './TrackedObject';
import HasLabel from './HasLabel';

export interface Store extends HasID, HasAddress, TrackedObject {
    name: string;
    phoneNumber: string;
    faxNumber: string;
    emailAddress: string;
    webAddress: string;
    npiNumber: string;
    mailNPINumber: string;
    pidNumber: string;
    fedIDNumber: string;
    storeNumber: string;
    storeID: string;
    deaNumber: string;
    ncpdp: string;
    allowOutOfState: boolean;
}

export class Store extends HasLabel {

    constructor(obj?: Store) {
        super();
        if (obj) Object.assign(this, obj);
    }

    toString(): string {
        if (this.name)
            return `${this.id} - ${this.name}`;
        else return '';
    }
}
