
    import Vue from 'vue'
    import {Component, Model, Prop, Watch, Mixins, PropSync} from 'vue-property-decorator';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';

    import EnumSelector from '@/components/EnumSelector.vue'

    import { InsuranceUpdateRequest }  from '@/models/InsuranceUpdateRequest'
    import { RelationshipCode }  from '@/models/PatientPlan'
    import { Plan }  from '@/models/Plan'
    
    @Component({
        name: "InsuranceUpdateRequestFormComponent",
        components: {
            EnumSelector
        }
    })
    export default class InsuranceUpdateRequestFormComponent extends Vue {
        @Prop() patientId!:number;

        private request = new InsuranceUpdateRequest();
        private relationshipCodes = RelationshipCode;
        private providerTypes = Plan.ProviderType;
        private history = new Array<InsuranceUpdateRequest>();
        private isLoading = false;
        private isSending = false;
        
        private sortBy: string = 'created';
        private sortDesc: boolean = true;

        private fields: any[] = [
            { key: "planName", label: "Plan Name" },
            { key: "bin", label: "BIN" },
            { key: "pcn", label: "PCN" },
            { key: "groupId", label: "Group ID" },
            { key: "cardholderId", label: "Cardholder ID" },
            { key: "personCode", label: "Person Code" },
            { key: "relationshipDesc", label: "Relationship" },
            { key: "providerTypeDesc", label: "Provider Type" },
            { key: "created", label: "Created" },
            { key: "processed", label: "Processed" }
        ];

        created(){
            this.loadHistory()
        }
        
        loadHistory(){
            if(!this.patientId) return;
            
            this.isLoading = true;
            this.$http.get<Array<InsuranceUpdateRequest>>(`insurance/request-update-history/${this.patientId}`)
            .then(res => {
                if(res.data?.length) this.history = res.data.map(i => new InsuranceUpdateRequest(i));
            })
            .catch(err => {
                console.log("Error while getting insurance update request history.", { err, response: err?.response })
            })
            .finally(() => this.isLoading = false)
        }

        save(){
            this.isSending = true;

            this.request.patientID = this.patientId;
            this.$http.post<InsuranceUpdateRequest>('Insurance', this.request)
            .then(res => {
                if(res.data.id){
                    this.request = new InsuranceUpdateRequest();
                    this.loadHistory();
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Insurance update request"));
                    this.$emit("InsuranceRequestSent");
                }
            })
            .catch(err => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Insurance update request"));
                console.log("Error while posting insurance update request.", { err, response: err?.response })
            })
            .finally(() => this.isSending = false)
        }

        get disableSend():boolean {
            return !(this.request.planName && this.request.bin && this.request.pcn)
        }

        colorCode(row: InsuranceUpdateRequest, type: any){
            if (!row || type !== 'row')
                return;
            if (row.processed)
                return 'table-info';
        }

        processedHoverMsg(row: InsuranceUpdateRequest){
            if(!row?.processedBy) return '';

            const res = `Processed by ${row?.processedBy}`;
            return res;
        }
    }
