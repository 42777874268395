
import { Component, Vue } from "vue-property-decorator";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { BvTableFieldArray } from "bootstrap-vue";
import { CognitoUser } from "@/models/CognitoUser";

@Component({
    name: "UserManagementPage"
})
export default class UserManagementPage extends Vue {
    protected users: CognitoUser[] = [];
    protected addedUser: any = null;
    protected updatedUser: any = null;

    get fields(): BvTableFieldArray {
        return [
            "email",
            { key: "group", class: this.showGroups ? "" : "d-none" },
            "roles",
            "zones",
        ];
    }

    mounted() {
        this.getUserList();
    }

    get groups(): string[] {
        let groups = this.users.map(u => u.group).filter((value, index, array) => array.indexOf(value) === index);
        console.log("groups: ", groups);
        return groups;
    }

    get showGroups(): boolean {
        return this.groups.length > 1;
    }

    async getUserList() {
        try {
            let usersResponse = await this.axios.get<CognitoUser[]>("Users/List");
            console.log("usersResponse: ", usersResponse);
            this.users = usersResponse.data;
        } catch (err) {
            console.warn("Err: ", err);
            this.$notification(NotificationOptions.error(err));
        }
    }

    clearUpdateUser() {
        this.updatedUser = null;
    }

    clearAddUser() {
        this.addedUser = null;
    }

    updateUser(item: any) {
        this.updatedUser = item[0];
    }

    addUser() {
        this.addedUser = {
            name: "",
            email: "",
            roles: "",
            zones: "",
            group: "",
        };
    }

    submitUpdate() {
        this.axios.post("Users/Update", this.updatedUser)
            .then(resp => {
                console.log("updateResp: ", resp);
                this.getUserList().finally(() => {
                    this.updatedUser = null
                });
            })
            .catch(err => {
                console.warn("err: ", err);
                if (err?.message == "Request failed with status code 403") {
                    this.$notification(NotificationOptions.error("You are not authorized to do that."));
                } else {
                    this.$notification(NotificationOptions.error(err));
                }

            });
    }

    submitAdd() {
        this.axios.post("Users/Create", this.addedUser)
            .then(resp => {
                console.log("resp: ", resp);
                this.getUserList().finally(() => {
                    this.addedUser = null
                });
            })
            .catch(err => {
                console.warn("err: ", err);
                if (err?.message == "Request failed with status code 403") {
                    this.$notification(NotificationOptions.error("You are not authorized to do that."));
                } else {
                    this.$notification(NotificationOptions.error(err));
                }
            });
    }
}
