
    import HasAddress from '@/models/HasAddress';
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component({
        name: "AddressFormFields",
    })
    export default class AddressFormFields extends Vue {
        get shouldDisable(): boolean {
            return this.disableFields;
        }

        @Prop() private disableFields!: boolean;
        @Prop() private value!: HasAddress;
        get address(): HasAddress {
            return this.value;
        }
        set address(val: HasAddress) {
            console.log("setting address");
            console.log(val);
            this.$emit('input', val);
        }

    }

