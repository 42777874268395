import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);
const router = new VueRouter({
    routes,
    linkActiveClass: "active",
    mode: "history",
});
router.beforeResolve((to,from,next)=>{
    if (!to.matched.some(record=>record.meta.requiresAuth)) return next();
    
    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
        .then(next)
        .catch(()=>{
        next({path:"/login", query: {redirect:to.fullPath}});
    });
});

export default router;
