
    import { Consultation } from '../../models/Consultation';
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import CompleteConsultationComponent from './CompleteConsultationComponent.vue';
    import { Table, TableColumn } from 'element-ui';
    import axios from 'axios';
    import { Store } from "@/models/Store";
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';

    @Component({
        name: "ConsultationListComponent",
        components: {
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            CompleteConsultationComponent
        }
    })

    export default class ConsultationListComponent extends Vue {
        @Prop() private patientId!: number;
        @Prop() private store!: Store;
        @Prop() private enableCancel!: boolean;
        @Prop() private enableEdit!: boolean;
        @Prop({default: false}) private patientNameAsLink!: boolean;

        private consultations: Consultation[] = [];
        private consultation: Consultation = new Consultation();

        private readonly consultationFields = [
            "storeName",
            "patientName",
            "dateOfBirth",
            "requested",
            "statusName",
            "note",
            { key: "buttons", label: "" },
        ];

        created() {
            this.getConsultation();
        }
        
        detailsConsultation(consultation: Consultation, cancel: boolean = false) {
            this.consultation = consultation;
            if (cancel) {
                this.consultation.status = 3;
                this.updateConsultation();
            }
        }

        updateConsultation() {
            if (this.enableEdit || this.enableCancel) {
                axios.post<Consultation>('/Consultation/', this.consultation)
                    .then(response => {
                        if(response.data.id){
                            this.$notification(NotificationOptions.successSaveNotificationPreset("Consultation"));
                            this.consultations.splice(this.consultations.findIndex(c => c.id == this.consultation.id), 1, response.data);
                            this.$bvModal.hide('complete-consultation');
                        }
                    })
                    .catch(error => {
                        this.$notification(NotificationOptions.errorSaveNotificationPreset("Consultation"));
                        console.log("Error while saving consultation", {error, response: error?.response});
                    });
            }
        }

        addConsultation(item: Consultation) {
            this.consultations.push(item);
        }

        @Watch('patientId')
        patIdUpdate() {
            this.getConsultation();
        }

        @Watch('store')
        storeUpdate() {
            this.getConsultation();
        }


        getConsultation() {
            let request = '/Consultation/';
            if (this.patientId != 0)
                request += this.patientId;
                
            if (this.store && this.store.id)
                request += '/' + this.store.id;

            axios.get(request)
                .then(response => {
                    this.consultations = response.data;
                })
                .catch(error => {
                    console.log("Error while loading consultations", {error, response: error?.response});
                });
        }
    }
