export namespace ClaimRejects {

    export const codes = [
        { 'Code': '01', 'Value': 'M/I IIN Number' },
        { 'Code': '02', 'Value': 'M/I Version/Release Number' },
        { 'Code': '03', 'Value': 'M/I Transaction Code' },
        { 'Code': '04', 'Value': 'M/I Processor Control Number' },
        { 'Code': '05', 'Value': 'M/I Service Provider Number' },
        { 'Code': '06', 'Value': 'M/I Group ID' },
        { 'Code': '07', 'Value': 'M/I Cardholder ID ' },
        { 'Code': '08', 'Value': 'M/I Person Code' },
        { 'Code': '09', 'Value': 'M/I Date Of Birth' },
        { 'Code': '10', 'Value': 'M/I Patient Gender Code' },
        { 'Code': '11', 'Value': 'M/I Patient Relationship Code' },
        { 'Code': '12', 'Value': 'M/I Place of Service' },
        { 'Code': '13', 'Value': 'M/I Other Coverage Code' },
        { 'Code': '14', 'Value': 'M/I Eligibility Clarification Code' },
        { 'Code': '15', 'Value': 'M/I Date of Service' },
        { 'Code': '16', 'Value': 'M/I Prescription/Service Reference Number' },
        { 'Code': '17', 'Value': 'M/I Fill Number' },
        { 'Code': '19', 'Value': 'M/I Days Supply' },
        { 'Code': '1C', 'Value': 'M/I Smoker/Non-Smoker Code' },
        { 'Code': '1K', 'Value': 'M/I Patient Country Code' },
        { 'Code': '1R', 'Value': 'Version/Release Value Not Supported' },
        { 'Code': '1S', 'Value': 'Transaction Code/Type Value Not Supported' },
        { 'Code': '1T', 'Value': 'PCN Must Contain Processor/Payer Assigned Value' },
        { 'Code': '1U', 'Value': 'Transaction Count Does Not Match Number of Transactions' },
        { 'Code': '1V', 'Value': 'Multiple Transactions Not Supported' },
        { 'Code': '1Y', 'Value': 'Claim Segment Required For Adjudication' },
        { 'Code': '1Z', 'Value': 'Clinical Segment Required For Adjudication' },
        { 'Code': '2A', 'Value': 'M/I Medigap ID' },
        { 'Code': '2B', 'Value': 'M/I Medicaid Indicator' },
        { 'Code': '2C', 'Value': 'M/I Pregnancy Indicator' },
        { 'Code': '2D', 'Value': 'M/I Provider Accept Assignment Indicator' },
        { 'Code': '2E', 'Value': 'M/I Primary Care Provider ID Qualifier' },
        { 'Code': '2G', 'Value': 'M/I Compound Ingredient Modifier Code Count' },
        { 'Code': '2H', 'Value': 'M/I Compound Ingredient Modifier Code' },
        { 'Code': '2J', 'Value': 'M/I Prescriber First Name' },
        { 'Code': '2K', 'Value': 'M/I Prescriber Street Address' },
        { 'Code': '2M', 'Value': 'M/I Prescriber City Address' },
        { 'Code': '2N', 'Value': 'M/I Prescriber State/Province Address' },
        { 'Code': '2P', 'Value': 'M/I Prescriber Zip/Postal Zone' },
        { 'Code': '2Q', 'Value': 'M/I Additional Documentation Type ID' },
        { 'Code': '2R', 'Value': 'M/I Length Of Need' },
        { 'Code': '2S', 'Value': 'M/I Length Of Need Qualifier' },
        { 'Code': '2T', 'Value': 'M/I Prescriber/Supplier Date Signed' },
        { 'Code': '2U', 'Value': 'M/I Request Status' },
        { 'Code': '2V', 'Value': 'M/I Request Period Begin Date' },
        { 'Code': '2W', 'Value': 'M/I Request Period Recert/Revised Date' },
        { 'Code': '2X', 'Value': 'M/I Supporting Documentation' },
        { 'Code': '2Z', 'Value': 'M/I Question Number/Letter Count ' },
        { 'Code': '20', 'Value': 'M/I Compound Code' },
        { 'Code': '21', 'Value': 'M/I Product/Service ID' },
        { 'Code': '22', 'Value': 'M/I Dispense As Written (DAW)/Product Selection Code ' },
        { 'Code': '23', 'Value': 'M/I Ingredient Cost Submitted' },
        { 'Code': '25', 'Value': 'M/I Prescriber ID' },
        { 'Code': '26', 'Value': 'M/I Unit Of Measure' },
        { 'Code': '27', 'Value': 'Product Identifier Not FDA/NSDE Listed' },
        { 'Code': '28', 'Value': 'M/I Date Prescription Written' },
        { 'Code': '29', 'Value': 'M/I Number Of Refills Authorized' },
        { 'Code': '201', 'Value': 'Patient Segment Is Not Used For This Transaction Code' },
        { 'Code': '202', 'Value': 'Insurance Segment Is Not Used For This Transaction Code' },
        { 'Code': '203', 'Value': 'Claim Segment Is Not Used For This Transaction Code' },
        { 'Code': '204', 'Value': 'Pharmacy Provider Segment Is Not Used For This Transaction Code' },
        { 'Code': '205', 'Value': 'Prescriber Segment Is Not Used For This Transaction Code' },
        { 'Code': '206', 'Value': 'Coordination Of Benefits/Other Payments Segment Is Not Used For This Transaction Code' },
        { 'Code': '207', 'Value': 'Workers Compensation Segment Is Not Used For This Transaction Code' },
        { 'Code': '208', 'Value': 'DUR/PPS Segment Is Not Used For This Transaction Code' },
        { 'Code': '209', 'Value': 'Pricing Segment Is Not Used For This Transaction Code' },
        { 'Code': '211', 'Value': 'Compound Segment Is Not Used For This Transaction Code' },
        { 'Code': '212', 'Value': 'Prior Authorization Segment Is Not Used For This Transaction Code' },
        { 'Code': '213', 'Value': 'Clinical Segment Is Not Used For This Transaction Code' },
        { 'Code': '214', 'Value': 'Additional Documentation Segment Is Not Used For This Transaction Code' },
        { 'Code': '215', 'Value': 'Facility Segment Is Not Used For This Transaction Code' },
        { 'Code': '216', 'Value': 'Narrative Segment Is Not Used For This Transaction Code' },
        { 'Code': '217', 'Value': 'Purchaser Segment Is Not Used For This Transaction Code' },
        { 'Code': '218', 'Value': 'Service Provider Segment Is Not Used For This Transaction Code' },
        { 'Code': '219', 'Value': 'Patient ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '220', 'Value': 'Patient ID Is Not Used For This Transaction Code' },
        { 'Code': '221', 'Value': 'Date Of Birth Is Not Used For This Transaction Code' },
        { 'Code': '222', 'Value': 'Patient Gender Code Is Not Used For This Transaction Code' },
        { 'Code': '223', 'Value': 'Patient First Name Is Not Used For This Transaction Code' },
        { 'Code': '224', 'Value': 'Patient Last Name Is Not Used For This Transaction Code' },
        { 'Code': '225', 'Value': 'Patient Street Address Is Not Used For This Transaction Code' },
        { 'Code': '226', 'Value': 'Patient City Address Is Not Used For This Transaction Code' },
        { 'Code': '227', 'Value': 'Patient State/Province Address Is Not Used For This Transaction Code' },
        { 'Code': '228', 'Value': 'Patient ZIP/Postal Zone Is Not Used For This Transaction Code' },
        { 'Code': '229', 'Value': 'Patient Phone Number Is Not Used For This Transaction Code' },
        { 'Code': '230', 'Value': 'Place Of Service Is Not Used For This Transaction Code' },
        { 'Code': '231', 'Value': 'Employer ID Is Not Used For This Transaction Code' },
        { 'Code': '232', 'Value': 'Smoker/Non-Smoker Code Is Not Used For This Transaction Code' },
        { 'Code': '233', 'Value': 'Pregnancy Indicator Is Not Used For This Transaction Code' },
        { 'Code': '234', 'Value': 'Patient E-Mail Address Is Not Used For This Transaction Code' },
        { 'Code': '235', 'Value': 'Patient Residence Is Not Used For This Transaction Code' },
        { 'Code': '236', 'Value': 'Patient ID Associated State/Province Address Is Not Used For This Transaction Code' },
        { 'Code': '237', 'Value': 'Cardholder First Name Is Not Used For This Transaction Code' },
        { 'Code': '238', 'Value': 'Cardholder Last Name Is Not Used For This Transaction Code' },
        { 'Code': '239', 'Value': 'Home Plan Is Not Used For This Transaction Code' },
        { 'Code': '240', 'Value': 'Plan ID Is Not Used For This Transaction Code' },
        { 'Code': '241', 'Value': 'Eligibility Clarification Code Is Not Used For This Transaction Code' },
        { 'Code': '242', 'Value': 'Group ID Is Not Used For This Transaction Code' },
        { 'Code': '243', 'Value': 'Person Code Is Not Used For This Transaction Code' },
        { 'Code': '244', 'Value': 'Patient Relationship Code Is Not Used For This Transaction Code' },
        { 'Code': '246', 'Value': 'Other Payer Processor Control Number Is Not Used For This Transaction Code' },
        { 'Code': '247', 'Value': 'Other Payer Cardholder ID Is Not Used For This Transaction Code' },
        { 'Code': '248', 'Value': 'Other Payer Group ID Is Not Used For This Transaction Code' },
        { 'Code': '249', 'Value': 'Medigap ID Is Not Used For This Transaction Code' },
        { 'Code': '250', 'Value': 'Medicaid Indicator Is Not Used For This Transaction Code' },
        { 'Code': '251', 'Value': 'Provider Accept Assignment Indicator Is Not Used For This Transaction Code' },
        { 'Code': '252', 'Value': 'CMS Part D Defined Qualified Facility Is Not Used For This Transaction Code' },
        { 'Code': '253', 'Value': 'Medicaid ID Number Is Not Used For This Transaction Code' },
        { 'Code': '254', 'Value': 'Medicaid Agency Number Is Not Used For This Transaction Code' },
        { 'Code': '255', 'Value': 'Associated Prescription/Service Reference Number Is Not Used For This Transaction Code' },
        { 'Code': '256', 'Value': 'Associated Prescription/Service Date Is Not Used For This Transaction Code' },
        { 'Code': '257', 'Value': 'Procedure Modifier Code Count Is Not Used For This Transaction Code' },
        { 'Code': '258', 'Value': 'Procedure Modifier Code Is Not Used For This Transaction Code' },
        { 'Code': '259', 'Value': 'Quantity Dispensed Is Not Used For This Transaction Code' },
        { 'Code': '260', 'Value': 'Fill Number Is Not Used For This Transaction Code' },
        { 'Code': '261', 'Value': 'Days Supply Is Not Used For This Transaction Code' },
        { 'Code': '262', 'Value': 'Compound Code Is Not Used For This Transaction Code' },
        { 'Code': '263', 'Value': 'Dispense As Written(DAW)/Product Selection Code Is Not Used For This Transaction Code' },
        { 'Code': '264', 'Value': 'Date Prescription Written Is Not Used For This Transaction Code' },
        { 'Code': '265', 'Value': 'Number Of Refills Authorized Is Not Used For This Transaction Code' },
        { 'Code': '266', 'Value': 'Prescription Origin Code Is Not Used For This Transaction Code' },
        { 'Code': '267', 'Value': 'Submission Clarification Code Count Is Not Used For This Transaction Code' },
        { 'Code': '268', 'Value': 'Submission Clarification Code Is Not Used For This Transaction Code' },
        { 'Code': '269', 'Value': 'Quantity Prescribed Is Not Used For This Transaction Code' },
        { 'Code': '270', 'Value': 'Other Coverage Code Is Not Used For This Transaction Code' },
        { 'Code': '271', 'Value': 'Special Packaging Indicator Is Not Used For This Transaction Code' },
        { 'Code': '272', 'Value': 'Originally Prescribed Product/Service ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '273', 'Value': 'Originally Prescribed Product/Service Code Is Not Used For This Transaction Code' },
        { 'Code': '274', 'Value': 'Originally Prescribed Quantity Is Not Used For This Transaction Code' },
        { 'Code': '275', 'Value': 'Alternate ID Is Not Used For This Transaction Code' },
        { 'Code': '276', 'Value': 'Scheduled Prescription ID Number Is Not Used For This Transaction Code' },
        { 'Code': '277', 'Value': 'Unit Of Measure Is Not Used For This Transaction Code' },
        { 'Code': '278', 'Value': 'Level Of Service Is Not Used For This Transaction Code' },
        { 'Code': '279', 'Value': 'Prior Authorization Type Code Is Not Used For This Transaction Code' },
        { 'Code': '280', 'Value': 'Prior Authorization ID Submitted Is Not Used For This Transaction Code' },
        { 'Code': '283', 'Value': 'Dispensing Status Is Not Used For This Transaction Code' },
        { 'Code': '284', 'Value': 'Quantity Intended To Be Dispensed Is Not Used For This Transaction Code' },
        { 'Code': '285', 'Value': 'Days Supply Intended To Be Dispensed Is Not Used For This Transaction Code' },
        { 'Code': '286', 'Value': 'Delay Reason Code Is Not Used For This Transaction Code' },
        { 'Code': '287', 'Value': 'Transaction Reference Number Is Not Used For This Transaction Code' },
        { 'Code': '288', 'Value': 'Patient Assignment Indicator (Direct Member Reimbursement Indicator) Is Not Used For This Transaction Code' },
        { 'Code': '289', 'Value': 'Route of Administration Is Not Used For This Transaction Code' },
        { 'Code': '290', 'Value': 'Compound Type Is Not Used For This Transaction Code' },
        { 'Code': '291', 'Value': 'Medicaid Subrogation Internal Control Number/Transaction Control Number (ICN/TCN) Is Not Used For This Transaction Code' },
        { 'Code': '292', 'Value': 'Pharmacy Service Type Is Not Used For This Transaction Code' },
        { 'Code': '293', 'Value': 'Associated Prescription/Service Provider ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '294', 'Value': 'Associated Prescription/Service Provider ID Is Not Used For This Transaction Code' },
        { 'Code': '295', 'Value': 'Associated Prescription/Service Reference Number Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '296', 'Value': 'Associated Prescription/Service Reference Fill Number Is Not Used For This Transaction Code' },
        { 'Code': '297', 'Value': 'Time of Service Is Not Used For This Transaction Code' },
        { 'Code': '298', 'Value': 'Sales Transaction ID Is Not Used For This Transaction Code' },
        { 'Code': '299', 'Value': 'Reported Adjudicated Program Type Is Not Used For This Transaction Code' },
        { 'Code': '3A', 'Value': 'M/I Request Type ' },
        { 'Code': '3B', 'Value': 'M/I Request Period Date-Begin' },
        { 'Code': '3C', 'Value': 'M/I Request Period Date-End' },
        { 'Code': '3D', 'Value': 'M/I Basis Of Request' },
        { 'Code': '3E', 'Value': 'M/I Authorized Representative First Name' },
        { 'Code': '3F', 'Value': 'M/I Authorized Representative Last Name' },
        { 'Code': '3G', 'Value': 'M/I Authorized Representative Street Address' },
        { 'Code': '3H', 'Value': 'M/I Authorized Representative City Address' },
        { 'Code': '3J', 'Value': 'M/I Authorized Representative State/Province Address' },
        { 'Code': '3K', 'Value': 'M/I Authorized Representative Zip/Postal Zone' },
        { 'Code': '3M', 'Value': 'M/I Prescriber Phone Number' },
        { 'Code': '3N', 'Value': 'M/I Prior Authorization ID Assigned' },
        { 'Code': '3P', 'Value': 'M/I Authorization Number' },
        { 'Code': '3Q', 'Value': 'M/I Facility Name' },
        { 'Code': '3R', 'Value': 'Prior Authorization Not Required' },
        { 'Code': '3S', 'Value': 'M/I Prior Authorization Supporting Documentation' },
        { 'Code': '3T', 'Value': 'Active Prior Authorization Exists Resubmit At Expiration Of Prior Authorization' },
        { 'Code': '3U', 'Value': 'M/I Facility Street Address' },
        { 'Code': '3V', 'Value': 'M/I Facility State/Province Address' },
        { 'Code': '3W', 'Value': 'Prior Authorization In Process' },
        { 'Code': '3X', 'Value': 'Authorization Number Not Found' },
        { 'Code': '3Y', 'Value': 'Prior Authorization Denied' },
        { 'Code': '30', 'Value': 'Reversal Request Outside Processor Reversal Window' },
        { 'Code': '31', 'Value': 'No Matching Paid Claim Found For Reversal Request' },
        { 'Code': '32', 'Value': 'M/I Level Of Service' },
        { 'Code': '33', 'Value': 'M/I Prescription Origin Code' },
        { 'Code': '34', 'Value': 'M/I Submission Clarification Code' },
        { 'Code': '35', 'Value': 'M/I Primary Care Provider ID' },
        { 'Code': '39', 'Value': 'M/I Diagnosis Code' },
        { 'Code': '300', 'Value': 'Provider ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '301', 'Value': 'Provider ID Is Not Used For This Transaction Code' },
        { 'Code': '302', 'Value': 'Prescriber ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '303', 'Value': 'Prescriber ID Is Not Used For This Transaction Code' },
        { 'Code': '304', 'Value': 'Prescriber ID Associated State/Province Address Is Not Used For This Transaction Code' },
        { 'Code': '305', 'Value': 'Prescriber Last Name Is Not Used For This Transaction Code' },
        { 'Code': '306', 'Value': 'Prescriber Phone Number Is Not Used For This Transaction Code' },
        { 'Code': '307', 'Value': 'Primary Care Provider ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '308', 'Value': 'Primary Care Provider ID Is Not Used For This Transaction Code' },
        { 'Code': '309', 'Value': 'Primary Care Provider Last Name Is Not Used For This Transaction Code' },
        { 'Code': '310', 'Value': 'Prescriber First Name Is Not Used For This Transaction Code' },
        { 'Code': '311', 'Value': 'Prescriber Street Address Is Not Used For This Transaction Code' },
        { 'Code': '312', 'Value': 'Prescriber City Address Is Not Used For This Transaction Code' },
        { 'Code': '313', 'Value': 'Prescriber State/Province Address Is Not Used For This Transaction Code' },
        { 'Code': '314', 'Value': 'Prescriber ZIP/Postal Zone Is Not Used For This Transaction Code' },
        { 'Code': '315', 'Value': 'Prescriber Alternate ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '316', 'Value': 'Prescriber Alternate ID Is Not Used For This Transaction Code' },
        { 'Code': '317', 'Value': 'Prescriber Alternate ID Associated State/Province Address Is Not Used For This Transaction Code' },
        { 'Code': '318', 'Value': 'Other Payer ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '319', 'Value': 'Other Payer ID Is Not Used For This Transaction Code' },
        { 'Code': '320', 'Value': 'Other Payer Date Is Not Used For This Transaction Code' },
        { 'Code': '321', 'Value': 'Internal Control Number Is Not Used For This Transaction Code' },
        { 'Code': '322', 'Value': 'Other Payer Amount Paid Count Is Not Used For This Transaction Code' },
        { 'Code': '323', 'Value': 'Other Payer Amount Paid Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '324', 'Value': 'Other Payer Amount Paid Is Not Used For This Transaction Code' },
        { 'Code': '325', 'Value': 'Other Payer Reject Count Is Not Used For This Transaction Code' },
        { 'Code': '326', 'Value': 'Other Payer Reject Code Is Not Used For This Transaction Code' },
        { 'Code': '327', 'Value': 'Other Payer-Patient Responsibility Amount Count Is Not Used For This Transaction Code' },
        { 'Code': '328', 'Value': 'Other Payer-Patient Responsibility Amount Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '329', 'Value': 'Other Payer-Patient Responsibility Amount Is Not Used For This Transaction Code' },
        { 'Code': '333', 'Value': 'Employer Name Is Not Used For This Transaction Code' },
        { 'Code': '334', 'Value': 'Employer Street Address Is Not Used For This Transaction Code' },
        { 'Code': '335', 'Value': 'Employer City Address Is Not Used For This Transaction Code' },
        { 'Code': '336', 'Value': 'Employer State/Province Address Is Not Used For This Transaction Code' },
        { 'Code': '337', 'Value': 'Employer ZIP/Postal Code Is Not Used For This Transaction Code' },
        { 'Code': '338', 'Value': 'Employer Phone Number Is Not Used For This Transaction Code' },
        { 'Code': '339', 'Value': 'Employer Contact Name Is Not Used For This Transaction Code' },
        { 'Code': '340', 'Value': 'Carrier ID Is Not Used For This Transaction Code' },
        { 'Code': '341', 'Value': 'Claim/Reference ID Is Not Used For This Transaction Code' },
        { 'Code': '342', 'Value': 'Billing Entity Type Indicator Is Not Used For This Transaction Code' },
        { 'Code': '343', 'Value': 'Pay To Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '344', 'Value': 'Pay To ID Is Not Used For This Transaction Code' },
        { 'Code': '345', 'Value': 'Pay To Name Is Not Used For This Transaction Code' },
        { 'Code': '346', 'Value': 'Pay To Street Address Is Not Used For This Transaction Code' },
        { 'Code': '347', 'Value': 'Pay To City Address Is Not Used For This Transaction Code' },
        { 'Code': '348', 'Value': 'Pay To State/Province Address Is Not Used For This Transaction Code' },
        { 'Code': '349', 'Value': 'Pay To ZIP/Postal Zone Is Not Used For This Transaction Code' },
        { 'Code': '350', 'Value': 'Generic Equivalent Product ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '351', 'Value': 'Generic Equivalent Product ID Is Not Used For This Transaction Code' },
        { 'Code': '352', 'Value': 'DUR/PPS Code Counter Is Not Used For This Transaction Code' },
        { 'Code': '353', 'Value': 'Reason For Service Code Is Not Used For This Transaction Code' },
        { 'Code': '354', 'Value': 'Professional Service Code Is Not Used For This Transaction Code' },
        { 'Code': '355', 'Value': 'Result Of Service Code Is Not Used For This Transaction Code' },
        { 'Code': '356', 'Value': 'DUR/PPS Level Of Effort Is Not Used For This Transaction Code' },
        { 'Code': '357', 'Value': 'DUR Co-Agent ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '358', 'Value': 'DUR Co-Agent ID Is Not Used For This Transaction Code' },
        { 'Code': '359', 'Value': 'Ingredient Cost Submitted Is Not Used For This Transaction Code' },
        { 'Code': '360', 'Value': 'Dispensing Fee Submitted Is Not Used For This Transaction Code' },
        { 'Code': '361', 'Value': 'Professional Service Fee Submitted Is Not Used For This Transaction Code' },
        { 'Code': '362', 'Value': 'Patient Paid Amount Submitted Is Not Used For This Transaction Code' },
        { 'Code': '363', 'Value': 'Incentive Amount Submitted Is Not Used For This Transaction Code' },
        { 'Code': '364', 'Value': 'Other Amount Claimed Submitted Count Is Not Used For This Transaction Code' },
        { 'Code': '365', 'Value': 'Other Amount Claimed Submitted Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '366', 'Value': 'Other Amount Claimed Submitted Is Not Used For This Transaction Code' },
        { 'Code': '367', 'Value': 'Flat Sales Tax Amount Submitted Is Not Used For This Transaction Code' },
        { 'Code': '368', 'Value': 'Percentage Sales Tax Amount Submitted Is Not Used For This Transaction Code' },
        { 'Code': '369', 'Value': 'Percentage Sales Tax Rate Submitted Is Not Used For This Transaction Code' },
        { 'Code': '370', 'Value': 'Percentage Sales Tax Basis Submitted Is Not Used For This Transaction Code' },
        { 'Code': '371', 'Value': 'Usual And Customary Charge Is Not Used For This Transaction Code' },
        { 'Code': '372', 'Value': 'Gross Amount Due Is Not Used For This Transaction Code' },
        { 'Code': '373', 'Value': 'Basis Of Cost Determination Is Not Used For This Transaction Code' },
        { 'Code': '374', 'Value': 'Medicaid Paid Amount Is Not Used For This Transaction Code' },
        { 'Code': '376', 'Value': 'Compound Ingredient Drug Cost Is Not Used For This Transaction Code' },
        { 'Code': '377', 'Value': 'Compound Ingredient Basis Of Cost Determination Is Not Used For This Transaction Code' },
        { 'Code': '378', 'Value': 'Compound Ingredient Modifier Code Count Is Not Used For This Transaction Code' },
        { 'Code': '379', 'Value': 'Compound Ingredient Modifier Code Is Not Used For This Transaction Code' },
        { 'Code': '380', 'Value': 'Authorized Representative First Name Is Not Used For This Transaction Code' },
        { 'Code': '381', 'Value': 'Authorized Rep. Last Name Is Not Used For This Transaction Code' },
        { 'Code': '382', 'Value': 'Authorized Rep. Street Address Is Not Used For This Transaction Code' },
        { 'Code': '383', 'Value': 'Authorized Rep. City Is Not Used For This Transaction Code' },
        { 'Code': '384', 'Value': 'Authorized Rep. State/Province Is Not Used For This Transaction Code' },
        { 'Code': '385', 'Value': 'Authorized Rep. ZIP/Postal Code Is Not Used For This Transaction Code' },
        { 'Code': '386', 'Value': 'Prior Authorization ID Assigned Is Not Used For This Transaction Code' },
        { 'Code': '387', 'Value': 'Authorization Number Is Not Used For This Transaction Code' },
        { 'Code': '388', 'Value': 'Prior Authorization Supporting Documentation Is Not Used For This Transaction Code' },
        { 'Code': '389', 'Value': 'Diagnosis Code Count Is Not Used For This Transaction Code' },
        { 'Code': '390', 'Value': 'Diagnosis Code Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '391', 'Value': 'Diagnosis Code Is Not Used For This Transaction Code' },
        { 'Code': '392', 'Value': 'Clinical Information Counter Is Not Used For This Transaction Code' },
        { 'Code': '393', 'Value': 'Measurement Date Is Not Used For This Transaction Code' },
        { 'Code': '394', 'Value': 'Measurement Time Is Not Used For This Transaction Code' },
        { 'Code': '395', 'Value': 'Measurement Dimension Is Not Used For This Transaction Code' },
        { 'Code': '396', 'Value': 'Measurement Unit Is Not Used For This Transaction Code' },
        { 'Code': '397', 'Value': 'Measurement Value Is Not Used For This Transaction Code' },
        { 'Code': '398', 'Value': 'Request Period Begin Date Is Not Used For This Transaction Code' },
        { 'Code': '399', 'Value': 'Request Period Recert/Revised Date Is Not Used For This Transaction Code' },
        { 'Code': '4B', 'Value': 'M/I Question Number/Letter' },
        { 'Code': '4C', 'Value': 'M/I Coordination Of Benefits/Other Payments Count' },
        { 'Code': '4D', 'Value': 'M/I Question Percent Response' },
        { 'Code': '4E', 'Value': 'M/I Primary Care Provider Last Name' },
        { 'Code': '4G', 'Value': 'M/I Question Date Response' },
        { 'Code': '4H', 'Value': 'M/I Question Dollar Amount Response' },
        { 'Code': '4J', 'Value': 'M/I Question Numeric Response' },
        { 'Code': '4K', 'Value': 'M/I Question Alphanumeric Response' },
        { 'Code': '4M', 'Value': 'Compound Ingredient Modifier Code Count Does Not Match Number Of Repetitions' },
        { 'Code': '4N', 'Value': 'Question Number/Letter Count Does Not Match Number Of Repetitions' },
        { 'Code': '4P', 'Value': 'Question Number/Letter Not Valid For Identified Document ' },
        { 'Code': '4Q', 'Value': 'Question Response Not Appropriate For Question Number/Letter' },
        { 'Code': '4R', 'Value': 'Required Question Number/Letter Response For Indicated Document Missing' },
        { 'Code': '4S', 'Value': 'Compound Product ID Requires A Modifier Code' },
        { 'Code': '4T', 'Value': 'M/I Additional Documentation Segment' },
        { 'Code': '4W', 'Value': 'Must Dispense Through Specialty Pharmacy' },
        { 'Code': '4X', 'Value': 'M/I Patient Residence' },
        { 'Code': '4Y', 'Value': 'Patient Residence Value Not Supported' },
        { 'Code': '4Z', 'Value': 'Place of Service Not Supported By Plan' },
        { 'Code': '40', 'Value': 'Pharmacy Not Contracted With Plan/Processor On Date Of Service' },
        { 'Code': '41', 'Value': 'Submit Bill To Other Processor Or Primary Payer' },
        { 'Code': '42', 'Value': 'Plans Prescriber Data Base Indicates The Prescriber ID Submitted Is Inactive Or Expired' },
        { 'Code': '43', 'Value': 'Plans Prescriber Data Base Indicates The Prescriber DEA Number Is Inactive Or Expired' },
        { 'Code': '44', 'Value': 'Plans Prescriber Data Base Indicates The Prescriber DEA Number Is Not Found' },
        { 'Code': '46', 'Value': 'Plans Prescriber Data Base Indicates The Prescriber DEA Number Does Not Allow This Drug DEA Class' },
        { 'Code': '400', 'Value': 'Request Status Is Not Used For This Transaction Code' },
        { 'Code': '401', 'Value': 'Length Of Need Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '402', 'Value': 'Length Of Need Is Not Used For This Transaction Code' },
        { 'Code': '403', 'Value': 'Prescriber/Supplier Date Signed Is Not Used For This Transaction Code' },
        { 'Code': '404', 'Value': 'Supporting Documentation Is Not Used For This Transaction Code' },
        { 'Code': '405', 'Value': 'Question Number/Letter Count Is Not Used For This Transaction Code' },
        { 'Code': '406', 'Value': 'Question Number/Letter Is Not Used For This Transaction Code' },
        { 'Code': '407', 'Value': 'Question Percent Response Is Not Used For This Transaction Code' },
        { 'Code': '408', 'Value': 'Question Date Response Is Not Used For This Transaction Code' },
        { 'Code': '409', 'Value': 'Question Dollar Amount Response Is Not Used For This Transaction Code' },
        { 'Code': '410', 'Value': 'Question Numeric Response Is Not Used For This Transaction Code' },
        { 'Code': '411', 'Value': 'Question Alphanumeric Response Is Not Used For This Transaction Code' },
        { 'Code': '412', 'Value': 'Facility ID Is Not Used For This Transaction Code' },
        { 'Code': '413', 'Value': 'Facility Name Is Not Used For This Transaction Code' },
        { 'Code': '414', 'Value': 'Facility Street Address Is Not Used For This Transaction Code' },
        { 'Code': '415', 'Value': 'Facility City Address Is Not Used For This Transaction Code' },
        { 'Code': '416', 'Value': 'Facility State/Province Address Is Not Used For This Transaction Code' },
        { 'Code': '417', 'Value': 'Facility ZIP/Postal Zone Is Not Used For This Transaction Code' },
        { 'Code': '418', 'Value': 'Purchaser ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '419', 'Value': 'Purchaser ID Is Not Used For This Transaction Code' },
        { 'Code': '420', 'Value': 'Purchaser ID Associated State Code Is Not Used For This Transaction Code' },
        { 'Code': '421', 'Value': 'Purchaser Date Of Birth Is Not Used For This Transaction Code' },
        { 'Code': '422', 'Value': 'Purchaser Gender Code Is Not Used For This Transaction Code' },
        { 'Code': '423', 'Value': 'Purchaser First Name Is Not Used For This Transaction Code' },
        { 'Code': '424', 'Value': 'Purchaser Last Name Is Not Used For This Transaction Code' },
        { 'Code': '425', 'Value': 'Purchaser Street Address Is Not Used For This Transaction Code' },
        { 'Code': '426', 'Value': 'Purchaser City Address Is Not Used For This Transaction Code' },
        { 'Code': '427', 'Value': 'Purchaser State/Province Address Is Not Used For This Transaction Code' },
        { 'Code': '428', 'Value': 'Purchaser ZIP/Postal Zone Is Not Used For This Transaction Code' },
        { 'Code': '429', 'Value': 'Purchaser Country Code Is Not Used For This Transaction Code' },
        { 'Code': '430', 'Value': 'Purchaser Relationship Code Is Not Used For This Transaction Code' },
        { 'Code': '431', 'Value': 'Released Date Is Not Used For This Transaction Code' },
        { 'Code': '432', 'Value': 'Released Time Is Not Used For This Transaction Code' },
        { 'Code': '433', 'Value': 'Service Provider Name Is Not Used For This Transaction Code' },
        { 'Code': '434', 'Value': 'Service Provider Street Address Is Not Used For This Transaction Code' },
        { 'Code': '435', 'Value': 'Service Provider City Address Is Not Used For This Transaction Code' },
        { 'Code': '436', 'Value': 'Service Provider State/Province Address Is Not Used For This Transaction Code' },
        { 'Code': '437', 'Value': 'Service Provider ZIP/Postal Zone Is Not Used For This Transaction Code' },
        { 'Code': '438', 'Value': 'Seller ID Qualifier Is Not Used For This Transaction Code' },
        { 'Code': '439', 'Value': 'Seller ID Is Not Used For This Transaction Code' },
        { 'Code': '440', 'Value': 'Seller Initials Is Not Used For This Transaction Code' },
        { 'Code': '441', 'Value': 'Other Amount Claimed Submitted Grouping Incorrect' },
        { 'Code': '442', 'Value': 'Other Payer Amount Paid Grouping Incorrect' },
        { 'Code': '443', 'Value': 'Other Payer-Patient Responsibility Amount Grouping Incorrect' },
        { 'Code': '445', 'Value': 'Diagnosis Code Grouping Incorrect' },
        { 'Code': '446', 'Value': 'COB/Other Payments Segment Incorrectly Formatted ' },
        { 'Code': '447', 'Value': 'Additional Documentation Segment Incorrectly Formatted' },
        { 'Code': '448', 'Value': 'Clinical Segment Incorrectly Formatted' },
        { 'Code': '449', 'Value': 'Patient Segment Incorrectly Formatted' },
        { 'Code': '450', 'Value': 'Insurance Segment Incorrectly Formatted' },
        { 'Code': '451', 'Value': 'Transaction Header Segment Incorrectly Formatted' },
        { 'Code': '452', 'Value': 'Claim Segment Incorrectly Formatted' },
        { 'Code': '453', 'Value': 'Pharmacy Provider Segment Incorrectly Formatted' },
        { 'Code': '454', 'Value': 'Prescriber Segment Incorrectly Formatted' },
        { 'Code': '455', 'Value': 'Workers Compensation Segment Incorrectly Formatted' },
        { 'Code': '456', 'Value': 'Pricing Segment Incorrectly Formatted' },
        { 'Code': '458', 'Value': 'Prior Authorization Segment Incorrectly Formatted' },
        { 'Code': '459', 'Value': 'Facility Segment Incorrectly Formatted' },
        { 'Code': '460', 'Value': 'Narrative Segment Incorrectly Formatted' },
        { 'Code': '461', 'Value': 'Purchaser Segment Incorrectly Formatted' },
        { 'Code': '462', 'Value': 'Service Provider Segment Incorrectly Formatted' },
        { 'Code': '463', 'Value': 'Pharmacy Not Contracted In Assisted Living Network' },
        { 'Code': '464', 'Value': 'Service Provider ID Qualifier Does Not Precede Service Provider ID' },
        { 'Code': '465', 'Value': 'Patient ID Qualifier Does Not Precede Patient ID' },
        { 'Code': '466', 'Value': 'Prescription/Service Reference Number Qualifier Does Not Precede Prescription/Service Reference Number' },
        { 'Code': '467', 'Value': 'Product/Service ID Qualifier Does Not Precede Product/Service ID' },
        { 'Code': '468', 'Value': 'Procedure Modifier Code Count Does Not Precede Procedure Modifier Code' },
        { 'Code': '469', 'Value': 'Submission Clarification Code Count Does Not Precede Submission Clarification Code' },
        { 'Code': '470', 'Value': 'Originally Prescribed Product/Service ID Qualifier Does Not Precede Originally Prescribed Product/Service Code' },
        { 'Code': '471', 'Value': 'Other Amount Claimed Submitted Count Does Not Precede Other Amount Claimed Amount And/Or Qualifier' },
        { 'Code': '472', 'Value': 'Other Amount Claimed Submitted Qualifier Does Not Precede Other Amount Claimed Submitted' },
        { 'Code': '473', 'Value': 'Provider ID Qualifier Does Not Precede Provider ID' },
        { 'Code': '474', 'Value': 'Prescriber ID Qualifier  Does Not Precede Prescriber ID' },
        { 'Code': '475', 'Value': 'Primary Care Provider ID Qualifier Does Not Precede Primary Care Provider ID' },
        { 'Code': '476', 'Value': 'Coordination Of Benefits/Other Payments Count Does Not Precede Other Payer Coverage Type' },
        { 'Code': '478', 'Value': 'Other Payer ID Qualifier Does Not Precede Other Payer ID ' },
        { 'Code': '479', 'Value': 'Other Payer Amount Paid Count Does Not Precede Other Payer Amount Paid And/Or Qualifier' },
        { 'Code': '480', 'Value': 'Other Payer Amount Paid Qualifier Does Not Precede Other Payer Amount Paid ' },
        { 'Code': '481', 'Value': 'Other Payer Reject Count Does Not Precede Other Payer Reject Code' },
        { 'Code': '482', 'Value': 'Other Payer-Patient Responsibility Amount Count Does Not Precede Other Payer-Patient Responsibility Amount and/or Qualifier' },
        { 'Code': '483', 'Value': 'Other Payer-Patient Responsibility Amount Qualifier Does Not Precede Other Payer-Patient Responsibility Amount' },
        { 'Code': '486', 'Value': 'Pay To Qualifier Does Not Precede Pay To ID' },
        { 'Code': '487', 'Value': 'Generic Equivalent Product ID Qualifier Does Not Precede Generic Equivalent Product ID' },
        { 'Code': '488', 'Value': 'DUR/PPS Code Counter Does Not Precede DUR Data Fields' },
        { 'Code': '489', 'Value': 'DUR Co-Agent ID Qualifier Does Not Precede DUR Co-Agent ID' },
        { 'Code': '490', 'Value': 'Compound Ingredient Component Count Does Not Precede Compound Product ID And/Or Qualifier' },
        { 'Code': '491', 'Value': 'Compound Product ID Qualifier  Does Not Precede Compound Product ID ' },
        { 'Code': '492', 'Value': 'Compound Ingredient Modifier Code Count Does Not Precede Compound Ingredient Modifier Code' },
        { 'Code': '493', 'Value': 'Diagnosis Code Count Does Not Precede Diagnosis Code And/Or Qualifier' },
        { 'Code': '494', 'Value': 'Diagnosis Code Qualifier Does Not Precede Diagnosis Code' },
        { 'Code': '495', 'Value': 'Clinical Information Counter Does Not Precede Clinical Measurement Data' },
        { 'Code': '496', 'Value': 'Length Of Need Qualifier Does Not Precede Length Of Need' },
        { 'Code': '497', 'Value': 'Question Number/Letter Count Does Not Precede Question Number/Letter' },
        { 'Code': '498', 'Value': 'Accumulator Month Count Does Not Precede Accumulator Month' },
        { 'Code': '5C', 'Value': 'M/I Other Payer Coverage Type' },
        { 'Code': '5E', 'Value': 'M/I Other Payer Reject Count' },
        { 'Code': '5J', 'Value': 'M/I Facility City Address' },
        { 'Code': '50', 'Value': 'Non-Matched Pharmacy Number' },
        { 'Code': '51', 'Value': 'Non-Matched Group ID' },
        { 'Code': '52', 'Value': 'Non-Matched Cardholder ID' },
        { 'Code': '53', 'Value': 'Non-Matched Person Code' },
        { 'Code': '54', 'Value': 'Non-Matched Product/Service ID Number' },
        { 'Code': '55', 'Value': 'Non-Matched Product Package Size' },
        { 'Code': '56', 'Value': 'Non-Matched Prescriber ID' },
        { 'Code': '58', 'Value': 'Non-Matched Primary Prescriber' },
        { 'Code': '500', 'Value': 'Patient ID Count Does Not Precede Patient ID Data Fields' },
        { 'Code': '505', 'Value': 'Other Payer Coverage Type Value Not Supported' },
        { 'Code': '506', 'Value': 'Prescription/Service Reference Number Qualifier Value Not Supported' },
        { 'Code': '507', 'Value': 'Additional Documentation Type ID Value Not Supported' },
        { 'Code': '508', 'Value': 'Authorized Representative State/Province Address Value Not Supported' },
        { 'Code': '509', 'Value': 'Basis Of Request Value Not Supported' },
        { 'Code': '510', 'Value': 'Billing Entity Type Indicator Value Not Supported' },
        { 'Code': '511', 'Value': 'CMS Part D Defined Qualified Facility Value Not Supported' },
        { 'Code': '512', 'Value': 'Compound Code Value Not Supported' },
        { 'Code': '513', 'Value': 'Compound Dispensing Unit Form Indicator Value Not Supported' },
        { 'Code': '514', 'Value': 'Compound Ingredient Basis Of Cost Determination Value Not Supported' },
        { 'Code': '515', 'Value': 'Compound Product ID Qualifier Value Not Supported' },
        { 'Code': '516', 'Value': 'Compound Type Value Not Supported' },
        { 'Code': '518', 'Value': 'DUR Co-Agent ID Qualifier Value Not Supported' },
        { 'Code': '519', 'Value': 'DUR/PPS Level Of Effort Value Not Supported' },
        { 'Code': '520', 'Value': 'Delay Reason Code Value Not Supported' },
        { 'Code': '521', 'Value': 'Diagnosis Code Qualifier Value Not Supported' },
        { 'Code': '522', 'Value': 'Dispensing Status Value Not Supported' },
        { 'Code': '523', 'Value': 'Eligibility Clarification Code Value Not Supported' },
        { 'Code': '524', 'Value': 'Employer State/Province Address Value Not Supported' },
        { 'Code': '525', 'Value': 'Facility State/Province Address Value Not Supported' },
        { 'Code': '528', 'Value': 'Length of Need Qualifier Value Not Supported' },
        { 'Code': '529', 'Value': 'Level Of Service Value Not Supported' },
        { 'Code': '530', 'Value': 'Measurement Dimension Value Not Supported' },
        { 'Code': '531', 'Value': 'Measurement Unit Value Not Supported' },
        { 'Code': '532', 'Value': 'Medicaid Indicator Value Not Supported' },
        { 'Code': '533', 'Value': 'Originally Prescribed Product/Service ID Qualifier Value Not Supported' },
        { 'Code': '534', 'Value': 'Other Amount Claimed Submitted Qualifier Value Not Supported' },
        { 'Code': '535', 'Value': 'Other Coverage Code Value Not Supported' },
        { 'Code': '536', 'Value': 'Other Payer-Patient Responsibility Amount Qualifier Value Not Supported' },
        { 'Code': '537', 'Value': 'Patient Assignment Indicator (Direct Member Reimbursement Indicator) Value Not Supported' },
        { 'Code': '538', 'Value': 'Patient Gender Code Value Not Supported' },
        { 'Code': '539', 'Value': 'Patient State/Province Address Value Not Supported' },
        { 'Code': '540', 'Value': 'Pay to State/Province Address Value Not Supported' },
        { 'Code': '541', 'Value': 'Percentage Sales Tax Basis Submitted Value Not Supported' },
        { 'Code': '542', 'Value': 'Pregnancy Indicator Value Not Supported' },
        { 'Code': '543', 'Value': 'Prescriber ID Qualifier Value Not Supported' },
        { 'Code': '544', 'Value': 'Prescriber State/Province Address Value Not Supported' },
        { 'Code': '545', 'Value': 'Prescription Origin Code Value Not Supported' },
        { 'Code': '546', 'Value': 'Primary Care Provider ID Qualifier Value Not Supported' },
        { 'Code': '547', 'Value': 'Prior Authorization Type Code Value Not Supported' },
        { 'Code': '548', 'Value': 'Provider Accept Assignment Indicator Value Not Supported' },
        { 'Code': '549', 'Value': 'Provider ID Qualifier Value Not Supported' },
        { 'Code': '550', 'Value': 'Request Status Value Not Supported' },
        { 'Code': '551', 'Value': 'Request Type Value Not Supported' },
        { 'Code': '552', 'Value': 'Route of Administration Value Not Supported' },
        { 'Code': '553', 'Value': 'Smoker/Non-Smoker Code Value Not Supported' },
        { 'Code': '554', 'Value': 'Special Packaging Indicator Value Not Supported' },
        { 'Code': '555', 'Value': 'Transaction Count Value Not Supported' },
        { 'Code': '556', 'Value': 'Unit Of Measure Value Not Supported' },
        { 'Code': '557', 'Value': 'COB Segment Present On A Non-COB Claim' },
        { 'Code': '558', 'Value': 'Part D Plan Cannot Coordinate Benefits With Another Part D Plan' },
        { 'Code': '559', 'Value': 'ID Submitted Is Associated With An Excluded Pharmacy' },
        { 'Code': '560', 'Value': 'Pharmacy Not Contracted In Retail Network' },
        { 'Code': '561', 'Value': 'Pharmacy Not Contracted In Mail Order Network' },
        { 'Code': '562', 'Value': 'Pharmacy Not Contracted In Hospice Network' },
        { 'Code': '563', 'Value': 'Pharmacy Not Contracted In Veterans Administration Network' },
        { 'Code': '564', 'Value': 'Pharmacy Not Contracted In Military Network' },
        { 'Code': '565', 'Value': 'Patient Country Code Value Not Supported' },
        { 'Code': '566', 'Value': 'Patient Country Code Not Used For This Transaction' },
        { 'Code': '567', 'Value': 'M/I Veterinary Use Indicator' },
        { 'Code': '568', 'Value': 'Veterinary Use Indicator Value Not Supported' },
        { 'Code': '569', 'Value': 'Provide Notice: Medicare Prescription Drug Coverage And Your Rights' },
        { 'Code': '570', 'Value': 'Veterinary Use Indicator Not Used For This Transaction' },
        { 'Code': '571', 'Value': 'Patient ID Associated State/Province Address Value Not Supported' },
        { 'Code': '572', 'Value': 'Medigap ID Not Covered' },
        { 'Code': '573', 'Value': 'Prescriber Alternate ID Associated State/Province Address Value Not Supported' },
        { 'Code': '574', 'Value': 'Compound Ingredient Modifier Code Not Covered' },
        { 'Code': '575', 'Value': 'Purchaser State/Province Address Value Not Supported' },
        { 'Code': '576', 'Value': 'Service Provider State/Province Address Value Not Supported' },
        { 'Code': '583', 'Value': 'Provider ID Not Covered' },
        { 'Code': '584', 'Value': 'Purchaser ID Associated State/Province Code Value Not Supported' },
        { 'Code': '585', 'Value': 'Fill Number Value Not Supported' },
        { 'Code': '586', 'Value': 'Facility ID Not Covered' },
        { 'Code': '587', 'Value': 'Carrier ID Not Covered' },
        { 'Code': '588', 'Value': 'Alternate ID Not Covered' },
        { 'Code': '589', 'Value': 'Patient ID Not Covered' },
        { 'Code': '590', 'Value': 'Compound Dosage Form Not Covered' },
        { 'Code': '591', 'Value': 'Plan ID Not Covered' },
        { 'Code': '592', 'Value': 'DUR Co-Agent ID Not Covered' },
        { 'Code': '594', 'Value': 'Pay To ID Not Covered' },
        { 'Code': '595', 'Value': 'Associated Prescription/Service Provider ID Not Covered' },
        { 'Code': '596', 'Value': 'Compound Preparation Time Not Used For This Transaction Code' },
        { 'Code': '597', 'Value': 'LTC Dispensing Type Does Not Support The Packaging Type' },
        { 'Code': '598', 'Value': 'More Than One Patient Found' },
        { 'Code': '599', 'Value': 'Cardholder ID Matched But Last Name Did Not' },
        { 'Code': '6C', 'Value': 'M/I Other Payer ID Qualifier' },
        { 'Code': '6D', 'Value': 'M/I Facility ZIP/Postal Zone' },
        { 'Code': '6E', 'Value': 'M/I Other Payer Reject Code' },
        { 'Code': '6G', 'Value': 'Coordination Of Benefits/Other Payments Segment Required For Adjudication' },
        { 'Code': '6J', 'Value': 'Insurance Segment Required For Adjudication' },
        { 'Code': '6K', 'Value': 'Patient Segment Required For Adjudication' },
        { 'Code': '6M', 'Value': 'Pharmacy Provider Segment Required For Adjudication' },
        { 'Code': '6N', 'Value': 'Prescriber Segment Required For Adjudication' },
        { 'Code': '6P', 'Value': 'Pricing Segment Required For Adjudication' },
        { 'Code': '6Q', 'Value': 'Prior Authorization Segment Required For Adjudication' },
        { 'Code': '6R', 'Value': 'Workers Compensation Segment Required For Adjudication' },
        { 'Code': '6S', 'Value': 'Transaction Segment Required For Adjudication' },
        { 'Code': '6T', 'Value': 'Compound Segment Required For Adjudication' },
        { 'Code': '6U', 'Value': 'Compound Segment Incorrectly Formatted' },
        { 'Code': '6V', 'Value': 'Multi-ingredient Compounds Not Supported' },
        { 'Code': '6W', 'Value': 'DUR/PPS Segment Required For Adjudication' },
        { 'Code': '6X', 'Value': 'DUR/PPS Segment Incorrectly Formatted' },
        { 'Code': '6Y', 'Value': 'Not Authorized To Submit Electronically' },
        { 'Code': '6Z', 'Value': 'Provider Not Eligible To Perform Service/Dispense Product' },
        { 'Code': '60', 'Value': 'Product/Service Not Covered For Patient Age' },
        { 'Code': '61', 'Value': 'Product/Service Not Covered For Patient Gender' },
        { 'Code': '62', 'Value': 'Patient/Card Holder ID Name Mismatch' },
        { 'Code': '63', 'Value': 'Product/Service ID Not Covered For Institutionalized Patient' },
        { 'Code': '64', 'Value': 'Claim Submitted Does Not Match Prior Authorization' },
        { 'Code': '65', 'Value': 'Patient Is Not Covered' },
        { 'Code': '66', 'Value': 'Patient Age Exceeds Maximum Age' },
        { 'Code': '67', 'Value': 'Date Of Service Before Coverage Effective' },
        { 'Code': '68', 'Value': 'Date Of Service After Coverage Expired' },
        { 'Code': '69', 'Value': 'Date Of Service After Coverage Terminated' },
        { 'Code': '600', 'Value': 'Coverage Outside Submitted Date Of Service' },
        { 'Code': '602', 'Value': 'Associated Prescription/Service Provider ID Qualifier Does Not Precede Associated Prescription/Service Provider ID' },
        { 'Code': '603', 'Value': 'Prescriber Alternate ID Qualifier Does Not Precede Prescriber Alternate ID' },
        { 'Code': '604', 'Value': 'Purchaser ID Qualifier Does Not Precede Purchaser ID' },
        { 'Code': '605', 'Value': 'Seller ID Qualifier Does Not Precede Seller ID' },
        { 'Code': '606', 'Value': 'Brand Drug/Specific Labeler Code Required ' },
        { 'Code': '607', 'Value': 'Information Reporting (N1/N3) Transaction Cannot Be Matched To A Claim (B1/B3)' },
        { 'Code': '608', 'Value': 'Step Therapy, Alternate Drug Therapy Required Prior To Use Of Submitted Product Service ID' },
        { 'Code': '609', 'Value': 'COB Claim Not Required, Patient Liability Amount Submitted Was Zero' },
        { 'Code': '610', 'Value': 'Information Reporting Transaction (N1/N3) Matched To Reversed Or Rejected Claim Submitted Under Part D IIN PCN' },
        { 'Code': '611', 'Value': 'Information Reporting Transaction (N1/N3) Was Matched To A Claim Submitted Under The Part D IIN/PCN Paid As Enhanced Or OTC Or By A Benefit Other Than Part D' },
        { 'Code': '612', 'Value': 'LTC Appropriate Dispensing Invalid Submission Clarification Code (SCC) Combination ' },
        { 'Code': '613', 'Value': 'The Packaging Methodology  Or Dispensing Frequency Is Missing Or Inappropriate For LTC Short Cycle' },
        { 'Code': '614', 'Value': 'Uppercase Character(s) Required' },
        { 'Code': '615', 'Value': 'Compound Ingredient Basis Of Cost Determination Value 14 Required When Compound Ingredient Quantity Is 0 But Cost Is Greater Than $0' },
        { 'Code': '616', 'Value': 'Submission Clarification Code 8 Required When Compound Ingredient Quantity Is 0' },
        { 'Code': '617', 'Value': 'Compound Ingredient Drug Cost Cannot Be Negative Amount' },
        { 'Code': '619', 'Value': 'Prescriber Type 1 NPI Required' },
        { 'Code': '620', 'Value': 'This Product/Service May Be Covered Under Medicare Part D' },
        { 'Code': '621', 'Value': 'This Medicaid Patient Is Medicare Eligible' },
        { 'Code': '623', 'Value': 'M/I Authorized Representative Country Code' },
        { 'Code': '624', 'Value': 'M/I Employer Country Code' },
        { 'Code': '625', 'Value': 'M/I Entity Country Code' },
        { 'Code': '627', 'Value': 'M/I Facility Country Code' },
        { 'Code': '628', 'Value': 'M/I Patient ID Associated Country Code' },
        { 'Code': '629', 'Value': 'M/I Pay To Country Code' },
        { 'Code': '630', 'Value': 'M/I Prescriber Alternate ID Associated Country Code' },
        { 'Code': '631', 'Value': 'M/I Prescriber ID Associated Country Code' },
        { 'Code': '632', 'Value': 'M/I Prescriber Country Code' },
        { 'Code': '633', 'Value': 'M/I Purchaser ID Associated Country Code' },
        { 'Code': '634', 'Value': 'Authorized Representative Country Code Value Not Supported' },
        { 'Code': '635', 'Value': 'Employer Country Code Value Not Supported' },
        { 'Code': '637', 'Value': 'Entity Country Code Value Not Supported' },
        { 'Code': '638', 'Value': 'Facility Country Code Value Not Supported' },
        { 'Code': '639', 'Value': 'Patient ID Associated Country Code Value Not Supported' },
        { 'Code': '640', 'Value': 'Pay To Country Code Value Not Supported' },
        { 'Code': '641', 'Value': 'Prescriber Alternate ID Associated Country Code Value Not Supported' },
        { 'Code': '642', 'Value': 'Prescriber ID Associated Country Code Value Not Supported' },
        { 'Code': '643', 'Value': 'Prescriber Country Code Value Not Supported' },
        { 'Code': '644', 'Value': 'Purchaser ID Associated Country Code Value Not Supported' },
        { 'Code': '645', 'Value': 'Repackaged Product Is Not Covered By The Contract' },
        { 'Code': '646', 'Value': 'Patient Not Eligible Due To Non Payment Of Premium. Patient To Contact Plan' },
        { 'Code': '648', 'Value': 'Quantity Prescribed Does Not Match Quantity Prescribed On Original Dispensing' },
        { 'Code': '649', 'Value': 'Cumulative Quantity For This Rx Number Exceeds Total Prescribed Quantity' },
        { 'Code': '650', 'Value': 'Date Of Service Greater Than 60 Days From CII Date Prescription Written For LTC/Terminally Ill Patient' },
        { 'Code': '651', 'Value': 'REMS: Mandatory Data Element(s) Missing' },
        { 'Code': '652', 'Value': 'REMS: Prescriber Not Matched Or May Not Be Enrolled' },
        { 'Code': '653', 'Value': 'REMS: Patient Not Matched Or May Not Be Enrolled' },
        { 'Code': '654', 'Value': 'REMS: Pharmacy Not Matched Or May Not Be Enrolled' },
        { 'Code': '655', 'Value': 'REMS: Multiple Patient Matches' },
        { 'Code': '656', 'Value': 'REMS: Patient Age Not Matched' },
        { 'Code': '657', 'Value': 'REMS: Patient Gender Not Matched' },
        { 'Code': '658', 'Value': 'REMS: Pharmacy Has Not Enrolled' },
        { 'Code': '659', 'Value': 'REMS: Pharmacy Has Not Renewed Enrollment' },
        { 'Code': '660', 'Value': 'REMS: Pharmacy Has Not Submitted Agreement Form' },
        { 'Code': '661', 'Value': 'REMS: Pharmacy Has Been Suspended Due To Non-compliance' },
        { 'Code': '662', 'Value': 'REMS: Prescriber Has Not Enrolled' },
        { 'Code': '663', 'Value': 'REMS: Prescriber Has Not Completed A Knowledge Assessment' },
        { 'Code': '664', 'Value': 'REMS: Prescriber Has Been Suspended Due To Non-compliance' },
        { 'Code': '665', 'Value': 'REMS: Excessive Days Supply' },
        { 'Code': '666', 'Value': 'REMS: Insufficient Days Supply' },
        { 'Code': '667', 'Value': 'REMS: Excessive Dosage' },
        { 'Code': '668', 'Value': 'REMS: Insufficient Dosage' },
        { 'Code': '669', 'Value': 'REMS: Additional Fills Not Permitted' },
        { 'Code': '670', 'Value': 'REMS: Laboratory Test Results Not Documented' },
        { 'Code': '671', 'Value': 'REMS: Laboratory Test Not Conducted Within Specified Time Period' },
        { 'Code': '672', 'Value': 'REMS: Dispensing Not Authorized Due To Laboratory Test Results' },
        { 'Code': '673', 'Value': 'REMS: Prescriber Counseling Of Patient Not Documented' },
        { 'Code': '674', 'Value': 'REMS: Prescriber Has Not Documented Safe Use Conditions' },
        { 'Code': '675', 'Value': 'REMS: Prescriber Has Not Documented Patient Opioid Tolerance' },
        { 'Code': '676', 'Value': 'REMS: Prescriber Has Not Documented Patient Contraceptive Use' },
        { 'Code': '677', 'Value': 'REMS: Lack Of Contraindicated Therapy Not Documented' },
        { 'Code': '678', 'Value': 'REMS: Step Therapy Not Documented' },
        { 'Code': '679', 'Value': 'REMS: Prescriber Has Not Enrolled Patient' },
        { 'Code': '680', 'Value': 'REMS: Prescriber Must Renew Patient Enrollment' },
        { 'Code': '681', 'Value': 'REMS: Patient Enrollment Requirements Have Not Been Met' },
        { 'Code': '682', 'Value': 'REMS: Prescriber Has Not Submitted Patient Agreement' },
        { 'Code': '683', 'Value': 'REMS: Prescriber Has Not Verified Patients Reproductive Potential' },
        { 'Code': '684', 'Value': 'REMS: Patient Has Not Documented Safe Use Conditions' },
        { 'Code': '685', 'Value': 'REMS: Patient Has Not Documented Completed Education' },
        { 'Code': '686', 'Value': 'REMS: Patient Has Not Documented Contraceptive Use' },
        { 'Code': '687', 'Value': 'REMS: Administrator Denied' },
        { 'Code': '688', 'Value': 'REMS: Service Billing Denied' },
        { 'Code': '689', 'Value': 'PDMP: Administrator Denied' },
        { 'Code': '690', 'Value': 'PDMP: Pharmacy Not Contracted' },
        { 'Code': '691', 'Value': 'PDMP: Pharmacy Contract Not Renewed' },
        { 'Code': '694', 'Value': 'PDMP: M/I Patient Street Address' },
        { 'Code': '7A', 'Value': 'Provider Does Not Match Authorization On File' },
        { 'Code': '7B', 'Value': 'Service Provider ID Qualifier Value Not Supported For Processor/Payer' },
        { 'Code': '7C', 'Value': 'M/I Other Payer ID' },
        { 'Code': '7D', 'Value': 'Non-Matched DOB' },
        { 'Code': '7E', 'Value': 'M/I DUR/PPS Code Counter' },
        { 'Code': '7G', 'Value': 'Future Date Not Allowed For DOB' },
        { 'Code': '7H', 'Value': 'Non-Matched Gender Code' },
        { 'Code': '7J', 'Value': 'Patient Relationship Code Value Not Supported' },
        { 'Code': '7K', 'Value': 'Discrepancy Between Other Coverage Code And Other Payer Amount ' },
        { 'Code': '7M', 'Value': 'Discrepancy Between Other Coverage Code And Other Coverage Information On File' },
        { 'Code': '7N', 'Value': 'Patient ID Qualifier Value Not Supported' },
        { 'Code': '7P', 'Value': 'Coordination Of Benefits/Other Payments Count Exceeds Number of Supported Payers ' },
        { 'Code': '7Q', 'Value': 'Other Payer ID Qualifier Value Not Supported' },
        { 'Code': '7R', 'Value': 'Other Payer Amount Paid Count Exceeds Number Of Supported Groupings' },
        { 'Code': '7T', 'Value': 'Quantity Intended To Be Dispensed Required For Partial Fill Transaction' },
        { 'Code': '7U', 'Value': 'Days Supply Intended To Be Dispensed Required For Partial Fill Transaction' },
        { 'Code': '7V', 'Value': 'Duplicate Fill Number' },
        { 'Code': '7W', 'Value': 'Number Of Refills Authorized Exceed Allowable Refills' },
        { 'Code': '7X', 'Value': 'Days Supply Exceeds Plan Limitation' },
        { 'Code': '7Y', 'Value': 'Compounds Not Covered' },
        { 'Code': '7Z', 'Value': 'Compound Requires Two Or More Ingredients' },
        { 'Code': '70', 'Value': 'Product/Service Not Covered - Plan/Benefit Exclusion' },
        { 'Code': '71', 'Value': 'Prescriber ID Is Not Covered' },
        { 'Code': '72', 'Value': 'Primary Prescriber Is Not Covered' },
        { 'Code': '73', 'Value': 'Additional Fills Are Not Covered' },
        { 'Code': '74', 'Value': 'Other Carrier Payment Meets Or Exceeds Payable' },
        { 'Code': '75', 'Value': 'Prior Authorization Required' },
        { 'Code': '76', 'Value': 'Plan Limitations Exceeded' },
        { 'Code': '77', 'Value': 'Discontinued Product/Service ID Number' },
        { 'Code': '78', 'Value': 'Cost Exceeds Maximum' },
        { 'Code': '79', 'Value': 'Fill Too Soon' },
        { 'Code': '769', 'Value': 'Paid Billing Transaction (B1/B3) Submitted Under The Part D IIN PCN Found But Information Reporting Reversal (N2) Cannot Be Matched To An Information Reporting Transaction (N1/N3) In An Approved Status; Reversal (N2) Not Processed' },
        { 'Code': '770', 'Value': 'Paid Billing Transaction (B1/B3) Submitted Under The Part D IIN PCN Not Found And Information Reporting Reversal (N2) Cannot Be Matched To An Information Reporting Transaction (N1/N3) In Approved Status; Reversal (N2) Not Processed' },
        { 'Code': '771', 'Value': 'Compound Contains Unidentifiable Ingredient(s); Submission Clarification Code Override Not Allowed' },
        { 'Code': '772', 'Value': 'Compound Not Payable Due To Non-covered Ingredient(s); Submission Clarification Code Override Not Allowed' },
        { 'Code': '777', 'Value': 'Plans Prescriber Data Base Not Able To Verify Active State License With Prescriptive Authority For Prescriber ID Submitted' },
        { 'Code': '8A', 'Value': 'Compound Requires At Least One Covered Ingredient' },
        { 'Code': '8B', 'Value': 'Compound Segment Missing On A Compound Claim' },
        { 'Code': '8C', 'Value': 'M/I Facility ID' },
        { 'Code': '8D', 'Value': 'Compound Segment Present On A Non-Compound Claim' },
        { 'Code': '8E', 'Value': 'M/I DUR/PPS Level Of Effort' },
        { 'Code': '8G', 'Value': 'Product/Service ID (407-D7) Must Be A Single Zero For Compounds' },
        { 'Code': '8H', 'Value': 'Product/Service Only Covered On Compound Claim' },
        { 'Code': '8J', 'Value': 'Incorrect Product/Service ID For Processor/Payer' },
        { 'Code': '8K', 'Value': 'DAW Code Value Not Supported' },
        { 'Code': '8M', 'Value': 'Sum Of Compound Ingredient Costs Does Not Equal Ingredient Cost Submitted' },
        { 'Code': '8N', 'Value': 'Future Date Prescription Written Not Allowed' },
        { 'Code': '8P', 'Value': 'Date Written Different On Previous Fill' },
        { 'Code': '8Q', 'Value': 'Excessive Refills Authorized' },
        { 'Code': '8R', 'Value': 'Submission Clarification Code Value Not Supported' },
        { 'Code': '8S', 'Value': 'Basis Of Cost  Determination Value Not Supported ' },
        { 'Code': '8T', 'Value': 'U&C Must Be Greater Than Zero ' },
        { 'Code': '8U', 'Value': 'GAD Must Be Greater Than Zero' },
        { 'Code': '8W', 'Value': 'Discrepancy Between Other Coverage Code And Other Payer Amount Paid' },
        { 'Code': '8X', 'Value': 'Collection From Cardholder Not Allowed' },
        { 'Code': '8Y', 'Value': 'Excessive Amount Collected' },
        { 'Code': '8Z', 'Value': 'Product/Service ID Qualifier Value Not Supported' },
        { 'Code': '80', 'Value': 'Diagnosis Code Submitted Does Not Meet Drug Coverage Criteria' },
        { 'Code': '81', 'Value': 'Claim Too Old' },
        { 'Code': '82', 'Value': 'Claim Is Post-Dated' },
        { 'Code': '83', 'Value': 'Duplicate Paid/Captured Claim' },
        { 'Code': '85', 'Value': 'Claim Not Processed' },
        { 'Code': '86', 'Value': 'Submit Manual Reversal' },
        { 'Code': '87', 'Value': 'Reversal Not Processed' },
        { 'Code': '88', 'Value': 'DUR Reject Error' },
        { 'Code': '89', 'Value': 'Rejected Claim Fees Paid' },
        { 'Code': '816', 'Value': 'Pharmacy Benefit Exclusion, May Be Covered Under Patients Medical Benefit' },
        { 'Code': '817', 'Value': 'Pharmacy Benefit Exclusion, Covered Under Patients Medical Benefit' },
        { 'Code': '818', 'Value': 'Medication Administration Not Covered, Plan Benefit Exclusion' },
        { 'Code': '819', 'Value': 'Plan Enrollment File Indicates Medicare As Primary Coverage' },
        { 'Code': '820', 'Value': 'Information Reporting Transaction (N1/N3) Matched To Reversed Or Rejected Claim Not Submitted Under Part D IIN PCN' },
        { 'Code': '821', 'Value': 'Information Reporting (N1/N3) Transaction Matched To Paid Claim Not Submitted Under Part D IIN PCN' },
        { 'Code': '822', 'Value': 'Drug Is Unrelated To The Terminal Illness And/Or Related Conditions. Not Covered Under Hospice.' },
        { 'Code': '823', 'Value': 'Drug Is Beneficiarys Liability - Not Covered By Hospice Or Part D. Hospice Non-Formulary. Check Other Coverage.' },
        { 'Code': '824', 'Value': 'Multi-transaction Transmission Not Allowed In Current NCPDP Standard' },
        { 'Code': '825', 'Value': 'Claim Date Of Service Is Outside Of Products FDA/NSDE Marketing Dates' },
        { 'Code': '826', 'Value': 'Prescriber NPI Submitted Not Found Within Processors NPI File' },
        { 'Code': '827', 'Value': 'Pharmacy Service Provider Is Temporarily Suspended From Processing Claims By Payer/Processor' },
        { 'Code': '828', 'Value': 'Plan/Beneficiary Case Management Restriction In Place' },
        { 'Code': '829', 'Value': 'Pharmacy Must Notify Beneficiary: Claim Not Covered Due To Failure To Meet Medicare Part D Active, Valid Prescriber NPI Requirements' },
        { 'Code': '830', 'Value': 'Workers Comp Or P&C Adjuster Authorization Required -- Patient Must Directly Contact Their Adjuster' },
        { 'Code': '831', 'Value': 'Product Service ID Carve-Out, Bill Medicaid Fee For Service' },
        { 'Code': '834', 'Value': 'M/I Provider First Name' },
        { 'Code': '835', 'Value': 'M/I Provider Last Name' },
        { 'Code': '836', 'Value': 'M/I Facility ID Qualifier' },
        { 'Code': '837', 'Value': 'Facility ID Value Not Supported' },
        { 'Code': '838', 'Value': 'M/I Original Manufacturer Product  ID' },
        { 'Code': '839', 'Value': 'M/I Original Manufacturer Product  ID Qualifier' },
        { 'Code': '840', 'Value': 'Original Manufacturer Product  ID Value Not Supported' },
        { 'Code': '844', 'Value': 'M/I Other Payer Adjudicated Program Type' },
        { 'Code': '845', 'Value': 'Other Payer Reconciliation ID Is Not Used For This Transaction Code' },
        { 'Code': '846', 'Value': 'Benefit Stage Indicator Count Is Not Used For This Transaction Code' },
        { 'Code': '847', 'Value': 'Benefit Stage Indicator Count Does Not Precede Benefit Stage Indicator' },
        { 'Code': '848', 'Value': 'M/I Benefit Stage Indicator Count' },
        { 'Code': '849', 'Value': 'Benefit Stage Indicator Count Does Not Match Number of Repetitions' },
        { 'Code': '850', 'Value': 'Benefit Stage Indicator Is Not Used For This Transaction Code' },
        { 'Code': '851', 'Value': 'Benefit Stage Indicator Value Not Supported' },
        { 'Code': '852', 'Value': 'M/I Benefit Stage Indicator' },
        { 'Code': '853', 'Value': 'N Payer IIN Is Not Used For This Transaction Code' },
        { 'Code': '854', 'Value': 'M/I N Payer IIN' },
        { 'Code': '855', 'Value': 'Non-Matched N Payer IIN' },
        { 'Code': '856', 'Value': 'N Payer Processor Control Number Is Not Used For This Transaction Code' },
        { 'Code': '857', 'Value': 'M/I N Payer Processor Control Number' },
        { 'Code': '858', 'Value': 'Non-Matched N Payer Processor Control Number' },
        { 'Code': '859', 'Value': 'N Payer Group ID Is Not Used For This Transaction Code' },
        { 'Code': '860', 'Value': 'M/I N Payer Group ID' },
        { 'Code': '861', 'Value': 'Non-Matched N Payer Group ID' },
        { 'Code': '862', 'Value': 'N Payer Cardholder ID Is Not Used For This Transaction Code' },
        { 'Code': '863', 'Value': 'M/I N Payer Cardholder ID' },
        { 'Code': '864', 'Value': 'N Payer Cardholder ID Is Not Covered' },
        { 'Code': '865', 'Value': 'N Payer Adjudicated Program Type Is Not Used For This Transaction Code' },
        { 'Code': '866', 'Value': 'M/I N Payer Adjudicated Program Type' },
        { 'Code': '867', 'Value': 'N Payer Adjudicated Program Type Value Not Supported' },
        { 'Code': '868', 'Value': 'M/I N Transaction Reconciliation ID' },
        { 'Code': '869', 'Value': 'M/I N Transaction Source Type' },
        { 'Code': '870', 'Value': 'M/I Prescriber DEA Number' },
        { 'Code': '871', 'Value': 'M/I Compound Level Of Complexity' },
        { 'Code': '872', 'Value': 'Mismatch Between Compound Level Of Complexity And Preparation Environment Type' },
        { 'Code': '873', 'Value': 'M/I Preparation Environment Type' },
        { 'Code': '874', 'Value': 'M/I Preparation Environment Event Code' },
        { 'Code': '875', 'Value': 'M/I Total Prescribed Quantity Remaining' },
        { 'Code': '876', 'Value': 'Prescriptive Authority Restrictions Apply, Criteria Not Met' },
        { 'Code': '877', 'Value': 'Service Provider ID Terminated  On NPPES File' },
        { 'Code': '878', 'Value': 'Service Provider ID Not Found On NPPES File' },
        { 'Code': '879', 'Value': 'Service Provider ID Excluded From Receiving CMS Enrollment Data' },
        { 'Code': '880', 'Value': 'M/I Submission Type Code ' },
        { 'Code': '881', 'Value': 'Missing Submission Type Code Count' },
        { 'Code': '882', 'Value': 'M/I Do Not Dispense Before Date' },
        { 'Code': '883', 'Value': 'Date of Service Prior To Do Not Dispense Before Date' },
        { 'Code': '884', 'Value': 'M/I Multiple RX Order Group Reason Code' },
        { 'Code': '885', 'Value': 'M/I Multiple RX Order Group ID' },
        { 'Code': '886', 'Value': 'M/I Prescriber Place of Service' },
        { 'Code': '887', 'Value': 'A Previous Payer(s) Is An Excluded Federal Health Care Program Copay Assistance Is Not Allowed' },
        { 'Code': '888', 'Value': 'Beneficiary Is Enrolled In Excluded Federal Health Care Program' },
        { 'Code': '889', 'Value': 'Prescriber Not Enrolled in State Medicaid Program' },
        { 'Code': '890', 'Value': 'Pharmacy Not Enrolled in State Medicaid Program' },
        { 'Code': '891', 'Value': 'Days Supply Is Less Than Plan Minimum' },
        { 'Code': '892', 'Value': 'Pharmacy Must Attest FDA REMS Requirements Have Been Met' },
        { 'Code': '893', 'Value': 'Pharmacy Must Attest Required Patient Form Is On File' },
        { 'Code': '894', 'Value': 'Pharmacy Must Attest Plan Medical Necessity Criteria Has Been Met' },
        { 'Code': '895', 'Value': 'Allowed Number of Overrides Exhausted' },
        { 'Code': '896', 'Value': 'Other Adjudicated Program Type Of Unknown Is Not Covered' },
        { 'Code': '897', 'Value': 'M/I Regulatory Fee Count' },
        { 'Code': '898', 'Value': 'M/I Regulatory Fee Type Code' },
        { 'Code': '899', 'Value': 'M/I Other Payer Percentage Tax Exempt Indicator' },
        { 'Code': '9B', 'Value': 'Reason For Service Code Value Not Supported' },
        { 'Code': '9C', 'Value': 'Professional Service Code Value Not Supported' },
        { 'Code': '9D', 'Value': 'Result Of Service Code Value Not Supported' },
        { 'Code': '9E', 'Value': 'Quantity Does Not Match Dispensing Unit' },
        { 'Code': '9G', 'Value': 'Quantity Dispensed Exceeds Maximum Allowed' },
        { 'Code': '9H', 'Value': 'Quantity Not Valid For Product/Service ID Submitted' },
        { 'Code': '9J', 'Value': 'Future Other Payer Date Not Allowed' },
        { 'Code': '9K', 'Value': 'Compound Ingredient Component Count Exceeds Number Of Ingredients Supported' },
        { 'Code': '9M', 'Value': 'Minimum Of Two Ingredients Required' },
        { 'Code': '9N', 'Value': 'Compound Ingredient Quantity Exceeds Maximum Allowed ' },
        { 'Code': '9Q', 'Value': 'Route Of Administration Submitted Not Covered ' },
        { 'Code': '9R', 'Value': 'Prescription/Service Reference Number Qualifier Submitted Not Covered' },
        { 'Code': '9S', 'Value': 'Future Associated Prescription/Service Date Not Allowed' },
        { 'Code': '9T', 'Value': 'Prior Authorization Type Code Submitted Not Covered' },
        { 'Code': '9U', 'Value': 'Provider ID Qualifier Submitted Not Covered' },
        { 'Code': '9V', 'Value': 'Prescriber ID Qualifier Submitted Not Covered' },
        { 'Code': '9W', 'Value': 'DUR/PPS Code Counter Exceeds Number Of Occurrences Supported' },
        { 'Code': '9Y', 'Value': 'Compound Product ID Qualifier Submitted Not Covered' },
        { 'Code': '9Z', 'Value': 'Duplicate Product ID In Compound' },
        { 'Code': '90', 'Value': 'Host Hung Up' },
        { 'Code': '91', 'Value': 'Host Response Error' },
        { 'Code': '92', 'Value': 'System Unavailable/Host Unavailable' },
        { 'Code': '95', 'Value': 'Time Out' },
        { 'Code': '96', 'Value': 'Scheduled Downtime' },
        { 'Code': '97', 'Value': 'Payer Unavailable' },
        { 'Code': '98', 'Value': 'Connection To Payer Is Down' },
        { 'Code': '99', 'Value': 'Host Processing Error' },
        { 'Code': '900', 'Value': 'M/I Other Payer Regulatory Fee Type Count' },
        { 'Code': '901', 'Value': 'M/I Other Payer Regulatory Fee  Type Code' },
        { 'Code': '902', 'Value': 'M/I Other Payer Regulatory Fee Exempt Indicator' },
        { 'Code': '903', 'Value': 'Regulatory Fee Count Not Used For This Transaction Code' },
        { 'Code': '904', 'Value': 'Regulatory Fee Type Code Not Used For This Transaction Code' },
        { 'Code': '905', 'Value': 'Other Payer Percentage Tax Exempt Indicator Not Used For This Transaction Code' },
        { 'Code': '906', 'Value': 'Other Payer Regulatory Fee Type Count Not Used For This Transaction Code' },
        { 'Code': '907', 'Value': 'Other Payer Regulatory Fee Type Code Not Used For This Transaction Code' },
        { 'Code': '908', 'Value': 'Other Payer Regulatory Fee Exempt Indicator Not Used For This Transaction Code' },
        { 'Code': '909', 'Value': 'Regulatory Fee Grouping Not Correct' },
        { 'Code': '910', 'Value': 'Other Payer Regulatory Fee Grouping Not Correct' },
        { 'Code': '911', 'Value': 'Regulatory Fee Count Does Not Match Number of Repetitions' },
        { 'Code': '912', 'Value': 'Other Payer Regulatory Fee Type Count Does Not Match Number of Repetitions' },
        { 'Code': '913', 'Value': 'Regulatory Fee Count Exceeds Number Of Occurrences Supported' },
        { 'Code': '914', 'Value': 'Other Payer Regulatory Fee Type Count Exceeds Number Of Occurrences Supported' },
        { 'Code': '915', 'Value': 'Regulatory Fee Type Code Does Not Precede Regulatory Fee Amount Submitted' },
        { 'Code': '916', 'Value': 'Other Payer Regulatory Fee Type Code Does Not Precede Other Payer Regulatory Fee Exempt Indicator' },
        { 'Code': '917', 'Value': 'Regulatory Fee Count Does Not Precede Regulatory Fee Type Code' },
        { 'Code': '918', 'Value': 'Other Payer Regulatory Fee Type Count Does Not Precede Other Payer Regulatory Fee Type Code' },
        { 'Code': '919', 'Value': 'Regulatory Fee Type Code Value Not Supported' },
        { 'Code': '920', 'Value': 'Other Payer Regulatory Fee Type Code Value Not Supported' },
        { 'Code': '921', 'Value': 'Other Payer Regulatory Fee Exempt Indicator Value Not Supported' },
        { 'Code': '922', 'Value': 'Morphine Milligram Equivalency (MME) Exceeds Limits' },
        { 'Code': '923', 'Value': 'Morphine Milligram Equivalency (MME) Exceeds Limits For Patient Age' },
        { 'Code': '924', 'Value': 'Cumulative Dose Exceeded Across Multiple Prescriptions' },
        { 'Code': '925', 'Value': 'Initial Fill Days Supply Exceeds Limits' },
        { 'Code': '926', 'Value': 'Initial Fill Days Supply Exceeds Limits For Patient Age' },
        { 'Code': '927', 'Value': 'Days Supply Limitation For Product/Service For Patient Age' },
        { 'Code': '928', 'Value': 'Cumulative Fills Exceed Limits' },
        { 'Code': '929', 'Value': 'ID Submitted Is Associated With A Precluded Prescriber' },
        { 'Code': '930', 'Value': 'ID Submitted Is Associated To A Precluded Pharmacy' },
        { 'Code': '931', 'Value': 'M/I Sending Entity Name' },
        { 'Code': '932', 'Value': 'M/I Patient Middle Name' },
        { 'Code': '933', 'Value': 'M/I Patient Name Suffix' },
        { 'Code': '934', 'Value': 'M/I Patient Name Prefix' },
        { 'Code': '935', 'Value': 'M/I Electronic Prescription Message ID' },
        { 'Code': '936', 'Value': 'M/I Electronic Prescriber Order Number' },
        { 'Code': '937', 'Value': 'M/I State Issuing Scheduled Prescription ID Number' },
        { 'Code': '938', 'Value': 'M/I Prescriber Middle Name' },
        { 'Code': '939', 'Value': 'M/I Service Provider Contact First Name' },
        { 'Code': '940', 'Value': 'M/I Service Provider Contact Last Name' },
        { 'Code': '941', 'Value': 'M/I Service Provider Telephone Number' },
        { 'Code': '942', 'Value': 'M/I Species' },
        { 'Code': '943', 'Value': 'DUR Reject – Pharmacy Override Using DUR/PPS Not Allowed' },
        { 'Code': '944', 'Value': 'All Lots Of Drug/Product Recalled' },
        { 'Code': '945', 'Value': 'High Dollar Amount Is Not Supported' },
        { 'Code': '946', 'Value': 'Last Known 4RX Claim Date Submitted Too Old' },
        { 'Code': '979', 'Value': 'Patient Locked Into Specific Prescriber(s)' },
        { 'Code': '980', 'Value': 'Patient Locked Into Specific Pharmacy(s)' },
        { 'Code': '981', 'Value': 'Date of Service for Incremental Fill Exceeds Regulatory Timeframe for Dispensing' },
        { 'Code': '982', 'Value': 'Provide Patient With Required Notice When Patient Access To Care Is Delayed Due To Claim Rejection/Benefit Restriction.' },
        { 'Code': '984', 'Value': 'Bill Dual Eligible Medicare Part B Patient Cost Share To Alternate Medicaid Benefit' },
        { 'Code': '985', 'Value': 'Beneficiary Has A Workers Compensation Medicare Set-Aside Arrangement (WCMSA).' },
        { 'Code': 'AA', 'Value': 'Patient Spenddown Not Met' },
        { 'Code': 'AB', 'Value': 'Date Written Is After Date Of Service' },
        { 'Code': 'AC', 'Value': 'Product Not Covered Non-Participating Manufacturer' },
        { 'Code': 'AD', 'Value': 'Billing Provider Not Eligible To Bill This Claim Type' },
        { 'Code': 'AE', 'Value': 'QMB (Qualified Medicare Beneficiary)-Bill Medicare' },
        { 'Code': 'AF', 'Value': 'Patient Enrolled Under Managed Care' },
        { 'Code': 'AG', 'Value': 'Days Supply Limitation For Product/Service' },
        { 'Code': 'AH', 'Value': 'Unit Dose Packaging Only Payable For Nursing Home Recipients' },
        { 'Code': 'AJ', 'Value': 'Generic Drug Required' },
        { 'Code': 'AK', 'Value': 'M/I Software Vendor/Certification ID ' },
        { 'Code': 'AM', 'Value': 'M/I Segment Identification' },
        { 'Code': 'AQ', 'Value': 'M/I Facility Segment' },
        { 'Code': 'A1', 'Value': 'ID Submitted Is Associated With An Excluded Prescriber' },
        { 'Code': 'A2', 'Value': 'ID Submitted Is Associated To A Deceased Prescriber' },
        { 'Code': 'A3', 'Value': 'This Product May Be Covered Under Hospice - Medicare A' },
        { 'Code': 'A4', 'Value': 'This Product May Be Covered Under The Medicare- B Bundled Payment To An ESRD Dialysis Facility' },
        { 'Code': 'A5', 'Value': 'Not Covered Under Part D Law' },
        { 'Code': 'A6', 'Value': 'This Product/Service May Be Covered Under Medicare Part B' },
        { 'Code': 'A7', 'Value': 'M/I Internal Control Number' },
        { 'Code': 'A9', 'Value': 'M/I Transaction Count' },
        { 'Code': 'BA', 'Value': 'Compound Basis Of Cost Determination Submitted Not Covered ' },
        { 'Code': 'BB', 'Value': 'Diagnosis Code Qualifier Submitted Not Covered' },
        { 'Code': 'BC', 'Value': 'Future Measurement Date Not Allowed' },
        { 'Code': 'BE', 'Value': 'M/I Professional Service Fee Submitted' },
        { 'Code': 'BM', 'Value': 'M/I Narrative Message' },
        { 'Code': 'B2', 'Value': 'M/I Service Provider ID Qualifier' },
        { 'Code': 'CA', 'Value': 'M/I Patient First Name' },
        { 'Code': 'CB', 'Value': 'M/I Patient Last Name' },
        { 'Code': 'CC', 'Value': 'M/I Cardholder First Name' },
        { 'Code': 'CD', 'Value': 'M/I Cardholder Last Name' },
        { 'Code': 'CE', 'Value': 'M/I Home Plan' },
        { 'Code': 'CF', 'Value': 'M/I Employer Name' },
        { 'Code': 'CG', 'Value': 'M/I Employer Street Address' },
        { 'Code': 'CH', 'Value': 'M/I Employer City Address' },
        { 'Code': 'CI', 'Value': 'M/I Employer State/Province Address' },
        { 'Code': 'CJ', 'Value': 'M/I Employer ZIP Postal Zone' },
        { 'Code': 'CK', 'Value': 'M/I Employer Phone Number' },
        { 'Code': 'CL', 'Value': 'M/I Employer Contact Name' },
        { 'Code': 'CM', 'Value': 'M/I Patient Street Address' },
        { 'Code': 'CN', 'Value': 'M/I Patient City Address' },
        { 'Code': 'CO', 'Value': 'M/I Patient State/Province Address' },
        { 'Code': 'CP', 'Value': 'M/I Patient ZIP/Postal Zone ' },
        { 'Code': 'CQ', 'Value': 'M/I Patient Phone Number' },
        { 'Code': 'CR', 'Value': 'M/I Carrier ID' },
        { 'Code': 'CW', 'Value': 'M/I Alternate ID' },
        { 'Code': 'CX', 'Value': 'M/I Patient ID Qualifier' },
        { 'Code': 'CY', 'Value': 'M/I Patient ID' },
        { 'Code': 'CZ', 'Value': 'M/I Employer ID' },
        { 'Code': 'DC', 'Value': 'M/I Dispensing Fee Submitted' },
        { 'Code': 'DN', 'Value': 'M/I Basis Of Cost Determination' },
        { 'Code': 'DQ', 'Value': 'M/I Usual And Customary Charge' },
        { 'Code': 'DR', 'Value': 'M/I Prescriber Last Name' },
        { 'Code': 'DT', 'Value': 'M/I Special Packaging Indicator' },
        { 'Code': 'DU', 'Value': 'M/I Gross Amount Due' },
        { 'Code': 'DV', 'Value': 'M/I Other Payer Amount Paid' },
        { 'Code': 'DX', 'Value': 'M/I Patient Paid Amount Submitted' },
        { 'Code': 'DY', 'Value': 'M/I Date Of Injury' },
        { 'Code': 'DZ', 'Value': 'M/I Claim/Reference ID' },
        { 'Code': 'EA', 'Value': 'M/I Originally Prescribed Product/Service Code' },
        { 'Code': 'EB', 'Value': 'M/I Originally Prescribed Quantity' },
        { 'Code': 'EC', 'Value': 'M/I Compound Ingredient Component Count' },
        { 'Code': 'ED', 'Value': 'M/I Compound Ingredient Quantity' },
        { 'Code': 'EE', 'Value': 'M/I Compound Ingredient Drug Cost' },
        { 'Code': 'EF', 'Value': 'M/I Compound Dosage Form Description Code' },
        { 'Code': 'EG', 'Value': 'M/I Compound Dispensing Unit Form Indicator' },
        { 'Code': 'EJ', 'Value': 'M/I Originally Prescribed Product/Service ID Qualifier' },
        { 'Code': 'EK', 'Value': 'M/I Scheduled Prescription ID Number' },
        { 'Code': 'EM', 'Value': 'M/I Prescription/Service Reference Number Qualifier' },
        { 'Code': 'EN', 'Value': 'M/I Associated Prescription/Service Reference Number' },
        { 'Code': 'EP', 'Value': 'M/I Associated Prescription/Service Date' },
        { 'Code': 'ER', 'Value': 'M/I Procedure Modifier Code' },
        { 'Code': 'ET', 'Value': 'M/I Quantity Prescribed' },
        { 'Code': 'EU', 'Value': 'M/I Prior Authorization Type Code' },
        { 'Code': 'EV', 'Value': 'M/I Prior Authorization ID Submitted' },
        { 'Code': 'EY', 'Value': 'M/I Provider ID Qualifier' },
        { 'Code': 'EZ', 'Value': 'M/I Prescriber ID Qualifier' },
        { 'Code': 'E1', 'Value': 'M/I Product/Service ID Qualifier' },
        { 'Code': 'E2', 'Value': 'M/I Route Of Administration' },
        { 'Code': 'E3', 'Value': 'M/I Incentive Amount Submitted' },
        { 'Code': 'E4', 'Value': 'M/I Reason For Service Code' },
        { 'Code': 'E5', 'Value': 'M/I Professional Service Code' },
        { 'Code': 'E6', 'Value': 'M/I Result Of Service Code' },
        { 'Code': 'E7', 'Value': 'M/I Quantity Dispensed' },
        { 'Code': 'E8', 'Value': 'M/I Other Payer Date' },
        { 'Code': 'E9', 'Value': 'M/I Provider ID' },
        { 'Code': 'FO', 'Value': 'M/I Plan ID' },
        { 'Code': 'GE', 'Value': 'M/I Percentage Sales Tax Amount Submitted' },
        { 'Code': 'G1', 'Value': 'M/I Compound Type' },
        { 'Code': 'G2', 'Value': 'M/I CMS Part D Defined Qualified Facility' },
        { 'Code': 'G4', 'Value': 'Prescriber Must Contact Plan' },
        { 'Code': 'G5', 'Value': 'Pharmacist Must Contact Plan' },
        { 'Code': 'G6 ', 'Value': 'Pharmacy Not Contracted In Specialty Network ' },
        { 'Code': 'G7', 'Value': 'Pharmacy Not Contracted In Home Infusion Network' },
        { 'Code': 'G8', 'Value': 'Pharmacy Not Contracted In Long Term Care Network' },
        { 'Code': 'G9', 'Value': 'Pharmacy Not Contracted In 90 Day Retail Network' },
        { 'Code': 'HA', 'Value': 'M/I Flat Sales Tax Amount Submitted' },
        { 'Code': 'HB', 'Value': 'M/I Other Payer Amount Paid Count' },
        { 'Code': 'HC', 'Value': 'M/I Other Payer Amount Paid Qualifier' },
        { 'Code': 'HD', 'Value': 'M/I Dispensing Status' },
        { 'Code': 'HE', 'Value': 'M/I Percentage Sales Tax Rate Submitted' },
        { 'Code': 'HF', 'Value': 'M/I Quantity Intended To Be Dispensed' },
        { 'Code': 'HG', 'Value': 'M/I Days Supply Intended To Be Dispensed' },
        { 'Code': 'HN', 'Value': 'M/I Patient E-Mail Address' },
        { 'Code': 'H1', 'Value': 'M/I Measurement Time' },
        { 'Code': 'H2', 'Value': 'M/I Measurement Dimension' },
        { 'Code': 'H3', 'Value': 'M/I Measurement Unit' },
        { 'Code': 'H4', 'Value': 'M/I Measurement Value' },
        { 'Code': 'H6', 'Value': 'M/I DUR Co-Agent ID' },
        { 'Code': 'H7', 'Value': 'M/I Other Amount Claimed Submitted Count' },
        { 'Code': 'H8', 'Value': 'M/I Other Amount Claimed Submitted Qualifier' },
        { 'Code': 'H9', 'Value': 'M/I Other Amount Claimed Submitted' },
        { 'Code': 'JE', 'Value': 'M/I Percentage Sales Tax Basis Submitted' },
        { 'Code': 'J9', 'Value': 'M/I DUR Co-Agent ID Qualifier' },
        { 'Code': 'K5', 'Value': 'M/I Transaction Reference Number' },
        { 'Code': 'M1', 'Value': 'Patient Not Covered In This Aid Category' },
        { 'Code': 'M3', 'Value': 'Host PA/MC Error' },
        { 'Code': 'M4', 'Value': 'Prescription/Service Reference Number/Time Limit Exceeded' },
        { 'Code': 'M5', 'Value': 'Requires Manual Claim' },
        { 'Code': 'M6', 'Value': 'Host Eligibility Error' },
        { 'Code': 'M7', 'Value': 'Host Drug File Error' },
        { 'Code': 'M8', 'Value': 'Host Provider File Error' },
        { 'Code': 'MH', 'Value': 'M/I Other Payer Processor Control Number' },
        { 'Code': 'MJ', 'Value': 'M/I Other Payer Group ID' },
        { 'Code': 'MM', 'Value': 'Non-Matched Other Payer Processor Control Number' },
        { 'Code': 'MN', 'Value': 'Non-Matched Other Payer Group ID' },
        { 'Code': 'MP', 'Value': 'Other Payer Cardholder ID Not Covered' },
        { 'Code': 'MR', 'Value': 'Product Not On Formulary' },
        { 'Code': 'MS', 'Value': 'More than 1 Cardholder Found -- Narrow Search Criteria' },
        { 'Code': 'MT', 'Value': 'M/I Patient Assignment Indicator (Direct Member Reimbursement Indicator)' },
        { 'Code': 'MZ', 'Value': 'Error Overflow' },
        { 'Code': 'NN', 'Value': 'Transaction Rejected At Switch Or Intermediary' },
        { 'Code': 'NP', 'Value': 'M/I Other Payer-Patient Responsibility Amount Qualifier' },
        { 'Code': 'NQ', 'Value': 'M/I Other Payer-Patient Responsibility Amount' },
        { 'Code': 'NR', 'Value': 'M/I Other Payer-Patient Responsibility Amount Count' },
        { 'Code': 'NU', 'Value': 'M/I Other Payer Cardholder ID' },
        { 'Code': 'NV', 'Value': 'M/I Delay Reason Code' },
        { 'Code': 'NX', 'Value': 'M/I Submission Clarification Code Count' },
        { 'Code': 'N1', 'Value': 'No Patient Match Found' },
        { 'Code': 'N3', 'Value': 'M/I Medicaid Paid Amount' },
        { 'Code': 'N4', 'Value': 'M/I Medicaid Subrogation Internal Control Number/Transaction Control Number (ICN/TCN)' },
        { 'Code': 'N5', 'Value': 'M/I Medicaid ID Number' },
        { 'Code': 'N6', 'Value': 'M/I Medicaid Agency Number' },
        { 'Code': 'N7', 'Value': 'Use Prior Authorization ID Provided During Transition Period' },
        { 'Code': 'N8', 'Value': 'Use Prior Authorization ID Provided For Emergency Supply' },
        { 'Code': 'N9', 'Value': 'Use Prior Authorization ID Provided For Level of Care Change' },
        { 'Code': 'PA', 'Value': 'PA Exhausted/Not Renewable' },
        { 'Code': 'PB', 'Value': 'Invalid Transaction Count For This Transaction Code' },
        { 'Code': 'PC', 'Value': 'M/I Request Claim Segment' },
        { 'Code': 'PD', 'Value': 'M/I Request Clinical Segment' },
        { 'Code': 'PE', 'Value': 'M/I Request Coordination Of Benefits/Other Payments Segment' },
        { 'Code': 'PF', 'Value': 'M/I Request Compound Segment' },
        { 'Code': 'PH', 'Value': 'M/I Request DUR/PPS Segment' },
        { 'Code': 'PJ', 'Value': 'M/I Request Insurance Segment' },
        { 'Code': 'PK', 'Value': 'M/I Request Patient Segment' },
        { 'Code': 'PM', 'Value': 'M/I Request Pharmacy Provider Segment' },
        { 'Code': 'PN', 'Value': 'M/I Request Prescriber Segment' },
        { 'Code': 'PP', 'Value': 'M/I Request Pricing Segment' },
        { 'Code': 'PQ', 'Value': 'M/I Narrative Segment' },
        { 'Code': 'PR', 'Value': 'M/I Request Prior Authorization Segment' },
        { 'Code': 'PS', 'Value': 'M/I Transaction Header Segment' },
        { 'Code': 'PT', 'Value': 'M/I Request Workers Compensation Segment' },
        { 'Code': 'PV', 'Value': 'Non-Matched Associated Prescription/Service Date' },
        { 'Code': 'PW', 'Value': 'Employer ID Not Covered' },
        { 'Code': 'PX', 'Value': 'Other Payer ID Not Covered' },
        { 'Code': 'PY', 'Value': 'Non-Matched Unit Form/Route of Administration' },
        { 'Code': 'PZ', 'Value': 'Non-Matched Unit Of Measure To Product/Service ID ' },
        { 'Code': 'P0', 'Value': 'Non-zero Value Required for Vaccine Administration' },
        { 'Code': 'P1', 'Value': 'Associated Prescription/Service Reference Number Not Found' },
        { 'Code': 'P2', 'Value': 'Clinical Information Counter Out Of Sequence' },
        { 'Code': 'P3', 'Value': 'Compound Ingredient Component Count Does Not Match Number Of Repetitions' },
        { 'Code': 'P4', 'Value': 'Coordination Of Benefits/Other Payments Count Does Not Match Number Of Repetitions' },
        { 'Code': 'P6', 'Value': 'Date Of Service Prior To Date Of Birth' },
        { 'Code': 'P7', 'Value': 'Diagnosis Code Count Does Not Match Number Of Repetitions' },
        { 'Code': 'P8', 'Value': 'DUR/PPS Code Counter Out Of Sequence' },
        { 'Code': 'P9', 'Value': 'Field Is Non-Repeatable' },
        { 'Code': 'RA', 'Value': 'PA Reversal Out Of Order' },
        { 'Code': 'RB', 'Value': 'Multiple Partials Not Allowed' },
        { 'Code': 'RC', 'Value': 'Different Drug Entity Between Partial & Completion' },
        { 'Code': 'RD', 'Value': 'Mismatched Cardholder/Group ID-Partial To Completion' },
        { 'Code': 'RF', 'Value': 'Improper Order Of Dispensing Status Code On Partial Fill Transaction' },
        { 'Code': 'RG', 'Value': 'M/I Associated Prescription/Service Reference Number On Completion Transaction ' },
        { 'Code': 'RH', 'Value': 'M/I Associated Prescription/Service Date On Completion Transaction' },
        { 'Code': 'RJ', 'Value': 'Associated Partial Fill Transaction Not On File' },
        { 'Code': 'RK', 'Value': 'Partial Fill Transaction Not Supported' },
        { 'Code': 'RL', 'Value': 'Transitional Benefit/Resubmit Claim' },
        { 'Code': 'RM', 'Value': 'Completion Transaction Not Permitted With Same Date Of Service As Partial Transaction' },
        { 'Code': 'RN', 'Value': 'Plan Limits Exceeded On Intended Partial Fill Field Limitations' },
        { 'Code': 'RP', 'Value': 'Out Of Sequence P Reversal On Partial Fill Transaction' },
        { 'Code': 'RR', 'Value': 'M/I Patient ID Count' },
        { 'Code': 'RS', 'Value': 'M/I Associated Prescription/Service Date On Partial Transaction' },
        { 'Code': 'RT', 'Value': 'M/I Associated Prescription/Service Reference Number On Partial Transaction' },
        { 'Code': 'RU', 'Value': 'Mandatory Data Elements Must Occur Before Optional Data Elements In A Segment' },
        { 'Code': 'R0', 'Value': 'Professional Service Code of MA required for Vaccine Incentive Fee Submitted' },
        { 'Code': 'R2', 'Value': 'Other Payer Reject Count Does Not Match Number Of Repetitions' },
        { 'Code': 'R3', 'Value': 'Procedure Modifier Code Count Does Not Match Number Of Repetitions' },
        { 'Code': 'R4', 'Value': 'Procedure Modifier Code Invalid For Product/Service ID' },
        { 'Code': 'R5', 'Value': 'Product/Service ID Must Be Zero When Product/Service ID Qualifier Equals 06' },
        { 'Code': 'R6', 'Value': 'Product/Service Not Appropriate For This Location' },
        { 'Code': 'R7', 'Value': 'Repeating Segment Not Allowed In Same Transaction' },
        { 'Code': 'R8', 'Value': 'Syntax Error' },
        { 'Code': 'R9', 'Value': 'Value In Gross Amount Due Does Not Follow Pricing Formulae' },
        { 'Code': 'SE', 'Value': 'M/I Procedure Modifier Code Count' },
        { 'Code': 'SF', 'Value': 'Other Payer Amount Paid Count Does Not Match Number Of Repetitions' },
        { 'Code': 'SG', 'Value': 'Submission Clarification Code Count Does Not Match Number of Repetitions' },
        { 'Code': 'SH', 'Value': 'Other Payer-Patient Responsibility Amount Count Does Not Match Number of Repetitions' },
        { 'Code': 'SW', 'Value': 'Accumulated Patient True Out Of Pocket Must Be Equal To Or Greater Than Zero' },
        { 'Code': 'TH', 'Value': 'Patient ID Count Does Not Match Number Of Repetitions' },
        { 'Code': 'TN ', 'Value': 'Emergency Supply/Resubmit Claim' },
        { 'Code': 'TP', 'Value': 'Level Of Care Change/Resubmit Claim' },
        { 'Code': 'TQ', 'Value': 'Dosage Exceeds Product Labeling Limit ' },
        { 'Code': 'TR', 'Value': 'M/I Billing Entity Type Indicator' },
        { 'Code': 'TS', 'Value': 'M/I Pay To Qualifier' },
        { 'Code': 'TT', 'Value': 'M/I Pay To ID' },
        { 'Code': 'TU', 'Value': 'M/I Pay To Name' },
        { 'Code': 'TV', 'Value': 'M/I Pay To Street Address' },
        { 'Code': 'TW', 'Value': 'M/I Pay To City Address' },
        { 'Code': 'TX', 'Value': 'M/I Pay To State/ Province Address' },
        { 'Code': 'TY', 'Value': 'M/I Pay To ZIP/Postal Zone' },
        { 'Code': 'TZ', 'Value': 'M/I Generic Equivalent Product ID Qualifier' },
        { 'Code': 'UA', 'Value': 'M/I Generic Equivalent Product ID' },
        { 'Code': 'UU', 'Value': 'DAW 0 Cannot Be Submitted On A Multi-source Drug With Available Generics' },
        { 'Code': 'UZ', 'Value': 'Other Payer Coverage Type (338-5C) Required On Reversals To Downstream Payers. Resubmit Reversal With This Field.' },
        { 'Code': 'U7', 'Value': 'M/I Pharmacy Service Type' },
        { 'Code': 'VA', 'Value': 'Pay To Qualifier  Value Not Supported' },
        { 'Code': 'VB', 'Value': 'Generic Equivalent Product ID Qualifier Value Not Supported' },
        { 'Code': 'VC', 'Value': 'Pharmacy Service Type Value Not Supported' },
        { 'Code': 'VD', 'Value': 'Eligibility Search Time Frame Exceeded' },
        { 'Code': 'VE', 'Value': 'M/I Diagnosis Code Count' },
        { 'Code': 'WE', 'Value': 'M/I Diagnosis Code Qualifier' },
        { 'Code': 'XE', 'Value': 'M/I Clinical Information Counter' },
        { 'Code': 'XZ', 'Value': 'M/I Associated Prescription/Service Reference Number Qualifier ' },
        { 'Code': 'X0', 'Value': 'M/I Associated Prescription/Service Fill Number' },
        { 'Code': 'X8', 'Value': 'Procedure Modifier Code Count Exceeds Number Of Occurrences Supported' },
        { 'Code': 'X9', 'Value': 'Diagnosis Code Count Exceeds Number Of Occurrences Supported' },
        { 'Code': 'YA', 'Value': 'Compound Ingredient Modifier Code Count Exceeds Number Of Occurrences Supported' },
        { 'Code': 'YB', 'Value': 'Other Amount Claimed Submitted Count Exceeds Number Of Occurrences Supported' },
        { 'Code': 'YC', 'Value': 'Other Payer Reject Count Exceeds Number Of Occurrences Supported' },
        { 'Code': 'YD', 'Value': 'Other Payer-Patient Responsibility Amount Count Exceeds Number Of Occurrences Supported' },
        { 'Code': 'YE', 'Value': 'Submission Clarification Code Count Exceeds Number of Occurrences Supported' },
        { 'Code': 'YF', 'Value': 'Question Number/Letter Count Exceeds Number Of Occurrences Supported' },
        { 'Code': 'YH', 'Value': 'Clinical Information Counter Exceeds Number of Occurrences Supported' },
        { 'Code': 'YJ', 'Value': 'Medicaid Agency Number Not Supported' },
        { 'Code': 'YK', 'Value': 'M/I Service Provider Name' },
        { 'Code': 'YM', 'Value': 'M/I Service Provider Street Address' },
        { 'Code': 'YN', 'Value': 'M/I Service Provider City Address' },
        { 'Code': 'YP', 'Value': 'M/I Service Provider State/Province Code Address' },
        { 'Code': 'YQ', 'Value': 'M/I Service Provider ZIP/Postal Code' },
        { 'Code': 'YR', 'Value': 'M/I Patient ID Associated State/Province Address' },
        { 'Code': 'YS', 'Value': 'M/I Purchaser Relationship Code' },
        { 'Code': 'YT', 'Value': 'M/I Seller Initials' },
        { 'Code': 'YU', 'Value': 'M/I Purchaser ID Qualifier' },
        { 'Code': 'YV', 'Value': 'M/I Purchaser ID ' },
        { 'Code': 'YW', 'Value': 'M/I Purchaser ID Associated State/Province Code' },
        { 'Code': 'YX', 'Value': 'M/I Purchaser Date of Birth' },
        { 'Code': 'YY', 'Value': 'M/I Purchaser Gender Code' },
        { 'Code': 'YZ', 'Value': 'M/I Purchaser First Name' },
        { 'Code': 'Y0', 'Value': 'M/I Purchaser Last Name' },
        { 'Code': 'Y1', 'Value': 'M/I Purchaser Street Address' },
        { 'Code': 'Y2', 'Value': 'M/I Purchaser City Address' },
        { 'Code': 'Y3', 'Value': 'M/I Purchaser State/Province Code' },
        { 'Code': 'Y4', 'Value': 'M/I Purchaser ZIP/Postal Code' },
        { 'Code': 'Y5', 'Value': 'M/I Purchaser Country Code' },
        { 'Code': 'Y6', 'Value': 'M/I Time Of Service' },
        { 'Code': 'Y7', 'Value': 'M/I Associated Prescription/Service Provider ID Qualifier ' },
        { 'Code': 'Y8', 'Value': 'M/I Associated Prescription/Service Provider ID ' },
        { 'Code': 'Y9', 'Value': 'M/I Seller ID' },
        { 'Code': 'Z0', 'Value': 'Purchaser Country Code Value Not Supported For Processor/Payer' },
        { 'Code': 'Z1', 'Value': 'Prescriber Alternate ID Qualifier Value Not Supported' },
        { 'Code': 'Z2', 'Value': 'M/I Purchaser Segment' },
        { 'Code': 'Z3', 'Value': 'Purchaser Segment Present On A Non-Controlled Substance Reporting Transaction' },
        { 'Code': 'Z4', 'Value': 'Purchaser Segment Required On A Controlled Substance Reporting Transaction' },
        { 'Code': 'Z5', 'Value': 'M/I Service Provider Segment' },
        { 'Code': 'Z6', 'Value': 'Service Provider Segment Present On A Non-Controlled Substance Reporting Transaction' },
        { 'Code': 'Z7', 'Value': 'Service Provider Segment Required On A Controlled Substance Reporting Transaction' },
        { 'Code': 'Z8', 'Value': 'Purchaser Relationship Code Value Not Supported' },
        { 'Code': 'Z9', 'Value': 'Prescriber Alternate ID Not Covered' },
        { 'Code': 'ZB', 'Value': 'M/I Seller ID Qualifier' },
        { 'Code': 'ZC', 'Value': 'Associated Prescription/Service Provider ID Qualifier Value Not Supported For Processor/Payer' },
        { 'Code': 'ZD', 'Value': 'Associated Prescription/Service Reference Number Qualifier Value Not Supported' },
        { 'Code': 'ZE', 'Value': 'M/I Measurement Date' },
        { 'Code': 'ZF', 'Value': 'M/I Sales Transaction ID' },
        { 'Code': 'ZK', 'Value': 'M/I Prescriber ID Associated State/Province Address' },
        { 'Code': 'ZM', 'Value': 'M/I Prescriber Alternate ID Qualifier' },
        { 'Code': 'ZN', 'Value': 'Purchaser ID Qualifier Value Not Supported For Processor/Payer' },
        { 'Code': 'ZP', 'Value': 'M/I Prescriber Alternate ID' },
        { 'Code': 'ZQ', 'Value': 'M/I Prescriber Alternate ID Associated State/Province Address' },
        { 'Code': 'ZS', 'Value': 'M/I Reported Adjudicated Program Type' },
        { 'Code': 'ZT', 'Value': 'M/I Released Date' },
        { 'Code': 'ZU', 'Value': 'M/I Released Time' },
        { 'Code': 'ZV', 'Value': 'Reported Adjudicated ProgramType Value Not Supported' },
        { 'Code': 'ZW', 'Value': 'M/I Compound Preparation Time' },
        { 'Code': 'ZX', 'Value': 'M/I CMS Part D Contract ID' },
        { 'Code': 'ZY', 'Value': 'M/I Medicare Part D Plan Benefit Package (PBP)' },
        { 'Code': 'ZZ', 'Value': 'Cardholder ID Submitted Is Inactive. New Cardholder ID On File.' }
    ];

}