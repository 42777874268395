
    import Vue from 'vue'
    import moment from 'moment';
    import { Component, Prop } from 'vue-property-decorator';
    import { DatePicker } from 'element-ui';
    
    import { AutoRefillSubscription } from '@/models/AutoRefillSubscription';
    import { Prescription } from '@/models/Prescription';
    import { Patient } from '@/models/Patient';
    import { Drug } from '@/models/Drug/Drug';

    import SearchComponent from '@/components/SearchComponent.vue';
        
    @Component({
        name: "RXSubscriptionForm",
        components: { 
            SearchComponent,
            [DatePicker.name]: DatePicker,
        },
    })
    export default class RXSubscriptionForm extends Vue {
        @Prop({default: () => new Prescription()}) private rx! : Prescription;
        @Prop({default: () => new AutoRefillSubscription()}) sub! : AutoRefillSubscription;
        
        private isSaving = false;
        private patient = new Patient();
        private drug = new Drug();
        private rxExpirationDate : Date | null = null;
        private _rx : Prescription | null = null;
        private isLoading = false;
        private patientConsent = false;

        created(){
            this.isLoading = true;
            if(!this.rx.rxID){
                const rxID = this.sub.StoreRx+"-0";
                this.$http.get<Prescription>(`/Prescription/${rxID}`)
                .then(res => {
                    this._rx = new Prescription(res.data);
                    if(this._rx.rxID){
                        this.rxExpirationDate = this._rx.expirationDate;
                        this.fetchDrug();
                    }
                })
                .catch(err => console.error('Error getting Rx Details.', {err, response: err?.response }))
            } else {
                this.rxExpirationDate = this.rx.expirationDate;
                this.fetchDrug();
            }
        }
         fetchDrug(){
            if(!this.prescription?.packageID) return;

            this.$http.get<Drug>(`drug/${this.prescription.packageID}`)
            .then(res => {
                if(res.data?.packageID) this.drug = new Drug(undefined, res.data);
            })
            .catch(err => console.error('Error getting drug details.', {err, response: err?.response }))
            .finally(()=>this.isLoading = false)
        }
        
        get subscriptionExpirationDate(){
            const maxDays = this.drug?.status.autorefillMaxLength;
            if(this.sub?.expirationDate) return this.sub.expirationDate;
            else if(maxDays) return moment().add(maxDays, 'day').toDate();
            else return null;
        }

        get showExpirationAlert(){
            return !!this.subscriptionExpirationDate;
        }

        get invalidROA():boolean{
            return this.drug?.routeOfAdministration == 'Topical' && this.mode == 'create';
        }

        get isDisabled():boolean {
            return true;
        }

        get isFormValid():boolean {
            return !!(this.sub.nextProcessDate && (this.sub.cardId || this.mode == 'edit' || (this.prescription?.patientPay ?? -1) == 0) && (this.mode != 'create' || this.patientConsent));
        }

        get mode():string{
            return 'edit';
        }

        get storeName():string{
            return this.rx?.storeName || this.sub?.storeName;
        }

        get rxNumber():number{
            return this.rx?.rxNumber || this.sub?.rxNumber;
        }

        get patientID():number {
            return this.rx?.patientID || this.sub?.patientId;
        }

        get prescription(): Prescription | null{
            return this.rx.hasFullId ? this.rx : this._rx;
        }
        
        processDateValidator(date:Date){
            return AutoRefillSubscription.isProcessDateInvalid(date, this.rx.expirationDate, this.subscriptionExpirationDate);
        }
        private pickerOptions = {
            disabledDate: this.processDateValidator
        }

        mounted(){
            this.loadPatient();
        }

        loadPatient(){
            this.$http.get<Patient>(`/Patient/${this.patientID}`)
            .then(res => {
                if(res.data?.id){
                    this.patient = new Patient(res.data);
                }
            })
        }
    }
