import { render, staticRenderFns } from "./RxTransferModalComponent.vue?vue&type=template&id=5e2311ae&scoped=true"
import script from "./RxTransferModalComponent.vue?vue&type=script&lang=ts"
export * from "./RxTransferModalComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e2311ae",
  null
  
)

export default component.exports