import HasID from "./HasID";

export interface Note extends HasID {
    body: string;
    entityType: number;
    entityId: number | string;
    created: Date;
    createdBy: string;
    deleted: Date | null;
    deletedBy: string | null;
}

export enum EntityType {
    Unknow,
    PrescriptionHardCopyAnnotation,
    Patient,
    Case,
    Program,
    PriorAuthorization,
    DispenseError,
    RxTransferQueue,
    Prescriber
}
