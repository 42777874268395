<template>
  <div class="panel-header">
    <div class="header text-center">
      <h2 class="title">
        Sweet Alert
      </h2>
      <p class="category">
        A beautiful plugin, that replace the classic alert, Handcrafted
        by our friend
        <a
          target="_blank"
          href="https://twitter.com/t4t5"
        >Tristan Edwards</a>. Please check out the
        <a
          href="http://limonte.github.io/sweetalert2/"
          target="_blank"
        >full documentation.</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
    name: "SweetAlertHeader"
};
</script>

<style scoped></style>
