
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { Prescription } from '@/models/Prescription';
    import { Escript } from "@/models/Escript";
    import Axios from 'axios';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import { RxChange, RequestedMed } from '@/models/RxChange';
    import { debounce } from 'throttle-debounce';
    import DirectionsComponent from "@/components/Prescription/DirectionsComponent.vue";
    import SearchComponent from '@/components/SearchComponent.vue';

    @Component({
        components: {
            DirectionsComponent,
            SearchComponent
        }
    })
    export default class EscriptButtonComponent extends Vue {
        @Prop({ type: Prescription, default: {} }) readonly value!: Prescription;
        @Prop({ type: Escript, default: {} }) readonly escript!: Escript;

        private object: any = null;

        get isValidEscript(): boolean {
            return !isNaN(this.value.rxNumber) && this.value.rfNumber != null && this.value.escriptID != null;
        }
        get showCancelButton(): boolean {
            return this.isValidEscript && this.escript.isCancelRequest;
        }

        get showRenewDropdown(): boolean {
            return this.isValidEscript && !this.value.canBeFilled;
        }

        get showChangeDropdown(): boolean {
            return this.isValidEscript;
        }

        cancelRx() {
            if (!this.value || !this.value.escriptID) return;

            Axios.post(`escript/${this.value.escriptID}/cancel`, this.value)
                .then(response => {
                    const rx = Object.assign(new Prescription(), response.data);
                    this.$emit("input", rx);
                    //this.prescription = Object.assign(new Prescription(), response.data);
                });
        }

        renewRx(num: number) {
            if (!this.value || !this.value.escriptID) return;

            Axios.post(`escript/${this.value.escriptID}/renew/${num}`, this.value)
                .then(response => {
                    console.log(`Escript renew response: ${response.data}`);
                    this.$notification(NotificationOptions.notificationOptionsPreset("Renewal request sent."
                        , NotificationOptions.NotificationTypes.success));
                })
                .catch(err => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Error sending renewal request."
                        , NotificationOptions.NotificationTypes.danger));
                });
        }
        private rxChange: RxChange = {
            type: "",
            note: "",
            requestedMedications: []
        };
        addRequestedMedication() {
            if (this.object == null) return;
            var requestedMed = {
                package: this.object,
                note: "",
                quantity: this.value?.quantity,
                sig: this.value?.directions ?? "",
            } as RequestedMed;
            this.rxChange.requestedMedications.push(requestedMed);
            this.object = null;
        }
        changeRx(type: string) {
            if (!this.value || !this.value.escriptID) return;

            this.rxChange.type = type;
            // changeRx Component to be shown modally here?
            this.$bvModal.show("change-rx-modal");

            // set medication requested info here
            // allow up to 9 alternatives (for Therapeutic Interchange)
            // pharmacist note as well

        }

        clearChangeRx() {
            this.rxChange = {
                type: "",
                note: "",
                requestedMedications: []
            };
            this.object = null;
        }

        submitChangeRx(bvModalEvent: any) {
            if (!this.value || !this.value.escriptID) return;
            bvModalEvent.preventDefault();

            Axios.post(`escript/${this.value.escriptID}/change`, this.rxChange)
                .then(response => {
                    console.log(`Escript change for ${this.rxChange.type} response: ${response.data}`);
                    this.$bvModal.hide("change-rx-modal");
                    this.$notification(NotificationOptions.notificationOptionsPreset("Change requeest successfully submitted.", NotificationOptions.NotificationTypes.success));
                })
                .catch(err => {
                    this.$notification(NotificationOptions.notificationOptionsPreset("Error sending the change request.", NotificationOptions.NotificationTypes.danger));
                });
        }

        drugLabel(option: any) {
            if (option.ndc) {
                if (option.productNameShort) {
                    return `NDC:${option.ndc} - ${option.productNameShort}`;
                }
                return `NDC:${option.ndc} - ${option.productNameLong}`;
            }
            if (option.packageIdentifier) {
                if (option.productNameShort) {
                    return `NDC:${option.packageIdentifier.identifier} - ${option.productNameShort}`;
                }
                return `NDC:${option.packageIdentifier.identifier} - ${option.productNameLong}`;
            }
            return option.productNameLong;
        }

    }
