import HasID from "./HasID";
import HasLabel from "./HasLabel";
import { Patient } from "./Patient";
import { Prescription } from "./Prescription";
import { Prescriber } from "./Prescriber";
import { Store } from "./Store";
import { Drug } from '@/models/Drug/Drug';

export interface Escript extends HasID {
    messageId: string;
    messageType: string;
    sentTime: Date;
    relatesToMessageId: string;
    xmlString: string;
    prescriberOrderNumber: string;
    tertiaryIdentifier: string;
    created: Date;
    createdBy: string;
}

export interface EscriptResponse {
    eScript: Escript;
    message: EscriptResponseMessage;
}

export interface EscriptResponseMessage {
    allergies: any[];
    benefitCoordinations: any[];
    drugs: any;
    kind: string;
    patient: Patient;
    pharmacy: any;
    prescriber: Prescriber;
    prescription: Prescription;
    supervision: any;
    urgency: EscriptUrgency;
    storeObj: Store;
    patientObj: Patient;
    prescriberObj: Prescriber;
    drugObj: Drug;
    cancel: boolean;
    exception:any;
}

export enum EscriptUrgency {
    Standard = 0,
    Urgent = 1
}

export class Escript extends HasLabel {
    toString(): string {
        return `${this.messageType}`;
    }
    get isCancelRequest(): boolean {
        return this.messageType == "RxCancelRequest";
    }

}
