import { render, staticRenderFns } from "./DoctorFormComponent.vue?vue&type=template&id=049d7c0a&scoped=true"
import script from "./DoctorFormComponent.vue?vue&type=script&lang=ts"
export * from "./DoctorFormComponent.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049d7c0a",
  null
  
)

export default component.exports