import { DrugStatus } from "@/models/Drug/Drug";
import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";
import DrugStatusEnum = DrugStatus.DrugStatusEnum;

export interface CompoundPatEd {
    id: number;
    compoundID: number;
    description: string;
    contraindications: string;
    administration: string;
    missedDoseInstructions: string;
    monitoring: string;
    sideEffects: string;
    storage: string;
}
export class CompoundPatEd {
    constructor(obj?:CompoundPatEd){
        if(obj) Object.assign(this, obj);
    }
}

export interface CompoundIngredients {
    id: number;
    compoundID: number;
    drugSource: number;
    drugID: number;
    quantity: number;
    unitOfMeasure: string;
    drugName: string;
}
export class CompoundIngredients {
    constructor(obj?:Array<CompoundIngredients>){
        if(obj) Object.assign(this, obj);
    }
}

export interface CompoundWarningLabels {
    id: number;
    compoundID: number;
    order: number;
    warning: string;
}
export class CompoundWarningLabels {
    constructor(obj?:Array<CompoundWarningLabels>){
        if(obj) Object.assign(this, obj);
    }
}

export interface CompoundDrug extends DrugProduct {
    compoundID?: number;
    ndc: string;
    name: string;
    awp: number;
    packageSize: number;
    unitOfMeasure: string;
    minimumDispenseQuantity: number;
    deaClass: string;
    instructions: string;
    status: DrugStatusEnum;
    patEd: CompoundPatEd;
    ingredients: Array<CompoundIngredients>;
    warningLabels: Array<CompoundWarningLabels> | null;
}

export class CompoundDrug extends DrugProduct {
    constructor(compoundId?:number|null, obj?:CompoundDrug|DrugProduct){
        super();
        this.compoundID = compoundId || 0;
        this.drugId = Number(this.compoundID);
        // this.status = new DrugStatus()
        this.activeStatus = DrugStatusEnum.Inactive;
        this.source = DrugSource.CompoundProduct;

        if(obj){
            Object.assign(this, obj)
            this.compoundID = obj.drugId;
        }
    }
    toString(): string {
        const productName = this.name;
        let preferred = '';
        if (this.status == DrugStatusEnum.ActiveAndPreferred) {
            preferred = '\u2B50 ';
        }
        if (productName)
            return this.ndc ? `${preferred}(NG) CompoundID:${this.compoundID}, NDC:${this.ndc} - ${productName} ${this.packageSize ? ("- PS: " + this.packageSize) : ""}`
                : productName;
        else return '';
    }

    set activeStatus(val: DrugStatusEnum) {
        this.status = val;
    }

    get activeStatus(): DrugStatusEnum {
        return this.status;
    }

    get productNameShort():string {
        return this.name;
    }
    set productNameShort(val: string) {
        this.name = val;
    }

    get isCII():boolean{
        return this.deaClass == "schedule II";
    }

    get NDCFormat542():string{
        return CompoundDrug.formatNDC542(this.ndc);
    }

    static formatNDC542(value:string | null) : string {
        if(!value) return '';
        return `${value.substr(0, 5)}-${value.substr(5, 4)}-${value.substr(9)}`;
    }
}

export namespace CompoundDrug {
    export class EquivalentCompoundDrug{
        compoundID? : number;
        ndc? : string;
        productName? : string;
        packageSize?: number;
    }
}
