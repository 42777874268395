export interface Consultation {
    id: number | null;
    patientId: number | string | undefined;
    storeId: number;
    storeName: string;
    status: number;
    statusName: string
    clinical: boolean;
    note: string;
    requested: string;
    patientName: string;
    dateOfBirth: string;
    phoneNumber: string;
    created: Date;    
    createdBy: string;
    closed: Date | null;
    closedBy: string;
}

export class Consultation {
    
}
