export class PrepopulateProblemQuestions {

    public prePopulateProblemQuestions(problem:string | null): string {
        let questions = "";

        if (problem == "Dropped Order/RX Never Went Out")
            questions = "Was dispensing pharmacy contacted regarding delay? \n\nIs item on backorder through warehouse?\n\nWas the label printed?\n"
        if (problem == "HSA Account Was Billed")
            questions = "What is the primary card on file for payment?\n"
        if (problem == "Incorrect Directions")
            questions = "What are the prescribed directions?\n\nWhat are the directions on the received product?\n\nWas the error due to incorrect prescribing or dispensing?\n"
        if (problem == "Incorrect Dosage Form")
            questions = "What dosage form was prescribed?\n\nWhat dosage form was dispensed?\n\nWas the error due to incorrect prescribing or dispensing?\n"
        if (problem == "Incorrect Drug")
            questions = "What was the medication prescribed?\n\nWhat was the medication dispensed?\n\nWas the error due to incorrect prescribing or dispensing?\n"
        if (problem == "Incorrect Price Or Misquoted Price")
            questions = "What was the quoted price?\n\nWhat price was charged to the patient?\n\nWas the price correct on date of dispensing based on drug memo notes or pricing schedule?\n"
        if (problem == "Incorrect Quantity")
            questions = "What was the prescribed quantity?\n\nWhat was the dispensed quantity?\n\nWas the error due to incorrect prescribing or dispensing?\n"
        if (problem == "Incorrect Recipient")
            questions = "Who was the intended recipient of the order?\n\nDid FedEx state that the package was delivered successfully?\n\nWas the incorrect recipient contacted by a Carepoint employee regarding the error?\n\nWas the package opened by the incorrect recipient?\n\nWas/were the package contents opened/used by the incorrect recipient?\n\nWas the address of the incorrect recipient verified (for return label purposes)?\n\nWas the address of the intended recipient verified?\n"
        if (problem == "Incorrect Strength")
            questions = "What was the strength prescribed?\n\nWhat was the strength dispensed?\n\nWas the error due to incorrect prescribing or dispensing?\n"
        if (problem == "Package Is Missing")
            questions = "Was the tracking checked? (in transit or delivered?)\n\nDid the patient or recipient check in/around their residence or office if they reside in a complex?\n\nWas the address verified with the patient?\n\nDid the patient check with neighbors for package?\n\nDid the patient check in their mailbox?\n\nIs patient requesting a replacement?\n"
        if (problem == "Package Sent To Wrong Address")
            questions = "What is the OTS address on the order?\n\nDid FedEx state that the package was delivered successfully?\n\nWas the address of the intended recipient verified?\n\nWas the error due to incorrect address entry in patient profile or FedEx scanning?\n"
        if (problem == "Package Was Damaged")
            questions = "Is the product in the package damaged or just the package itself (Carepoint box)\n\nWas there any visible leaking, leaching, or other signs of damage to the immediate container of the medication?\n\nWhat is the manufacturer-suggested temperature for excursion?\n\nHow much time between delivery confirmation and collection by recipient?\n\nWas the package damaged after receiving undamaged product from the pharmacy?\n\nWas the manufacturer contacted if it was a defective product (i.e. pump that isn't working, foam coming out as liquid, etc)\n"
        if (problem == "Package Delayed")
            questions = ""
        if (problem == "Refund Medication Copay")
            questions = "Was the medication sent out for delivery from the originating pharmacy?\n\nWas a replacement sent?\n\nWas the refund request due to a Carepoint internal error?\n"

        return questions
    }
}
