import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "src/pages/Dashboard/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";

import UserManagementPage from "@/pages/User/UserManagementPage.vue";
import PatientDashboardPage from "src/pages/Patient/PatientDashboardPage.vue";
import DoctorDashboard from "@/pages/Doctor/DoctorDashboard.vue";
import DrugDashboard from "@/pages/Drug/DrugDashboard.vue";
import OrderDetailsPage from "src/pages/Order/OrderDetailsPage.vue";
import PrescriptionDetailsPage from "src/pages/Prescription/PrescriptionDetailsPage.vue";

// import CategoryComponent from "src/components/CategoryComponent.vue";
// import ConsultationsPage from "src/pages/Consultations/ConsultationsPage.vue";
// import DoctorDetailsPage from "src/pages/Doctor/DoctorDetailsPage.vue";
// import DispensedPage from "src/pages/Reports/DispensedPage.vue";
// import DrugComponent from "src/pages/Drug/DrugDetailsPage.vue";
// import HistoryComponent from "src/components/HistoryComponent.vue";
// import InventoryReportPage from "src/pages/Reports/InventoryReportPage.vue";
// import IngestionComponent from "src/components/IngestionComponent.vue";
// import PatientDetailsPage from "src/pages/Patient/PatientDetailsPage.vue";
// import OTCItemDetailsPage from "src/pages/OTCItem/OTCItemDetailsPage.vue";
// import OpenedCasesPage from "src/pages/Cases/OpenedCasesPage.vue";
// import PrintQueuePage from "src/pages/Order/PrintQueuePage.vue";
// import IncompleteAnswersQueuePage from "src/pages/Questionary/IncompleteAnswersQueuePage.vue";
// import PendingForBillingQueuePage from "src/pages/Prescription/PendingForBillingQueuePage.vue";
// import ReversalQueuePage from "src/pages/Prescription/ReversalQueuePage.vue";
// import ConsignmentQueuePage from "src/pages/Consignments/ConsignmentQueuePage";
// import RxTransferQueuePage from "src/pages/RxTransferQueue/RxTransferQueuePage";
// import AccessLogComponent from "src/components/AccessLogComponent.vue";
// import VendorDetailsPage from "src/pages/Vendor/VendorDetailsPage.vue";
// import PlanDetailsPage from "src/pages/Plan/PlanDetailsPage.vue";
// import PrescriptionVerificationDetailsPage from "src/pages/PrescriptionVerification/PrescriptionVerificationDetailsPage.vue";
// import ProgramDetailsPage from "src/pages/Program/ProgramDetailsPage.vue";
// import PendingToAcceptErrorsPage from "src/pages/DispenseError/PendingToAcceptErrorsPage.vue";
// import PriorAuthorizationListPage from "src/pages/PriorAuthorization/PriorAuthorizationListPage.vue";
// import ReportsComponent from "src/components/ReportsComponent.vue";
// import ReportedErrorsPage from "src/pages/DispenseError/ReportedErrorsPage.vue";
// import ResolvedCasesPage from "src/pages/Cases/ResolvedCasesPage.vue";
// import StoreDetailsPage from "src/pages/Store/StoreDetailsPage.vue";
// import UserComponent from "src/components/User/UserComponent.vue";
// import VerificationQueuePage from "src/pages/PrescriptionVerification/VerificationQueuePage.vue";
// import SigMaintenancePage from "src/pages/SigTranslation/SigMaintenancePage.vue";
// import PatientInsuranceQueue from "src/pages/PatientInsuranceUploads/PatientInsuranceQueue";
// import PatientWebAllergyUpdateQueue from "src/pages/PatientWeb/PatientWebAllergyUpdateQueue";
// import PrescriptionOnHoldQueue from "@/pages/Prescription/PrescriptionOnHoldQueue.vue";
// import OnlineOrderQueue from "@/pages/PatientWeb/OnlineOrderQueue.vue";

// Page Headers
import CalendarHeader from "src/pages/Dashboard/Calendar/CalendarHeader";

import DefaultHeader from "src/pages/Dashboard/DefaultHeader";
import SweetAlertHeader from "src/pages/Dashboard/Components/Headers/SweetAlertHeader";

// Calendar
const Calendar = () =>
    import(
        /* webpackChunkName: "calendar" */ "src/pages/Dashboard/Calendar/CalendarRoute.vue"
        );
// Charts
const Charts = () =>
    import(/* webpackChunkName: "charts" */ "src/pages/Dashboard/Charts.vue");

// Components pages
const Buttons = () =>
    import(
        /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Buttons.vue"
        );
const GridSystem = () =>
    import(
        /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/GridSystem.vue"
        );
const Panels = () =>
    import(
        /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Panels.vue"
        );
const SweetAlert = () =>
    import(
        /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/SweetAlert.vue"
        );
const Notifications = () =>
    import(
        /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Notifications.vue"
        );
const Icons = () =>
    import(
        /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Icons.vue"
        );
const Typography = () =>
    import(
        /* webpackChunkName: "components" */ "src/pages/Dashboard/Components/Typography.vue"
        );

// Dashboard pages
const Dashboard = () =>
    import(
        /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/Dashboard.vue"
        );

import Widgets from "src/pages/Dashboard/Widgets.vue";

// Forms pages
const RegularForms = () => import("src/pages/Dashboard/Forms/RegularForms.vue");
const ExtendedForms = () =>
    import("src/pages/Dashboard/Forms/ExtendedForms.vue");
const ValidationForms = () =>
    import("src/pages/Dashboard/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Dashboard/Forms/Wizard.vue");

// Pages
const User = () =>
    import(
        /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/UserProfile.vue"
        );
const Pricing = () =>
    import(
        /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/Pricing.vue"
        );
const TimeLine = () =>
    import(
        /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/TimeLinePage.vue"
        );
const Login = () =>
    import(
        /* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Login.vue"
        );
const Register = () =>
    import(
        /* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Register.vue"
        );
const Lock = () =>
    import(/* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Lock.vue");

// TableList pages
const RegularTables = () =>
    import(
        /* webpackChunkName: "tables" */ "src/pages/Dashboard/Tables/RegularTables.vue"
        );
const ExtendedTables = () =>
    import(
        /* webpackChunkName: "tables" */ "src/pages/Dashboard/Tables/ExtendedTables.vue"
        );
const PaginatedTables = () =>
    import(
        /* webpackChunkName: "tables" */ "src/pages/Dashboard/Tables/PaginatedTables.vue"
        );

// const maintenanceMenu = {
//     path: "/maintenance",
//     component: DashboardLayout,
//     redirect: "/maintenance/patient",
//     name: "Maintenance",
//     children: [
//         {
//             path: "patient/:id",
//             name: "PatientDetails",
//             components: { default: PatientDetailsPage, header: DefaultHeader },
//             meta: { title: "Patient" },
//         },
//         {
//             path: "patient",
//             name: "Patient",
//             components: { default: PatientDetailsPage, header: DefaultHeader },
//             meta: { title: "Patient" },
//         },
//         {
//             path: "doctor",
//             name: "Doctor",
//             components: { default: DoctorDetailsPage, header: DefaultHeader },
//             meta: { title: "Doctor" },
//         },
//         {
//             path: "vendor",
//             name: "Vendor",
//             components: { default: VendorDetailsPage, header: DefaultHeader },
//             meta: { title: "Vendor" },
//         },
//         {
//             path: "store",
//             name: "Store",
//             components: { default: StoreDetailsPage, header: DefaultHeader },
//             meta: { title: "Store" },
//         },
//         {
//             path: "drug",
//             name: "Drug",
//             components: { default: DrugComponent, header: DefaultHeader },
//             meta: { title: "Drug" },
//         },
//         {
//             path: "category",
//             name: "Category",
//             components: { default: CategoryComponent, header: DefaultHeader },
//             meta: { title: "Drug Category" },
//         },
//         {
//             path: "program",
//             name: "Program",
//             components: { default: ProgramDetailsPage, header: DefaultHeader },
//             meta: { title: "Program" },
//         },
//         {
//             path: "otc-Item",
//             name: "OTCItem",
//             components: { default: OTCItemDetailsPage, header: DefaultHeader },
//             meta: { title: "OTC Item" },
//         },
//         {
//             path: "consultation",
//             name: "Consultation",
//             components: { default: ConsultationsPage, header: DefaultHeader },
//             meta: { title: "Consultation" },
//         },
//         {
//             path: "plan",
//             name: "Plan",
//             components: { default: PlanDetailsPage, header: DefaultHeader },
//             meta: { title: "Plan" },
//         },
//         {
//             path: "users",
//             name: "Users",
//             components: { default: UserComponent, header: DefaultHeader },
//             meta: { title: "Users" },
//         },
//         {
//             path: "case/opened",
//             name: "Case",
//             components: { default: OpenedCasesPage, header: DefaultHeader },
//             meta: { title: "Cases - Opened" },
//         },
//         {
//             path: "case/resolved",
//             name: "Case",
//             components: { default: ResolvedCasesPage, header: DefaultHeader },
//             meta: { title: "Cases - Resolved" },
//         },
//         {
//             name: "SigMaintenance",
//             path: "Sig-Maintenance",
//             components: { default: SigMaintenancePage, header: DefaultHeader },
//             meta: { title: "Sig" },
//         },
//     ]
// };

// const reportsMenu = {
//     path: "/reports",
//     component: DashboardLayout,
//     redirect: "/reports/reports",
//     name: "ReportsCategory",
//     children: [
//         {
//             path: "reports",
//             name: "Reports",
//             components: { default: ReportsComponent, header: DefaultHeader },
//             meta: { title: "Reports" },
//         },
//         {
//             path: "history",
//             name: "History",
//             components: { default: HistoryComponent, header: DefaultHeader },
//             meta: { title: "History Report" },
//         },
//         {
//             path: "access",
//             name: "Access",
//             components: { default: AccessLogComponent, header: DefaultHeader },
//             meta: { title: "Access Report" },
//         },
//         {
//             path: "inventory",
//             name: "Inventory",
//             components: { default: InventoryReportPage, header: DefaultHeader },
//             meta: { title: "Inventory Report" },
//         },
//         {
//             path: "dispensed",
//             name: "Dispensed",
//             components: { default: DispensedPage, header: DefaultHeader },
//             meta: { title: "Dispensed Report" },
//         }
//     ]
// };

const cphubMenu = {
    path: "/cphub",
    component: DashboardLayout,
    redirect: "/dashboard",
    name: "CPHub",
    children: [
        {
            name: "UserManagement",
            path: "users",
            components: {
                default: UserManagementPage,
                header: DefaultHeader
            },
            meta: {title: "User Management", requiresAuth: true},
        },
        {
            name: "PatientDashboard",
            path: "patient-dashboard/:id",
            components: {
                default: PatientDashboardPage,
                header: DefaultHeader
            },
            meta: {title: "Patient Dashboard", requiresAuth: true},
        },
        {
            name: "PatientDashboardNew",
            path: "patient-dashboard",
            components: {
                default: PatientDashboardPage,
                header: DefaultHeader
            },
            meta: {title: "Patient Dashboard", requiresAuth: true},
        },
        {
            name: "DoctorDashboard",
            path: "doctor-dashboard",
            components: {
                default: DoctorDashboard,
                header: DefaultHeader
            },
            meta: {title: "Doctor Dashboard", requiresAuth: true},
        },
        {
            name: "DrugDashboard",
            path: "drug-dashboard",
            components: {
                default: DrugDashboard,
                header: DefaultHeader
            },
            meta: {title: "Drug Dashboard", requiresAuth: true},
        },
        {
            name: "Order",
            path: "order-patient/:patientId/",
            components: {
                default: OrderDetailsPage,
                header: DefaultHeader
            },
            meta: {title: "Order Details", requiresAuth: true},
        },
        {
            name: "OrderDetails",
            path: "order/:orderId",
            components: {
                default: OrderDetailsPage,
                header: DefaultHeader
            },
            meta: {title: "Order Details", requiresAuth: true},
        },
        {
            name: "PrescriptionDetails",
            path: "prescription/:storeID/:rxNumber/:rfNumber",
            components: { default: PrescriptionDetailsPage, header: DefaultHeader },
            meta: { title: "Prescription Details" },
        },
        {
            name: "PrescriptionDetailsId",
            path: "prescription/:rxid",
            components: {
                default: PrescriptionDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Prescription Details" },
        },
        {
            name: "Prescription",
            path: "prescription",
            components: {
                default: PrescriptionDetailsPage,
                header: DefaultHeader
            },
            meta: { title: "Prescription Details" },
        },

        /************/

        // {
        //     name: "OrderVerification",
        //     path: "order-verification/:orderId/",
        //     components: {
        //         default: OrderDetailsPage,
        //         header: DefaultHeader,
        //     },
        //     params: {
        //         mode: "verify"
        //     },
        //     meta: { title: "Order Verification" },
        // },
        // {
        //     name: "Ingestion",
        //     path: "ingestion",
        //     components: { default: IngestionComponent, header: DefaultHeader },
        //     meta: { title: "Rx Intake" },
        // },
        // {
        //     name: "PV2",
        //     path: "pv2/prescription/:storeID/:rxNumber/:rfNumber",
        //     components: {
        //         default: PrescriptionVerificationDetailsPage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Prescription Verification" },
        // },
        // {
        //     name: "PrescriptionVerification",
        //     path: "prescription-verification/:pvNbr",
        //     components: {
        //         default: PrescriptionVerificationDetailsPage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Prescription Verification" },
        // },
        // {
        //     name: "VerificationQueue",
        //     path: "verification-queue",
        //     components: {
        //         default: VerificationQueuePage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Verification Queue" },
        // },
        // {
        //     name: "OnHoldQueue",
        //     path: "on-hold-queue",
        //     components: { default: PrescriptionOnHoldQueue, header: DefaultHeader },
        //     meta: { title: "On Hold Queue" },
        // },
        // {
        //     name: "PrintQueue",
        //     path: "print-queue",
        //     components: {
        //         default: PrintQueuePage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Print Queue" },
        // },
        // {
        //     name: "PatientInsuranceQueue",
        //     path: "patient-insurance-queue",
        //     components: {
        //         default: PatientInsuranceQueue,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Insurance Queue" },
        // },
        // {
        //     name: "PatientWebAllergyUpdateQueue",
        //     path: "patient-web-allergy-update-queue",
        //     components: {
        //         default: PatientWebAllergyUpdateQueue,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Allergy Queue" },
        // },
        // {
        //     name: "OnlineOrderQueue",
        //     path: "online-order-queue",
        //     components: {
        //         default: OnlineOrderQueue,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Online Order Queue" },
        // },
        // {
        //     name: "ConsignmentQueue",
        //     path: "consignment-queue",
        //     components: {
        //         default: ConsignmentQueuePage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Consignment Queue" },
        // },
        // {
        //     name: "IncompleteAnswersQueue",
        //     path: "incomplete-answers-queue",
        //     components: {
        //         default: IncompleteAnswersQueuePage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Incomplete Answers Queue" },
        // },
        // {
        //     name: "RxTransferQueue",
        //     path: "RxTransferQueue",
        //     components: {
        //         default: RxTransferQueuePage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Transfer Queue" },
        // },
        // {
        //     name: "PendingForBillingQueuePage",
        //     path: "pending-billing-prescriptions-queue",
        //     components: {
        //         default: PendingForBillingQueuePage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Billing Queue" },
        // },
        // {
        //     name: "ReversalQueuePage",
        //     path: "reversal-queue",
        //     components: {
        //         default: ReversalQueuePage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Reversal Queue" },
        // },
        // {
        //     name: "PriorAuthorizationList",
        //     path: "prior-authorizations",
        //     components: {
        //         default: PriorAuthorizationListPage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Prior Authorizations" },
        // },
        // {
        //     name: "DispenseErrors",
        //     path: "dispense-errors",
        //     components: {
        //         default: ReportedErrorsPage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Dispense Errors" },
        // },
        // {
        //     name: "PendingDispenseErrors",
        //     path: "pending-errors",
        //     components: {
        //         default: PendingToAcceptErrorsPage,
        //         header: DefaultHeader
        //     },
        //     meta: { title: "Dispense Errors - Pending" },
        // },
    ]
};
const componentsMenu = {
    path: "/components",
    component: DashboardLayout,
    redirect: "/components/buttons",
    name: "Components",
    children: [
        {
            path: "buttons",
            name: "Buttons",
            components: {default: Buttons, header: DefaultHeader},
        },
        {
            path: "grid-system",
            name: "Grid System",
            components: {default: GridSystem, header: DefaultHeader},
        },
        {
            path: "panels",
            name: "Panels",
            components: {default: Panels, header: DefaultHeader},
        },
        {
            path: "sweet-alert",
            name: "Sweet Alert",
            components: {default: SweetAlert, header: SweetAlertHeader}
        },
        {
            path: "notifications",
            name: "Notifications",
            components: {default: Notifications, header: DefaultHeader},
        },
        {
            path: "icons",
            name: "Icons",
            components: {default: Icons, header: DefaultHeader},
        },
        {
            path: "typography",
            name: "Typography",
            components: {default: Typography, header: DefaultHeader},
        }
    ]
};
const formsMenu = {
    path: "/forms",
    component: DashboardLayout,
    redirect: "/forms/regular",
    name: "Forms",
    children: [
        {
            path: "regular",
            name: "Regular Forms",
            components: {default: RegularForms, header: DefaultHeader},
        },
        {
            path: "extended",
            name: "Extended Forms",
            components: {default: ExtendedForms, header: DefaultHeader},
        },
        {
            path: "validation",
            name: "Validation Forms",
            components: {default: ValidationForms, header: DefaultHeader},
        },
        {
            path: "wizard",
            name: "Wizard",
            components: {default: Wizard, header: DefaultHeader},
        }
    ]
};

const tablesMenu = {
    path: "/table-list",
    component: DashboardLayout,
    redirect: "/table-list/regular",
    name: "Tables",
    children: [
        {
            path: "regular",
            name: "Regular Tables",
            components: {default: RegularTables, header: DefaultHeader},
        },
        {
            path: "extended",
            name: "Extended Tables",
            components: {default: ExtendedTables, header: DefaultHeader},
        },
        {
            path: "paginated",
            name: "Paginated Tables",
            components: {default: PaginatedTables, header: DefaultHeader},
        }
    ]
};

const pagesMenu = {
    path: "/pages",
    component: DashboardLayout,
    name: "Pages",
    redirect: "/pages/user",
    children: [
        {
            path: "user",
            name: "User Page",
            components: {default: User, header: DefaultHeader},
        },
        {
            path: "timeline",
            name: "Timeline Page",
            components: {default: TimeLine, header: DefaultHeader},
        }
    ]
};

const authPages = {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [
        {
            path: "/login",
            name: "Login",
            components: {
                default: Login,
                header: DefaultHeader
            },
        },
        {
            path: "/register",
            name: "Register",
            component: Register
        },
        {
            path: "/pricing",
            name: "Pricing",
            component: Pricing
        },
        {
            path: "/lock",
            name: "Lock",
            component: Lock
        }
    ]
};

const DashboardHeader = () =>
    import(
        /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/DashboardHeader.vue"
        );

const routes = [
    {
        path: "/",
        redirect: "/cphub",
        name: "Home",
        meta: {title: "Brand Portal"},

    },
    cphubMenu,
    /*maintenanceMenu,
    reportsMenu,*/
    componentsMenu,
    formsMenu,
    tablesMenu,
    pagesMenu,
    authPages,
    {
        path: "/",
        component: DashboardLayout,
        redirect: "/dashboard",
        name: "DashboardCategory",
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                components: {default: Dashboard, header: DashboardHeader},
                meta: {title: "Company Dashboard", requiresAuth: true},
            },
            {
                path: "calendar",
                name: "Calendar",
                components: {default: Calendar, header: CalendarHeader}
            },
            {
                path: "charts",
                name: "Charts",
                components: {default: Charts, header: DefaultHeader},
            },
            {
                path: "widgets",
                name: "Widgets",
                components: {default: Widgets, header: DefaultHeader},
            }
        ]
    },
    {path: "*", component: NotFound}
];

export default routes;
