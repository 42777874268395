<template>
    <b-row>
        <b-col id="payment-form">
            <div ref="card" class="form-control m-2"></div>
        </b-col>
        <b-col>
            <n-button type="success" size="sm" @click="createCardToken">Add Card</n-button>
        </b-col>
    </b-row>
    <!--<div class="d-flex flex-column align-items-center">
        <h2 class="text-primary">Amount {{payAmount}}</h2>
        <form id="payment-form" class="w-75 px-5 d-flex flex-column align-items-center">
            <div ref="card" class="form-control m-2">-->
    <!-- A Stripe Element will be inserted here. -->
    <!--</div>
            <input class="btn btn-primary shadow-sm" value="Add Card" v-on:click="createCardToken" />
        </form>
    </div>-->
</template>
<script>
    import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
    @Component({
        name: "StripeVueCard",
    })
    export default class StripeVueCard extends Vue {
        //spk = "pk_live_6eFaEdSHQLSIayd43Dhb3ily00ieAjZNJc";
        spk = "pk_test_6qlXqcFdhIA2Ix0w8xBWGljk00Bj2UY7mm";
        stripe = undefined;
        card = undefined;

        mounted() {
            this.stripe = Stripe(this.spk);
            this.card = this.stripe.elements().create('card');
            this.card.mount(this.$refs.card);
        }

        @Emit("tokenCreated")
        createCardToken() {
            return this.stripe.createToken(this.card);
        }

    }
</script>

<style scoped>
</style>
