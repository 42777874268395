

    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { Consultation } from '../../models/Consultation';
    import Radio from "src/components/Inputs/Radio.vue";
    import { Patient } from "@/models/Patient";
    import { Store } from "@/models/Store";
    import axios from 'axios';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';

    @Component({
        name: "NewConsultationFormComponent",
        components: {
            [Radio.name]: Radio
        }
    })
    export default class NewConsultationFormComponent extends Vue {
        @Prop() private patient!: Patient;
        @Prop() private store!: Store;

        private newConsultation: Consultation = new  Consultation();

        created() {
            this.newConsultation.storeId = this.store.id as number;
            this.newConsultation.status = 1;
            this.newConsultation.patientId = this.patient.id;
            this.newConsultation.note = "";
        }
        
        private saveWasClicked = false;
        saveNewConsultation() {
            this.saveWasClicked = true;
            axios.post('/Consultation/', this.newConsultation).then(response => {
                this.$emit('updateList');
            }).catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Consultation"));
                console.log("Error creating consultation", {error, response: error?.response })                
            });
        }
    }
