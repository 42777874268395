
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import Axios, { AxiosRequestConfig } from 'axios';
import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import EnumSelector from '@/components/EnumSelector.vue';
import NotesForm from '@/components/Note/NotesForm.vue';
import NotesList from '@/components/Note/NotesList.vue';
import InsuranceBillingComponent from '@/components/InsurancePlan/InsuranceBillingComponent.vue';
import PrescriptionImageComponent from '@/components/Prescription/PrescriptionImageComponent.vue';
import { Lock, LockHandler } from '@/mixins/LockHandler';
import {
    DAWCode,
    DeliveryCode,
    OnHoldSubstatusCode,
    OriginCode,
    Prescription,
    RefillStatusCode,
    TransferTo
} from '@/models/Prescription';
import { Prescriber } from '@/models/Prescriber';
import { Patient } from '@/models/Patient';
import { Program } from '@/models/Program';
import { Store } from '@/models/Store';
import { Drug } from '@/models/Drug/Drug';
import { DrugCategory } from '@/models/DrugCategory';
import { Escript, EscriptResponseMessage } from '@/models/Escript';

import { Location } from 'vue-router';
import { DatePicker } from 'element-ui';
import { EntityType, Note } from '@/models/Note';
import PatientFormComponent from '@/components/Patient/PatientFormComponent.vue';
import DoctorFormComponent from '@/components/Doctor/DoctorFormComponent.vue';
import Checkbox from "@/components/Inputs/Checkbox.vue";

import moment from 'moment';
import { Claim } from '@/models/Claim';
import DirectionsComponent from "@/components/Prescription/DirectionsComponent.vue";
import EscriptButtonComponent from "@/components/Prescription/EscriptButtonComponent.vue";
import TextUtils from '@/util/TextUtils';
import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";
import { NonGSDDDrug } from "@/models/Drug/NonGSDDDrug";
import { CompoundDrug } from "@/models/Drug/CompoundDrug";

let processingInsurance = false;
let previousRoute: any;
@Component({
    beforeRouteLeave(to, from, next) {
        if (processingInsurance == true) {
            alert("Please wait until insurance processing has finished");
            next(false);
        } else {next();}
    },
    beforeRouteEnter(to: any, from: any, next: any) {
        previousRoute = from;
        next();
    },
    name: "PrescriptionDetailsPage",
    components: {
        DirectionsComponent,
        SearchComponent,
        NewClearSaveButtons,
        EnumSelector,
        InsuranceBillingComponent,
        PrescriptionImageComponent,
        [DatePicker.name]: DatePicker,
        PatientFormComponent,
        DoctorFormComponent,
        NotesForm, NotesList, Checkbox,
        EscriptButtonComponent,
    },
})
export default class PrescriptionDetailsPage extends Mixins(LockHandler) {
    //Props
    @Prop({ default: false }) private refillBatchMode!: boolean;
    @Prop() prescriptionsInBatch!: Array<Prescription>;

    // Entities
    private prescription: Prescription = new Prescription();
    private transferTo: TransferTo = {} as TransferTo;
    private store: Store = new Store();
    private newStore: Store = new Store();
    private patient: Patient = new Patient();
    private doctor: Prescriber = new Prescriber();
    private program: Program = new Program();
    private drug: DrugProduct = new Drug();
    private escript: Escript = new Escript();
    private claims: Array<Claim> = [];
    private drugCategories: Array<DrugCategory> = [];
    private prescriptionLock: Lock | null = null;

    private errorNote: Note = {} as Note;
    private patientEntityType = EntityType.Patient;
    private dispenseErrorEntityType = EntityType.DispenseError;

    private escriptResponseMessage?: EscriptResponseMessage;

    get escriptPatient() {
        return this.escriptResponseMessage?.patient ?? null;
    }

    get escriptPrescriber() {
        return this.escriptResponseMessage?.prescriber ?? null;
    }

    // Arguments
    private imageID?: number;
    private escriptID?: number;
    private queueItemID?: number;

    //Flags
    private newMode = false;
    private fromIngestion = false;
    private fromIncompleteAnwersQueue = false;
    private showPatientAlert = false;
    private wasResolved: boolean = false;
    private newRefillMode = false;
    private loadingTransferFax = false;

    //View values
    private dispensedQuantity: number = 0;
    private originCode: number = 0;
    private statusCode: number = 0;
    private deliveryCode: number = 0;
    private dawCode: number = 0;
    private substatusCode: number = 0;

    // Enumerations
    private originCodes = OriginCode;
    private dawCodes = DAWCode;
    private statusCodes = RefillStatusCode;
    private onHoldSubstatusCode = OnHoldSubstatusCode;
    private deliveryCodes = DeliveryCode;
    private drugStatuses: string[] = [
        "Inactive",
        "Active",
        "Active and Preferred",
        "Blocked From Fills",
    ];
    private notSelectableStatus = [
        RefillStatusCode.TransferOut
        , RefillStatusCode.TransferIn
        , RefillStatusCode.Canceled
    ];

    // Buttons config
    protected buttonTitles: NewClearSaveButtons = {
        cancel: "Back to Dashboard"
    } as unknown as NewClearSaveButtons;

    //Notes
    private hardcopyAnnotations: Note[] = [];
    private hardcopyAnnotationsFields: any[] = [
        { label: "Note", key: "body" },
        { label: "By", key: "createdBy" },
        { label: "On", key: "created", formatter: this.formattedDate, sortable: true },
    ];
    private editPatientForm = false;
    private showPatientForm = false;
    private patientButtonTitles = {
        new: "",
        clear: "",
        save: "Save Patient",
        cancel: "Close Patient Form"
    };
    private showDoctorForm = false;
    private editDoctorForm = false;
    private doctorButtonTitles = {
        new: "",
        clear: "",
        save: "Save Doctor",
        cancel: "Close Doctor Form"
    };

    private coordinationOfBenefits: any[] = [];
    private imageURLs: string[] = [];
    private drugDetails: any = null;
    private faxNumber: string = '';
    private nextRefillRxId = '';
    //--------------
    private disableFields: boolean = true;
    private programTransferId: number | null = null;

    created() {
        this.create();
    }

    create() {
        if (this.routeRxId) {
            this.getPrescription(this.routeRxId);
        }
    }

    get isMD2RX(): boolean {
        return this.$userProgram == 'MD2RX';
    }

    @Watch('doctor') doctorChanged(val1: Prescriber, val2: Prescriber) {
        console.log('val1', val1);
        console.log('this.doctor', this.doctor);
    }

    backToPreviousPage() {
        this.$router.push({
            name: 'PatientDashboard',
            params: { id: (this.prescription?.patientID.toString() || '') }
        });
    }

    get showSearchBar(): boolean {
        return !(this.prescription?.rxNumber || this.newMode || this.fromIngestion || this.routeRxId);
    }

    get routeRxId(): string | any {
        let res = null;
        const storeID = Number(this.$route.params.storeID);
        const rxNumber = Number(this.$route.params.rxNumber);
        const rfNumber = Number(this.$route.params.rfNumber);
        const rxid = this.$route.params.rxid;
        if (!isNaN(storeID) && !isNaN(rxNumber) && !isNaN(rfNumber))
            res = `${storeID}-${rxNumber}-${rfNumber}`;
        else if (rxid)
            res = rxid;
        return res;
    }

    get drugLicenseType(): string {
        return this.drugDetails?.productDetail?.licenseType || "unknown";
    }

    get hasClaims(): boolean {
        return this.claims.length > 0;
    }

    get hasShipped(): boolean {
        return !!(this.prescription && this.prescription.rxNumber && !this.prescription.rfNumber);
    }

    get fillsLeft(): number {
        if (!this.prescription) return 0;
        return this.prescription.refills;
        //if (!this.prescription.rfNumber) return this.prescription.refills;
        //return this.prescription.refills - this.prescription.rfNumber;
    }

    get notRefillable(): boolean {
        const validRefillMode = !this.newRefillMode || this.prescription?.isRefillable;
        return !validRefillMode;
    }

    get claimIsPaid(): boolean {
        const res = this.claims.some(cl => cl.transactionStatus == "Paid");
        return res;
    }

    get validBillingInformation() {
        const isFilldateInTheFuture = moment(this.prescription.fillDate).isAfter(moment().toDate(), 'day')
            && moment(this.prescription.fillDate).isBefore(this.expiration, 'day');

        return this.claimIsPaid || this.prescription.isCashedOut || this.prescription.status == RefillStatusCode.Void || this.prescription.status == RefillStatusCode.OnHold || isFilldateInTheFuture;
    }

    claimsLoaded(billedClaims: Claim[], rx: Prescription) {
        this.claims = billedClaims;
        this.updatePricing(rx);
    }

    updatePricing(rx: Prescription) {
        if (rx?.rxNumber) {
            this.prescription.patientPay = rx.patientPay;
            this.prescription.cost = rx.cost;
            this.prescription.totalPrice = rx.totalPrice;
            this.prescription.tax = rx.tax;
            this.prescription.priceScheduleID = rx.priceScheduleID;
        }
    }

    get rxWasBilled(): boolean {
        return !!(this.claims?.some(cl => cl.transactionStatus == "Paid"));
    }

    changeDispense(num: number) {
        if (this.prescription.quantity.toString().length > 11) {
            this.prescription.quantity = Number(this.prescription.quantity.toString().substring(0, 11));
        }
        if (num.toString().length > 11) {
            num = Number(num.toString().substring(0, 11));
        }
        this.dispensedQuantity = Number(num);
        this.prescription.dispensed = Number(num);
    }

    get isMultifill(): boolean {
        return (this.dispensedQuantity || 0) > (this.prescription?.quantity || 0);
    }

    fetchPrescription(prescription: Prescription) {
        if (prescription.rfNumber || prescription.rfNumber == 0)
            this.getPrescription(prescription.rxID);
    }

    private initialDispensedQty = 0;

    /**
     * rxID: Concatenated Rx Ids in the format StoreID-RxNumber-RfNumber
     * setupRefill: If true, the method will load the prescription for RxID and immediattly will set up its refill in the front end ready to be edited and saved.
     */
    getPrescription(rxID: string, setupRefill = false) {
        // linked objects will be automatically fetched by the SearchComponent when we set the objects...
        // if we manually fetch them they are called for twice.
        Axios.get(`/Prescription/${rxID}`)
            .then(response => {
                if (!this.prescription) return;

                this.prescription = Object.assign(new Prescription(), response.data);
                this.setViewValues();

                this.initialDispensedQty = this.prescription.dispensed;

                //Remove current status from notSelectableStatus so it can be shown as selected.
                this.notSelectableStatus = this.notSelectableStatus.filter(st => st != this.prescription.status);

                this.patient.id = this.prescription.patientID;
                this.doctor.id = this.prescription.prescriberID;
                this.store.id = this.prescription.storeID;
                this.program.id = (this.prescription.programID || 0);

                this.enableExpirationDateEstimation = false;

                this.drug = new Drug(this.prescription.packageID);
                this.drug.source = this.prescription.drugSource;
                this.getDrugDetails(this.drug);
                this.fetchAnnotations();
                //this.fetchDispenseError();
                //this.fetchEscript();
            })
            .catch(error => {
                console.error("Error while getting Prescription", { error, response: error?.response });
            });
    }

    @Watch('patient.alert') onAlertChange(value: string, oldValue: string) {
        this.showPatientAlert = (!!value && !oldValue);
    }

    get showDoctorAlert(): boolean {
        return !!(this.doctor?.alert);
    }

    get isRxNotActive(): boolean {
        return !this.prescription.isActive;
    }

    get inactiveRxAlert() {
        const statusStr = TextUtils.camelCaseToNormal(RefillStatusCode[this.prescription.status]);
        const subStatus = this.prescription.status == RefillStatusCode.OnHold && this.prescription.subStatus ?
            TextUtils.camelCaseToNormal(OnHoldSubstatusCode[this.prescription.subStatus]) : '';
        return `Prescription is not active because its status is ${statusStr}${subStatus ? (" - " + subStatus) : ''}.`;
    }

    get prescriptionIdentifier(): string {
        if (!this.prescription) return "";
        if (this.prescription.storeID == null || this.prescription.rxNumber == null || this.prescription.rfNumber == null) return "N/A";
        return `${this.prescription.storeID}-${this.prescription.rxNumber}-${this.prescription.rfNumber}`;
    }

    get storeLocationInformation(): string {
        if (!this.store?.id) return "";
        return `${this.store.addressCity}, ${this.store.addressState}`;
    }

    get drugExpDate(): any {
        return this.prescription?.drugExpirationDate;
    }

    get typedBy(): string {
        return this.prescription?.createdBy?.split("@")[0];
    }

    set drugExpDate(value: any) {
        if (!this.prescription) return;
        this.prescription.drugExpirationDate = value ? value : undefined;
    }

    get origRxDate(): any {
        return this.prescription?.originalRxDate;
    }

    set origRxDate(value: any) {
        if (!this.prescription) return;
        this.prescription.originalRxDate = value ? value : undefined;
    }

    private expiration: Date | any = null;
    private writtenDate: Date | any = null;
    private enableExpirationDateEstimation = true;

    @Watch('writtenDate') chagedWrittenDate(value: string | Date | null) {

        let val = value;
        if (!(val instanceof Date))
            val = moment(val).toDate();

        this.prescription.writtenDate = val;

        if (value && this.enableExpirationDateEstimation) {
            this.estimateExpirationBasedOnPackage(this.prescription.packageID, val);
        }

        if (!this.enableExpirationDateEstimation)
            this.enableExpirationDateEstimation = true;
    }

    estimateExpirationBasedOnPackage(packageID: number, writtenDate: Date) {
        let estimatedExpirationDate = moment(writtenDate);
        if (packageID) {
            Axios.get(`Drug/${packageID}/DeaSchedule`)
                .then(response => {
                    const deaSchedule = response.data;
                    switch (deaSchedule) {
                        case 2:
                            estimatedExpirationDate = estimatedExpirationDate.add(7, 'days');
                            break;
                        case 3:
                        case 4:
                        case 5:
                            estimatedExpirationDate = estimatedExpirationDate.add(6, 'months');
                            break;
                        default:
                            estimatedExpirationDate = estimatedExpirationDate.add(1, 'years');
                            break;
                    }
                    this.expiration = estimatedExpirationDate.toDate();
                });
        } else {
            estimatedExpirationDate = estimatedExpirationDate.add(1, 'years');
            this.expiration = estimatedExpirationDate.toDate();
        }
    }

    get patientDOB(): string {
        if (!this.patient || !this.patient.dateOfBirth) return "";
        return moment(this.patient.dateOfBirth).format("L");
        //this.patient && this.formattedDate(this.patient.dateOfBirth)
    }

    formattedDate(value: Date): string {
        if (!value) return '';
        const date = moment.utc(value);
        return date.format("L");
    }

    formattedDateandTime(value: Date): string {
        if (!value) return '';
        const date = moment.utc(value);
        return date.format("lll");
    }

    @Watch('store') storeUpdate(value: any) {
        if (!value || !this.prescription) return;
        this.prescription.storeID = value.id;
    }

    patientUpdate(value: any) {
        if (!value || !this.prescription) return;
        this.prescription.patientID = value.id;
        if (!this.prescription.deliveryOption
            && (value.defaultDelivery != undefined || value.defaultDelivery != null)
            && !this.prescription.rxNumber
        ) {
            this.deliveryCode = value.defaultDelivery;
        }
    }

    programUpdate(value: any) {
        if (!this.prescription) return;
        if (!value?.id)
            this.program = {} as Program;

        this.prescription.programID = value?.id;
    }

    @Watch('drugDetails')
    drugDetailsUpdate(value: Drug | null, oldValue: Drug | null) {
        this.imageURLs = [];
        const baseURL = Axios?.defaults?.baseURL || "";
        if (!value) return;
        console.log("value: ", JSON.stringify(value));
        const imageURI =
            baseURL + `/drug/get-image?packageId=${value.packageID}&drugSource=${value.source}`;
        this.imageURLs.push(imageURI);

        this.drug.ndc = value.ndc;
        if (this.drug instanceof Drug) this.drug.productNameLong = value.productNameLong;
    }

    private loadingDrugDetails = false;

    @Watch("drug")
    drugUpdate(value: DrugProduct, oldValue: DrugProduct) {
        if (!value || !value.drugId
            || (oldValue && oldValue.drugId && +value.drugId == +oldValue.drugId)
            //|| this.disableFields
        ) return;

        let packId: number = +value.drugId;

        let drug: DrugProduct;
        switch(value.source) {
            case DrugSource.GsddFederal : drug = new Drug(packId, value); break;
            case DrugSource.NonGsddProduct: drug = new NonGSDDDrug(packId, value); break;
            case DrugSource.CompoundProduct: drug = new CompoundDrug(packId, value); break;
        }

        this.fetchDrug(drug);
    }

    getDrugDetails(drug: DrugProduct) {
        const packId = drug.drugId;
        const drugSource = drug.source;
        this.loadingDrugDetails = true;
        const url = `/Drug/${packId}`;
        const config = {
            params: { drugSource: drugSource, },
        } as AxiosRequestConfig;
        Axios.get<DrugProduct>(url, config)
            .then(response => {
                if (this.prescription?.packageID == response.data.drugId) {
                    switch(response.data.source) {
                        case DrugSource.GsddFederal: new Drug(response.data.drugId, response.data); break;
                        case DrugSource.NonGsddProduct: new NonGSDDDrug(response.data.drugId, response.data); break;
                        case DrugSource.CompoundProduct: new CompoundDrug(response.data.drugId, response.data); break;
                    }

                    this.drugDetails = response.data;
                    this.drugDetails.status = this.drug.activeStatus;
                    this.prescription.drugSource = drug.source;
                }
            })
            .catch(error => {
                console.error('Error while getting drug details', { error, response: error?.response });
            })
            .finally(() => {
                this.loadingDrugDetails = false;
            });
    }

    fetchDrug(drug: DrugProduct) {
        const packId = Number(drug.drugId);
        if (this.prescription) this.prescription.packageID = packId;

        //Estimate expiration date only if we are creating a new RX
        if (!this.prescription?.rxNumber) {
            this.estimateExpirationBasedOnPackage(packId, moment(this.writtenDate).toDate());
        }

        this.loadingDrugDetails = true;

        const url = `/Drug/${packId}`;
        const config = { params: { drugSource: drug.source, }, };
        Axios.get(url, config)
            .then(response => {
                if (!this.disableFields && this.prescription?.packageID == response.data.packageID) {
                    this.drugDetails = response.data;
                }
            })
            .catch(error => {
                console.error('Error while getting drug details', { error, response: error?.response });
            })
            .finally(() => {
                this.loadingDrugDetails = false;
            });
    }

    setPrescriptionDefaultValues() {
        //Default values needs to be assign to another variable
        this.prescription.originalRxDate = moment().toDate();
        this.prescription.fillDate = moment().toDate();

        this.dawCode = DAWCode.NotSpecified;
        this.originCode = OriginCode.NotSpecified;
        this.statusCode = RefillStatusCode.Refillable;
        this.substatusCode = OnHoldSubstatusCode.WaitingOnMd;
        this.writtenDate = moment().toDate();
    }

    clearForm(continueSameImage = false) {
        if (this.prescriptionLock && this.prescriptionLock.refreshURL) {
            this.releaseLockAtURL(this.prescriptionLock.refreshURL);
            this.prescriptionLock = null;
        }
        this.clearPrescription(continueSameImage);
        this.drugCategories = [];
        this.claims = [];
        (this.$refs.insuranceComponent as any).clearComponent();
        this.setPrescriptionDefaultValues();
        this.notSelectableStatus = [
            RefillStatusCode.TransferOut
            , RefillStatusCode.TransferIn
            , RefillStatusCode.Canceled
        ];

        this.enableExpirationDateEstimation = true;
        this.initialDispensedQty = 0;

        this.newMode = false;
        this.newRefillMode = false;
        this.$nextTick(() => {
            this.$forceUpdate();
        });
    }

    clearPrescription(continueSameImage = false) {
        this.clearAllLocks();
        this.prescription = new Prescription();

        if (!continueSameImage) {
            this.patient = new Patient();
            this.doctor = new Prescriber();
            this.store = new Store();
        }

        this.program = new Program();
        this.drug = new Drug();
        this.expiration = null;
        this.writtenDate = null;
        this.coordinationOfBenefits = [];
        this.drugDetails = null;

        this.dispensedQuantity = 0;
        // when we clear we need to clear the imageID query if it exists.
        this.clearQuery();
    }

    undoChanges() {
        this.clearForm();
        window.scrollTo(0, 0);
    }

    setViewValues(isEscript = false) {
        this.prescription.fillDate = moment(this.prescription.fillDate).toDate();

        this.dawCode = this.prescription.dawCode;
        this.originCode = this.prescription.originCode;
        this.statusCode = this.prescription.status;
        this.substatusCode = this.prescription.subStatus ?? this.onHoldSubstatusCode.WaitingOnMd;
        this.deliveryCode = this.prescription.deliveryCode;
        this.dispensedQuantity = this.prescription.dispensed;

        this.expiration = moment(this.prescription.expirationDate).toDate();
        this.writtenDate = moment(this.prescription.writtenDate).format();

    }

    assignViewValues() {
        this.prescription.dawCode = this.dawCode;
        this.prescription.originCode = this.originCode;
        this.prescription.status = this.statusCode;
        this.prescription.subStatus = this.statusCode == RefillStatusCode.OnHold ? this.substatusCode : null;
        this.prescription.deliveryCode = this.deliveryCode;
        this.prescription.expirationDate = this.expiration;
        this.prescription.writtenDate = this.writtenDate;
    }

    fetchAnnotations() {
        if (!this.prescription) return;

        const id = `${this.prescription.storeID}-${this.prescription.rxNumber}-${this.prescription.rfNumber}`;
        Axios.get(`/Prescription/${id}/Annotations`)
            .then(response => {
                this.hardcopyAnnotations = response.data;
            })
            .catch(err => {
                console.warn(err);
            });
    }

    clearQuery() {
        // when we clear we need to clear the imageID query if it exists.
        if (Object.keys(this.$route.query).length) {
            this.$router.replace({
                name: this.$route.name,
                query: {}
            } as Location);
        }
    }

    backToPatientDashboard() {
        if (this.routeRxId)
            this.$router.push({
                name: 'PatientDashboard',
                params: { id: (this.prescription?.patientID.toString() || '') }
            });
    }

}

