
    import {Component, Model, Prop, Vue, Watch, Mixins, PropSync} from 'vue-property-decorator';
    import {Prescription, RefillStatusCode} from '@/models/Prescription';
    import {CaseNote, CaseDetail} from '@/models/Case';
    import {PrepopulateProblemQuestions} from '@/util/PrepopulatedProblemQuestions';
    import {Table, TableColumn} from "element-ui";
    import SearchComponent from '@/components/SearchComponent.vue';
    import axios from 'axios';
    import swal from 'sweetalert2';
    import moment from "moment";
    import Checkbox from "@/components/Inputs/Checkbox.vue";

    @Component({
        name: "PrescriptionsListComponent",
        components: {
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            //Importing this way prevents component circular reference
            RxPage: () => import('@/pages/Prescription/PrescriptionDetailsPage.vue'),
            SearchComponent, Checkbox
        }
    })
    export default class PrescriptionsListComponent extends Vue {
        @Prop({default: null}) private prescriptions!: Array<Prescription>;
        @Prop({default: null}) private selected!: Array<Prescription>;
        @Prop({default: false, type: Boolean}) private hideActionsColumn!: boolean;
        @Prop({default: false, type: Boolean}) private colorCodePrescriptions!: boolean;
        @Prop({default: false, type: Boolean}) private disabled!: boolean;
        @Prop({default: false, type: Boolean}) private showReportProblemButton!: false;
        @Prop({default: false, type: Boolean}) private showProccessQueueButton!: false;
        @Prop({default: false, type: Boolean}) private showPatientName!: boolean;
        @Prop({default: true, type: Boolean}) private showSelectCheckox!: false;
        @Prop({default: true, type: Boolean}) private showVoidBox!: boolean;

        @Prop({default: "New Refills In Batch"}) private batchModalTitle!: boolean;
        @Prop({default: false, type: Boolean}) private refillBatchMode!: boolean;


        @Prop() private orderID!: number;
        @Prop() private actionButtons!: Array<string>;
        @Prop() private mode!: string;
        @Prop() private tableEmptyText!: string;
        @Prop() private additionalFields!: Array<any>;
        @Prop() private defaultSort!: string;
        @Prop() private defaultDesc!: boolean;

        private sortBy: string = "fillDate";
        private sortDesc: boolean = true;

        mounted() {
            if (this.defaultSort) {
                this.sortBy = this.defaultSort
            }

            if (this.defaultDesc != null && this.defaultDesc != undefined) {
                this.sortDesc = this.defaultDesc;
            }
        }

        private isSortable: boolean = true;
        private showVoidRxs: boolean = false;
        private rxForBatch: Array<Prescription> = [];
        private prescriptionsSelected: Prescription[] = [];
        private selectedProblem = null;
        private problemNote = "";
        private problems = [
            {value: null, text: 'Select A Problem'},
            {value: 'Dropped Order/RX Never Went Out', text: 'Dropped Order/RX Never Went Out'},
            {value: 'HSA Account Was Billed', text: 'HSA Account Was Billed'},
            {value: 'Incorrect Directions', text: 'Incorrect Directions'},
            {value: 'Incorrect Dosage Form', text: 'Incorrect Dosage Form'},
            {value: 'Incorrect Drug', text: 'Incorrect Drug'},
            {value: 'Incorrect Price Or Misquoted Price', text: 'Incorrect Price Or Misquoted Price'},
            {value: 'Incorrect Quantity', text: 'Incorrect Quantity'},
            {value: 'Incorrect Recipient', text: 'Incorrect Recipient'},
            {value: 'Incorrect Strength', text: 'Incorrect Strength'},
            {value: 'Package Is Missing', text: 'Package Is Missing'},
            {value: 'Package Sent To Wrong Address', text: 'Package Sent To Wrong Address'},
            {value: 'Package Was Damaged', text: 'Package Was Damaged'},
            {value: 'Package Delayed', text: 'Package Delayed'},
            {value: 'Refund Medication Copay', text: 'Refund Medication Copay'}
        ];

        private rxFields: any[] = [
            {key: "select", label: "", sortable: false},
            {key: "productNameShort", label: "Drug", sortable: true},
            {key: "patientName", label: "Patient", sortable: false, class: this.showPatientName ? "" : "d-none"},
            {key: "storeName", label: "Store", sortable: false},
            {key: "rxId", label: "Rx Id", sortable: false},
            {key: "dispensed", label: "Quantity", sortable: false, class: "text-center", },
            {key: "qtyLeft", label: "Quantity Left", sortable: false, class: "text-center", },
            {key: "fillDate", label: "Date", sortable: true, sortDirection: "desc", formatter: this.dateFormatter},
            {key: "patientPay", label: "Patient Pay", sortable: false, },
            {key: "programName", label: "Program", sortable: true},
            {key: "edit", label: "", },
        ];

        private incompleteAnswersFields: any[] = [
            { key: "patientName", label: "Patient", sortable: false },
            { key: "patientGender", label: "Gender", sortable: true, },
            { key: "rma", label: "RMA #", sortable: true },
            { key: "ipledge", label: "IPledge #" },
            { key: "dispenseBeforeDate", label: "Dispense before Date" },
            { key: "productNameShort", label: "Drug", sortable: true },
            { key: "storeName", label: "Store", sortable: false },
            { key: "rxWorkflowStatus", label: "Rx Status", sortable: true },
            { key: "rxId", label: "Rx Id", sortable: false },
            { key: "fillDate", label: "Fill Date", sortable: true, sortDirection: "desc", formatter: this.dateFormatter },
            { key: "patientPay", label: "Patient Pay", sortable: false, },
            { key: "edit", label: "", },
        ];

        created() {
            this.isSortable = this.mode != 'print';

            if (this.additionalFields?.length) {
                const editCol = this.rxFields.find(col => col.key == 'edit');
                this.rxFields = this.rxFields.filter(col => col.key != 'edit');
                this.rxFields = this.rxFields.concat(this.additionalFields);
                this.rxFields.push(editCol);
            }
        }

        DetermineFieldsByMode(mode: string) {
            if (mode == "IncompleteAnswers") {
                return this.incompleteAnswersFields;
            }
            else {
                return this.rxFields;
            }
        }

        get _tableEmptyText(): string {
            return this.tableEmptyText || "No Prescriptions to Show";
        }

        get actionSpecified() {
            return this.showAction('verify') || this.showAction('edit') || this.showAction('delete');
        }

        @Watch("sortDesc", {immediate: true})
        sortDirChange(val: any, oldVal: any) {
        }

        @Watch('prescriptions')
        changePrescriptions() {
            this.rxForBatch = [];
            this.prescriptionsSelected = [];
        }

        get prescritionsToShow(): Array<Prescription> {
            return this.prescriptions.map(rx => Object.assign(new Prescription(), rx)).filter(rx => rx.status != RefillStatusCode.Void && !this.showVoidRxs || this.showVoidRxs);
        }

        @Watch('selectedProblem')
        autoProblemQuestions() {
            this.problemNote = new PrepopulateProblemQuestions().prePopulateProblemQuestions(this.selectedProblem);
        }

        dateFormatter(value: Date, key: string, item: any) {
            return moment.utc(value).format("L");
        }

        showAction(action: string) {
            return this.actionButtons?.some(a => a == action);
        }

        remove(rxRow: Prescription) {
            this.$emit('prescriptionRemoved', rxRow);
        }

        getPrescriptionDetailsRoute(rxRow: Prescription) {
            return {
                name: 'PrescriptionDetails'
                , params: {
                    storeID: rxRow.storeID,
                    rxNumber: rxRow.rxNumber,
                    rfNumber: (rxRow.rfNumber || 0)
                }
            };
        }

        getPrescriptionVerificationRoute(rxRow: Prescription) {
            return {
                name: 'PV2'
                , params: {
                    orderID: this.orderID,
                    storeID: rxRow.storeID,
                    rxNumber: rxRow.rxNumber,
                    rfNumber: (rxRow.rfNumber || 0)
                }
            };
        }

        getVerifyActionButtonsClass(rxRow: Prescription) {
            return {'text-info': (rxRow.pV2ID || 0) == 0, 'text-success': (rxRow.pV2ID || 0) > 0, 'text-warning': (rxRow.dispenseErrorId || 0) > 0};
        }

        colorCodeTable(rxRow: Prescription, type: any) {
            if (!rxRow || type !== 'row' || !this.colorCodePrescriptions) return;
            const rx = new Prescription(rxRow);
            if (rx.status == RefillStatusCode.TransferOut)
                return 'table-info';
            else if (rx.status == RefillStatusCode.Void)
                return 'table-dark';
            else if (!rx.isRefillableStatus)
                return 'table-danger';
        }

        tooltipText(rxRow: Prescription) {
            let text = '';
            if ((rxRow.dispenseErrorId || 0) > 0)
                text = 'Error reported';
            else if ((rxRow.pV2ID || 0) > 0)
                text = 'Verified';
            else
                text = 'Verify';

            return text;
        }

        saveCase() {
            let newCaseNote: CaseNote | any = {
                id: 0,
                problem: this.selectedProblem,
                error: false,
                note: this.problemNote,
                resolvedBy: 'false',
                acceptedBy: 'false'
            };
            axios.post('/Case/', {
                caseProblem: newCaseNote,
                prescriptions: this.prescriptionsSelected
            })
                .then(response => {
                    swal.fire({
                        title: `Case Number`,
                        text: response.data.id,
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success btn-fill'
                    });
                })
                .catch(error => {
                    console.warn(error);
                });
        }

        @Watch('selected') buttonSelect() {
            this.prescriptionsSelected = this.selected;
        }

        @Watch('prescriptionsSelected') inputSelectedRxs(newSelectedRxs: Array<Prescription>) {
            this.$emit('selection-changed', newSelectedRxs);
        }

        isSamePrescriptionOrder(rx1: Prescription, rx2: Prescription) {
            return rx1.storeID == rx2.storeID && rx1.rxNumber == rx2.rxNumber &&
                rx1.rfNumber == rx2.rfNumber && rx1.orderId == rx2.orderId;
        }

        //Take only the max RfNumber from group of StoreId - RxNbr
        openBatch(rxForBatch: Array<Prescription>) {
            if (this.refillBatchMode) {
                if (!this.prescriptionsSelected?.length) return;

                const fillsForBatch = [] as Array<Prescription>;
                const sameStoreRx = (rx: any, rx2: any) => {
                    return rx.storeID == rx2.storeID && rx.rxNumber == rx2.rxNumber;
                };

                const findMaxRfInStoreRxGroup = (rx1: Prescription, rx2: Prescription): Prescription => {
                    return (rx1.rfNumber || 0) > (rx2.rfNumber ?? 0) ? rx1 : rx2;
                };

                //Find the distinct StoreId - RxNumber combinations in the selection
                const distinctStoreRxList = this.prescriptionsSelected.filter((rx, i, rxs) => {
                    const i2 = rxs.findIndex(rx2 => sameStoreRx(rx, rx2));
                    return i2 == i;
                }).map(rx => {return {storeID: rx.storeID, rxNumber: rx.rxNumber};});

                //Find the prescription with the max RfNumber for each distinct StoreId - RxNumber combination
                this.rxForBatch = distinctStoreRxList.map(ids => {
                    const group = this.prescritionsToShow.filter(rx => sameStoreRx(rx, ids));
                    return Object.assign(new Prescription(), group.reduce(findMaxRfInStoreRxGroup));
                });
            } else {
                let batch: Array<Prescription> = [];
                if (this.prescriptionsSelected?.length)
                    this.rxForBatch = this.prescriptionsSelected;
                else if (rxForBatch?.length)
                    this.rxForBatch = rxForBatch;
                else
                    this.rxForBatch = this.prescriptions;
            }

            this.$bvModal.show('rx-modal');
        }

        batchFinished() {
            this.$bvModal.hide('rx-modal');
            this.$emit('batchFinished');
        }

    }

    export class PrescriptionListColumn {
        public key?: string;
        public label?: string;
        public sortable?: boolean;
        public class?: string;
        public sortDirection?: string;
        public formatter?: Function;
    }

