import Vue from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
// app.js
import "bootstrap/dist/css/bootstrap.css";
import "./styles/app.less";
import "bootstrap-vue/dist/bootstrap-vue.css";
// @ts-ignore
import router from "./routes/router.js";
import App from "./App.vue";
import moment from "moment";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import Button from "@/components/Button.vue";
import DashboardPlugin from "./dashboard-plugin";
import Notifications from "@/components/NotificationPlugin";
import Axios from "axios";
// @ts-ignore
import VueAxios from "vue-axios";

import Amplify, * as AmplifyModules from "aws-amplify";
// @ts-ignore
import {AmplifyPlugin, AmplifyEventBus } from "aws-amplify-vue";
// @ts-ignore
import awsExports from "./aws-exports.ts";

Amplify.configure(awsExports);
AmplifyEventBus.$on("authState", (info:string)=> {
    if (info == "signedIn") {
        if (router.currentRoute.query.redirect){
            router.push(router.currentRoute.query.redirect);
        } else {
            router.push("/");
        }
    }
    console.log(`Here is the auth event that was just emitted by an Amplify component: ${info}`);
});

Axios.defaults.baseURL = "https://toptier-brand.scrx.io/api";
// Axios.defaults.baseURL = "https://localhost:44305/api";
Axios.interceptors.request.use(async config => {
    const jwtToken = await Amplify.Auth.currentSession().then((data:any) => {
        Vue.prototype.$jwtToken = data.idToken.jwtToken;
        Axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$jwtToken}`;

        const payload = data.accessToken.payload;
        const userProgram = payload["cognito:groups"][0];
        Vue.prototype.$userProgram = userProgram;

        return data.idToken.jwtToken;
    });
    config.headers.common['Authorization'] = `Bearer ${jwtToken}`;
    return config;
});

// noinspection JSUnusedGlobalSymbols
declare module 'vue/types/vue' {
    interface Vue {
        $userProgram:string
    }
}
Vue.prototype.$userProgram = "";
Vue.config.productionTip = true;

Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueAxios, Axios);
Vue.use(DashboardPlugin);
Vue.use(Notifications);
Vue.use(vueNumeralFilterInstaller, { locale: "en" });

Vue.component("VSelect", vSelect);
Vue.component(Button.name, Button);

// Custom filters, this should be better to be declared in a separate folder if we use more of this custom filters
Vue.filter("formatDate",
    (value: Date, format: string, setLocal = true) => {
        if (value && format) {
            let momentDate = moment.utc(value);
            momentDate = setLocal ? momentDate.local() : momentDate;
            return momentDate.format(format);
        } else return "";
    }
);

new Vue({
    render: h => h(App),
    router,
}).$mount("#app");
