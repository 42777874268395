import { Person } from './Person'

export interface Vendor extends Person {
    name: string;

    emailAddress: string;
    phoneNumber: string;
    faxNumber: string;

    note: string;
}

export class Vendor extends Person {
    toString(): string {
        if(this.id && this.name)
            return `ID: ${this.id} ${this.name}`;
        else return '';
    }
    
}
