import { render, staticRenderFns } from "./DoctorDashboard.vue?vue&type=template&id=6b173778&scoped=true"
import script from "./DoctorDashboard.vue?vue&type=script&lang=ts"
export * from "./DoctorDashboard.vue?vue&type=script&lang=ts"
import style0 from "./DoctorDashboard.vue?vue&type=style&index=0&id=6b173778&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b173778",
  null
  
)

export default component.exports