import HasID from './HasID'
import HasNames from './HasNames'
import HasAddress from './HasAddress'
import TrackedObject from './TrackedObject'
import HasLabel from './HasLabel';

export interface Person extends HasID, HasNames, HasAddress, TrackedObject {}

export class Person extends HasLabel {
    toString(): string {
        let label = "";
        if(this.id && this.firstName && this.lastName){
            label = "ID: " + this.id + " " + this.displayNamesForPerson() + " Address: " + this.address1;
        }
        return label;
    }

    displayNamesForPerson(): string {
        if (!(this && this.id) && !(this && this.lastName)) return "";
        return Person.concatName(this.firstName, this.lastName, this.middleName);
    }

    static concatName(firstName?:string, lastName?:string, middleName?:string):string{
        let compiledName = "";
        compiledName += lastName
            ? lastName
            : "!! Unknown Lastname !!";
        compiledName += firstName ? ", " + firstName : "";
        compiledName += middleName ? " " + middleName : "";
        return compiledName;
    }

    updateNamesForPersonFromDisplayName(displayName: string): void {
        let names : Array<string> = [];
        if (displayName.includes(",")) {
            names = displayName.replace(",", "").split(" ");
            this.lastName = names[0];
            this.firstName = names[1];    
            this.middleName = names[2];
        } else {
            names = displayName.split(" ");
            if (names.length == 1) {
                this.lastName = names[0];
                return;
            }
            this.firstName = names[0];
            this.lastName = names[names.length - 1];
            if (names.length > 2) this.middleName = names[1];
        }
    }

    get fullAddress():string {
        let fullAddress = this.address1 
            + (this.address2 ? ', ' + this.address2 : '') 
            + (this.addressCity ? ', '+this.addressCity : '');

        const addrStateZip = this.addressState + ' ' + this.addressZip;
        fullAddress += addrStateZip.replace(' ','') ? (', ' + addrStateZip) : '';

        return fullAddress;
    }
}
