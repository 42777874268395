
    import Vue from 'vue';
    import axios from 'axios';
    import {Component, Prop, Mixins, Watch} from 'vue-property-decorator';
    import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';

    import SearchComponent from '@/components/SearchComponent.vue';
    import EnumSelector from '@/components/EnumSelector.vue';
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';

    import {Plan, BillingFlags} from '@/models/Plan';
    import {NotificationOptions} from "@/util/NotificationOptionsPresets";

    @Component({
        name: 'PlanDetailsPage',
        components: {SearchComponent, Checkbox, NewClearSaveButtons, EnumSelector},
    })
    export default class PlanDetailsPage extends Mixins(DisableAutocompleteMixin) {
        @Prop({
            default: () => {
                return {
                    save: "Save Changes",
                    new: "New Plan",
                    clear: "Clear",
                };
            }
        }) private buttonTitles!: any;
        @Prop({default: false}) private createNewPlan!: any;

        private plan: Plan = new Plan();
        private providerTypeEnums = Plan.ProviderType;
        private searchValue = "";
        private displayName = "";
        private newMode = false;
        private isOffline: boolean = false;        
        private flagOptions = Plan.flagOptions;

        created() {
            if (this.createNewPlan)
                this.createPlan();
        }

        get disableFields(): boolean | null {
            return (this.plan?.id || 0) == 0 && !this.newMode;
        }

        get isFormCompleted() {
            const b1 = !this.disableFields && !!(this.plan.name && this.plan.bin);
            const validOfflineCopay = this.isOffline && (this.plan.offlineCopay || this.plan.offlineCopay == 0)
                || !this.isOffline && !this.plan.offlineCopay;

            return b1 && validOfflineCopay;
        }

        clearConfirmation() {
            this.clearForm();
        }

        createPlan() {
            this.newMode = true;
            this.isOffline = false;
            this.plan = new Plan();
        }

        clearForm() {
            this.newMode = false;
            this.isOffline = false;
            this.plan = new Plan();
        }

        savePlan() {
            this.plan.offlineCopay = this.isOffline ? (this.plan.offlineCopay || 0) : null;
            axios.post('/Plan', this.plan)
                .then(response => {
                    this.plan = Object.assign(new Plan(), response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Plan"));
                    if (this.createNewPlan) {
                        this.$emit("created", this.plan);
                        this.plan = new Plan();
                    }
                })
                .catch(error => {
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Plan"));
                });
        }

        onPlanChanged(value: Plan) {
            this.plan = value;
            this.isOffline = this.plan.offlineCopay != null;
        }

    } // close export of class
