
    import {Component, Prop, Vue, Ref} from 'vue-property-decorator';
    import {Order, OrderStatus} from '@/models/Order';
    import {BvTableFieldArray, BTable} from "bootstrap-vue";
    import moment from "moment";

    @Component({
        name: "OrdersListComponent",
        components: {},
    })
    export default class OrdersListComponent extends Vue {
        @Prop({default: {} as Array<Order>}) private orders!: Order[];
        @Prop({default: false, type: Boolean}) private hideActionsColumn!: boolean;
        @Prop({default: false, type: Boolean}) private expandable!: boolean;
        @Prop() private mode!: string;

        @Ref("order-list-table") private readonly table!: BTable;

        get items(): any[] {
            return this.orders.map((o: any) => {
                if (o.status == OrderStatus.Canceled) o._rowVariant = "danger";
                else if (o.status == OrderStatus.Shipped) o._rowVariant = "info";

                //console.log(o.prescriptions);

                return o;
            });
        }

        get fields(): BvTableFieldArray {
            const fields = [];
            fields.push({key: "id", label: "Order Id", });
            if (this.mode === "verify") {
                fields.push({key: "patientName", label: "Patient", class: "patient-col", sortable: true});
            }
            fields.push({ key: "created", label: "Date", formatter: (d: any) => moment.utc(d).format("L"), sortable: true, });
            fields.push({ key: "createdBy", label: "Created By", formatter: (c: any) => c.split("@")[0] });
            fields.push({key: "rxRfs", label: "Rx-Refill", });
            fields.push({key: "storeName", label: "Store", });
            fields.push({key: "status", formatter: this.orderStatusName});
            fields.push({key: "action", label: "", class: "action-col", });
            return fields;
        }

        orderStatusName(enumNbr: number): string {
            return OrderStatus[enumNbr];
        }

        rowClicked(item: any, index: number, event: Event) {
            console.log("event: ", event);
            if (this.mode == 'verify') {
                this.$router.push({ name: 'OrderVerification', params: { orderId: item.id }})
            }
            if (!this.expandable) return;
            this.$set(item, "_showDetails", !item._showDetails);
            console.log("item: ", item);

        }
    }

