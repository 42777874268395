import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class DisableAutocompleteMixin extends Vue {
    mounted(): void {
        this.disableAutocomplete();
    }
    updated(): void {
        this.disableAutocomplete();
    }

    disableAutocomplete(): void{
        this.$el.querySelectorAll('.form-control').forEach(item=>{
            item.setAttribute('autocomplete','nope');
        })
        this.$el.querySelectorAll('form').forEach(item=>{
            item.setAttribute('autocomplete','nope');
        })
    }

}
