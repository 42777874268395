import TrackedObject from "./TrackedObject";
import { Prescription } from "./Prescription";
import { Prescriber } from "./Prescriber";
import { Patient } from "./Patient";

export interface PriorAuthorization extends TrackedObject {
    id: number;
    coverMyMedsId: string;
    prescription: Prescription;
    prescriber: Prescriber;
    patient: Patient;
    drugManufacturer: string;
    status: PriorAuthorizationStatusCode;
    completeReason: string;
    responses: CoverMyMedsResponse[];
    latestCallback: CoverMyMedsCallback;
}

export enum PriorAuthorizationStatusCode {
    Initiated,
    SentToPrescriber,
    Abandoned,
    SubmittedToInsurance,
    Approved,
    Denied,
    Cancelled,
}

// ReSharper disable InconsistentNaming
export interface CoverMyMedsCallback {
    event_key: string;
    message: string;
    pharmacy_npi: string;
    rx_number: string;
    error: string;
}
// ReSharper restore InconsistentNaming

export interface CoverMyMedsResponse {
    actions: any;
    message: string;
    primaryAction: CoverMyMedsAction;
    requiredAction: CoverMyMedsAction;
    status: string;
    error: string;
}

export interface CoverMyMedsAction {
    buttonLabel: string;
    description: string;
    url: string;
}
