import { DrugStatus } from "@/models/Drug/Drug";
import HasLabel from "@/models/HasLabel";

export enum DrugSource {
    GsddFederal = 0,
    NonGsddProduct = 1,
    CompoundProduct = 2,
}

export interface DrugProduct {
    source: DrugSource;
    drugId: number;
    ndc: string;
    packageSize: number;
    productNameShort: string;
    activeStatus: DrugStatus.DrugStatusEnum;
    licenseType: string;
}

export abstract class DrugProduct extends HasLabel {
    constructor(obj?: DrugProduct) {
        super();
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
