import { PackagePrice, ProductImageType } from "@/models/GSDD";
import { DrugCategory } from "@/models/DrugCategory";
import { VendorDrugMap } from "@/models/VendorDrugMap";
import { DeliveryCode } from "@/models/DeliveryCode";
import HasLabel from "@/models/HasLabel";
import TextUtils from "@/util/TextUtils";
import { Program } from "@/models/Program";
import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";

export interface DrugStatus {
    packageID: number;
    status: DrugStatus.DrugStatusEnum;
    disableAutorefill: boolean;
    autorefillMaxLength: number | null;
    updated?: Date;
    updatedBy?: string;
}

export class DrugStatus {
    constructor(obj?: DrugStatus) {
        this.status = 0;
        this.disableAutorefill = false;
        this.autorefillMaxLength = null;

        if (obj) Object.assign(this, obj);
    }
}

export namespace DrugStatus {
    export enum DrugStatusEnum {
        Inactive,
        Active,
        ActiveAndPreferred,
        BlockedFromFills,
    }
}

export interface Drug extends DrugProduct {
    packageID?: number | null;
    productID: string;
    billingUnit: string;
    productNameLong: string;
    marketer: string;
    doseForm: string;
    deaClass: string;
    brandGenericStatus: string;
    isOffMarket: boolean;
    isReplaced: boolean;
    autosend: boolean;
    packageDescription: string;
    therapeuticEquivalenceCode: string[];
    routeOfAdministration: string | null;
    activeStatus: DrugStatus.DrugStatusEnum;

    productDetail: any; // GSDD DetailProductType
    packageDetail: any; // GSDD DetailPackageType
    prices: PackagePrice[]; // GSDD PriceDataType
    images: ProductImageType[];

    notes: DrugNote[];
    status: DrugStatus;

    categories?: DrugCategory[];
    vendors?: VendorDrugMap[];
}

export class Drug extends DrugProduct {
    constructor(packageId?: number | null, obj?: Drug | DrugProduct) {
        super();
        this.packageID = packageId;

        if (packageId != null) this.drugId = Number(packageId);

        this.status = new DrugStatus();
        this.source = DrugSource.GsddFederal;

        if (obj) {
            Object.assign(this, obj);
            this.packageID = obj.drugId;
            if (obj instanceof Drug && obj.status) this.status = new DrugStatus(obj.status);

            if (this.source != DrugSource.GsddFederal) {
                this.status.status = Number(obj.activeStatus);
                this.status.disableAutorefill = true;
                this.status.packageID = Number(this.drugId);
            }
        }
    }

    toString(): string {
        const productName = this.productNameLong || this.productNameShort;
        let display = '';
        let preferred = '';
        if (this.activeStatus == DrugStatus.DrugStatusEnum.ActiveAndPreferred) {
            preferred = '\u2B50 ';
        }
        if (productName) {
            display = productName;
            if (this.ndc) {
                display = '';
                if (this.source == DrugSource.NonGsddProduct) display = '(*) ';

                display += `${preferred}NDC:${this.ndc} - ${productName} ${this.packageSize ? ("- PS: " + this.packageSize) : ""}`;
            }
        }
        return display;
    }

    get isCII(): boolean {
        return this.deaClass == "schedule II";
    }

    get isControl(): boolean {
        if (this.deaClass) {
            return this.deaClass.startsWith("schedule");
        } else {
            return false;
        }
    }

    get NDCFormat542(): string {
        return Drug.formatNDC542(this.ndc);
    }

    static formatNDC542(value: string | null): string {
        if (!value) return '';
        return `${value.substr(0, 5)}-${value.substr(5, 4)}-${value.substr(9)}`;
    }
}

export namespace Drug {
    export class EquivalentDrug {
        packageID?: number;
        productID?: number;
        ndc?: string;
        brandGenericStatus?: string;
        productNameLong?: string;
        marketer?: string;
        packageSize?: number;
    }
}

export interface DrugNote {
    packageID: number;
    note: string;
    created: Date;
    createdBy: string;
}

export interface DrugProgram {
    packageID: number;
    programID: number;
    deliveryCode: DeliveryCode | null;
    autoselect: boolean;
    awaitsPriorAuth: boolean;
    disableAutorefill: boolean;
    program: Program;

    created: Date;
    createdBy: string;

    drugName: string
    ndc: string
    autoselectProgram: Program | null
}

export class DrugProgram extends HasLabel {
    constructor(obj?: DrugProgram) {
        super();
        this.deliveryCode = null;
        this.autoselect = false;

        if (obj) {
            Object.assign(this, obj);
            this.program = new Program(obj.program);
            this.autoselectProgram = new Program(obj.autoselectProgram || undefined);
        }
    }

    get deliveryTypeDesc(): string {
        if (this.deliveryCode == null) return '';

        const errorDesc = DeliveryCode[(this.deliveryCode)];
        return TextUtils.camelCaseToNormal(errorDesc);
    }

    set drug(val: Drug) {
        this.packageID = Number(val.packageID);
        this.drugName = val.productNameShort;
        this.ndc = val.ndc
    }

    toString(): string {
        return `${this.ndc} - ${this.drugName} - ${this.deliveryTypeDesc || 'No delivery method'}`
    }
}

