import moment from "moment";
import HasLabel from "./HasLabel";
import { Order, OrderStatus } from "./Order";
import TrackedObject from "./TrackedObject";
import TextUtils from "@/util/TextUtils";
import { Patient } from "@/models/Patient";
import { Program } from "@/models/Program";
import { Claim } from "./Claim";
import { Prescriber } from "@/models/Prescriber";

export interface Prescription extends TrackedObject {
    storeID: number;
    rxNumber: number;
    rfNumber?: number;
    imageID?: number;
    escriptID?: number;
    programTransferId?: number;
    directions: string;
    daySupply: number;
    quantity: number;
    dispensed: number;
    refills: number;
    originCode: OriginCode;
    dawCode: DAWCode;
    status: RefillStatusCode;
    subStatus: OnHoldSubstatusCode | null;
    memo: string;
    programName: string;
    storeName: string;
    lotNumber: string;
    drugExpirationDate: Date;
    totalPrice: number;
    cost: number;
    patientPay: number;
    tax: number;
    deliveryCode: DeliveryCode;
    deliveryOption: string;
    packageID: number;
    drugSource: number;
    patientID: number;
    prescriberID: number;
    programID?: number;
    orderId: number;
    originalRxDate: Date;
    fillDate: Date;
    writtenDate: Date;
    expirationDate: Date;
    drugsFilledDate: Date;
    annotations: string;
    productNameShort: string;
    pV1ID: number;
    pV2ID: number | null;
    pv1: Date;
    pv1By: string;
    pv2: Date;
    pv2By: string;
    dispenseErrorId?: number;
    canBeFilled: boolean;
    totalDispensed: number;
    totalAllowedToDispense: number;
    priceScheduleID?: number;
    createdBy: string;
    isAvailableForAutorefill: boolean;

    orders: Array<Order>;
    patient: Patient;
    prescriber: Prescriber;
    claims: Array<Claim>;
    program: Program;
}

export interface TransferTo {
    name: string;
    address: string;
    city: string;
    state: string;
    zip: number;
    phone: string;
    fax: string;
    dea: string;
    pharmacist: string;
    verbalTransfer: boolean;
}

export enum DeliveryCode {
    Standard,
    Overnight,
    WillCall,
    TwoDay
}

export enum OriginCode {
    NotSpecified,
    Written,
    Phone,
    Escript,
    Fax,
    Transfer,
    Custom
}

export enum DAWCode {
    NotSpecified,
    Prescriber,
    Patient,
    Pharmacy,
    GenericNotStocked,
    DispenseAsGeneric,
    Override,
    SubstitutionNotAllowed,
    SubstitutionOkNoGeneric,
    Other
}

export enum RefillStatusCode {
    Refillable,
    AutoRefillable,
    NonRefillable,
    Suspended,
    TransferOut,
    TransferIn,
    OnHold,
    Canceled,
    Expired,
    Void
}

export enum OnHoldSubstatusCode {
    WaitingOnMd,
    InventoryShort,
    WaitingOnInsurance,
    ReturnToStock,
    GovernmentFunded,
    NoCommercialInsurance,
    NotInNetwork,
    PtRefused,
    NoPtContact,
    InvalidPhoneNumber,
    MdGaveSamples,
    WaitingOnCoupon, // (for horizon? )
    CouponMaxReached,
    DuplicatedPrescription,
    RxDenied // same as being Void
}

export class Prescription extends HasLabel {
    constructor(obj?: Prescription) {
        super();
        this.fillDate = moment.utc().toDate();
        this.status = RefillStatusCode.Refillable;
        this.deliveryCode = DeliveryCode.Standard;
        this.patientID = 0;
        this.storeID = 0;
        this.packageID = 0;
        this.prescriberID = 0;
        this.isAvailableForAutorefill = false;
        this.programID = 0;
        this.program = new Program();

        if (obj) {
            Object.assign(this, obj);
            this.program = new Program(obj.program);
            if (obj.orders) {
                this.orders = obj.orders.map(o => new Order(o));
            }
        }
    }

    toString(): string {
        if (this.rxNumber)
            return `Store-Rx-Rf:${this.rxID}, patientID:${this.patientID}`;
        else return '';
    }

    get rxID(): string {
        return Prescription.createRxId(this.storeID, this.rxNumber, this.rfNumber);
    }

    get isActive(): boolean {
        return !(this.status == RefillStatusCode.TransferOut || this.isVoid);
    }

    get isVoid(): boolean {
        return this.status == RefillStatusCode.Void || (this.status == RefillStatusCode.OnHold && this.subStatus == OnHoldSubstatusCode.RxDenied);
    }

    /**
     * Indicates if the program allows the prescription to refill even if previous refill has not been shipped.
     */
    get programAllowsRefillsIfUnshipped(): boolean {
        if (!this.program?.flags.length) return false;

        return this.program.flags.some(f => f.value && f.name == "Allow Refills if Unshipped");
    }

    get isRefillable(): boolean {
        //const hasBeenPV2 = !!this.pV2ID;

        return this.canBeFilled && this.isRefillableStatus;// && ((this.hasBeenShipped && hasBeenPV2) || this.fillDate < moment('04/01/2021').toDate());
    }

    get isRefillableStatus(): boolean {
        return (this.status == RefillStatusCode.Refillable || this.status == RefillStatusCode.AutoRefillable
            || this.status == RefillStatusCode.TransferIn) && !this.isExpired;
    }

    get isExpired(): boolean {
        const expDate = moment.utc(this.expirationDate).toDate();
        const isExpired = expDate.getTime() < new Date().getTime();
        return isExpired;
    }

    get hasBeenShipped(): boolean {
        return this.orders?.some(o => o.status == OrderStatus.Shipped);
    }

    get hasFullId(): boolean {
        return !!(this.storeID && this.rxNumber && (this.rfNumber || this.rfNumber == 0));
    }

    get statusName(): string {
        if (this.isExpired) return "Expired";
        return TextUtils.camelCaseToNormal(RefillStatusCode[this.status]);
    }

    get isCashedOut(): boolean {
        return !!(this.priceScheduleID);
    }

    get isRx30BeforeApril(): boolean {
        const myMoment = moment;
        return this.status != RefillStatusCode.OnHold && myMoment(this.fillDate).isSameOrBefore(myMoment("2021-04-01")) && this.createdBy == "Rx30";
    }

    public static createRxId(storeID: number, rxNumber: number, rfNumber: number | undefined): string {
        return `${storeID || ''}-${rxNumber || ''}-${rfNumber == undefined ? '' : (rfNumber || 0)}`;
    }

    get remainingQuantity(): number {
        if (!this.rxNumber || this.rfNumber == 0) return (this.quantity * ((this.refills || 0) + 1));

        const res = this.totalAllowedToDispense - (this.totalDispensed || 0);
        return res;
    }

    get trackingNumber(): string {
        let orders = this.orders;
        let tracking = "";

        if (orders) {
            orders = orders.filter(o => o && o.status == OrderStatus.Shipped && o.trackingNumber.length > 0);
            if (orders.length > 0) {
                const order = orders.reduce((prev, current) => (prev && prev.updated > current.updated) ? prev : current);
                tracking = order.trackingNumber;
            }
        }
        return tracking;
    }

    get shipDate(): string {
        let orders = this.orders;
        let shipDate = "";

        if (orders) {
            orders = orders.filter(o => o && o.status == OrderStatus.Shipped && o.shipDate.length > 0);
            if (orders.length > 0) {
                const order = orders.reduce((prev, current) => (prev && prev.updated > current.updated) ? prev : current);
                shipDate = order.shipDate;
            }
        }
        return shipDate;
    }
}
