<template>
  <div class="panel-header">
    <div class="header text-center">
      <h2 class="title">
        FullCalendar.io
      </h2>
      <p class="category">
        Handcrafted by our friends from
        <a
          target="_blank"
          href="https://fullcalendar.io/"
        >FullCalendar.io</a>. Please checkout their
        <a
          href="https://fullcalendar.io/docs/"
          target="_blank"
        >full documentation.</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
