
    import { Component, Prop, Vue, VModel, Emit } from "vue-property-decorator";
    import { Patient } from "@/models/Patient";
    import Axios from 'axios';
    import { PatientPlan } from '@/models/PatientPlan';
    import { Plan } from '@/models/Plan';
    import { CombinedPlan } from '@/models/CombinedPlan';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';

    @Component({
        name: "EligibilityComponent"
    })
    export default class EligibilityComponent extends Vue {
        @VModel({ default: null }) private patient!: Patient;
        @Prop({ type: Boolean, default: false }) private disabled!: boolean;
        private cardholderId: string = "";
        private combinedPlans: CombinedPlan[] = [];

        private combinedFields: any[] = [
            { key: "plan.bin", label: "BIN", },
            { key: "plan.pcn", label: "PCN", },
            { key: "patientPlan.memberId", label: "Member Id", },
            { key: "patientPlan.groupId", label: "Group Id", },
            { key: "patientPlan.cardholderId", label: "Cardholder Id", },
            { key: "patientPlan.relationshipCode", label: "Relationship Code", },
        ];

        @Emit("eligibilityCheck")
        eligibilityCheck() {
            return this.sendRequest;
        }

        sendRequest() {
            const url = `Insurance/Eligibility/${this.patient.id}`;
            const cardholderId = this.cardholderId;
            Axios.get(url, { params: { cardholderId: cardholderId } })
                .then(response => {
                    const plans: Plan[] = response.data.plans;
                    const patientPlans: PatientPlan[] = response.data.patientPlans;
                    this.combinedPlans = plans.map((item, i) => {
                        const comb = { plan: new Plan(item), patientPlan: new PatientPlan(patientPlans[i]) } as CombinedPlan;
                        return new CombinedPlan(comb);
                    });
                }).catch(err => {
                    const message = err.response?.data ?? err;
                    this.$notification(NotificationOptions.notificationOptionsPreset(message, NotificationOptions.NotificationTypes.danger));
                });
        }
    }
