

    import {Component, Watch, Mixins, Prop} from 'vue-property-decorator'
    import {LockHandler, Lock} from '@/mixins/LockHandler';
    import {Prescription, DeliveryCode} from '@/models/Prescription';
    import {Printer} from '@/models/Printer'
    import {Store} from '@/models/Store';
    import {Order, OrderOTCItem, OrderStatus} from '@/models/Order';
    import {Payment, CustomerInfo, StripeCard} from '@/models/Payment';
    import {Patient} from '@/models/Patient';
    import {OTCItem} from '@/models/OTCItem';
    import {DatePicker, Table, TableColumn} from "element-ui";
    import EnumSelector from '@/components/EnumSelector.vue';
    import axios, {AxiosError} from 'axios';
    import moment from "moment";
    import SearchComponent from '@/components/SearchComponent.vue';
    import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
    import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
    import {NotificationOptions} from "@/util/NotificationOptionsPresets"
    import StripeVueCard from '@/components/Payments/StripeVueCardcomponent.vue';
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import {Program} from '@/models/Program';

    @Component({
        components: {
            SearchComponent,
            NewClearSaveButtons,
            [DatePicker.name]: DatePicker,
            PrescriptionsListComponent,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            StripeVueCard,
            EnumSelector,
            Checkbox
        }
    })
    export default class OrderDetailsPage extends Mixins(LockHandler) {
        private order: Order = new Order();
        private orderLock: Lock | null = null;
        private patient: Patient = new Patient();
        private selectedStore: Store = new Store();
        private orderStatus = OrderStatus;
        private paymentDateFilter: Date = new Date();
        private availableToOrder: Prescription[] = [];
        private deliveryCodes = DeliveryCode;

        private dataPrescriptions: Array<Prescription> = [];
        private dataOrderItems: Array<OrderOTCItem> = [];

        private payments: Array<Payment> = [];
        private selectedPayments: Array<Payment> = [];

        private customerInfo: CustomerInfo = {} as CustomerInfo;
        private stripeCardList: Array<StripeCard> = [];
        private selectedStripeCards: Array<StripeCard> = [];
        private prescriptionListActionButtons: Array<string> = [];

        private shippingOptions: Array<string> = [ "HIPAADocsInBox" ];
        private printers: Array<Printer> = [];
        private selectedPrinter: Printer = {} as Printer;

        private collectedByParter = false;
        private routeMode : string = "edit";
        private disableFields : boolean = true;

        private selectedProgram: Program = new Program();
        private isProgramWithCopayCollectionFlag: boolean = false;

        private availableRxFields = [
            {key: 'productNameShort', label: 'Drug', },
            {key: "rxId", label: "Rx"},
            {key: "fillDate", formatter: (d: Date) => moment(d).format("L")},
            {key: 'patientPay', label: 'Copay', formatter: (copay: any) => `$${copay == null ? '0.00' : copay.toFixed(2)}`},
            {key: "verifyButton", label: ''}
        ];

        private buttonTitles: any = {
            new: "",
            cancel: "Back to Dashboard"
        };

        created() {
            if (this.routeOrderId) {
                this.loadOrder();
            } 
        }
        
        loadOrder() {
            axios.get(`/Order/${this.routeOrderId}`)
                .then(response => {
                    this.order = Object.assign(new Order(), response.data);
                    this.dataOrderItems = this.order.items;
                    this.selectedStore = new Store();
                    this.selectedStore.id = this.order.storeID;
                    this.selectedStore.name = this.order.storeName;

                    this.dataPrescriptions = this.order?.prescriptions || new Array<Prescription>();

                    this.selectedPayments = this.order.payments;
                    const chargeIdList = this.selectedPayments.map(payment => payment.chargeId);

                    if (chargeIdList?.length) {
                        axios.get(`/order/charges/${this.routeOrderId}`)
                            .then(response => {
                                this.selectedStripeCards = response.data;
                                this.updateStripeCardList();
                            })
                            .catch(error => {
                                let errorMsg = "Error while loading payments.";
                                this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.danger));
                                console.error(errorMsg, {error, response: error?.response})
                            });
                    }

                    this.loadPatient();
                    this.loadProgram();
                })
                .catch(error => {
                    let errorMsg = "Error while loading order";
                    console.error(errorMsg, {error, response: error?.response})
                    this.$notification(NotificationOptions.errorSaveNotificationPreset(errorMsg));
                })
        }
        
        updateStripeCardList() {
            for (let i = 0; i < this.selectedStripeCards.length; i++) {
                let selectedCardIndex = this.stripeCardList.findIndex(item => item.id == this.selectedStripeCards[i].id);
                if (selectedCardIndex >= 0) {
                    this.stripeCardList[selectedCardIndex].amount = this.selectedStripeCards[i].amount;

                }
            }
        }

        get isOrderCancelled(): boolean {
            const isOrderCancelled = this.order?.status == this.orderStatus.Canceled;
            return isOrderCancelled;
        }

        initObject() {
            this.order.patientID = this.patientId;
            this.order.prescriptions = [];
            this.order.items = [];
        }
        
        loadPatient() {
            if (this.patientId) {
                axios.get(`/Patient/${this.patientId}`)
                    .then(response => {

                        this.patient = Object.assign(new Patient(), response.data);
                    })
                    .catch(error => {
                        console.log("Error while loading the Patient", {error, response: error?.response})
                    });
            }
        }

        get rxTable(): any & {selectRow: (index: any) => void} {
            return this.$refs.rxTable as any & {selectRow: (index: any) => void};
        }

        loadProgram() {
            axios.get<Program>(`/Program/`)
                .then(res => {
                    if(res.data?.id){
                        this.selectedProgram = Object.assign(new Program(), res.data);
                        this.isProgramWithCopayCollectionFlag = !!this.selectedProgram?.flags?.some(f => f.name.includes("Copay Collection") && f.value)
                        console.log("Program", this.selectedProgram);
                    } else {
                        console.error("Error while pulling program flags.", res.data);
                    }
                })
                .catch(err => {
                    console.error("Error while pulling program flags", {err, response: err?.response});
                })
        }

        loadPatientPayments() {
            let filterDateStr = moment(this.paymentDateFilter).format('DD-MM-YYYY');
            axios.get(`/Patient/${this.patientId}/payments/${filterDateStr}`)
                .then(response => {
                    this.payments = response.data.filter((p: Payment) => !p.orderId || p.orderId == this.order.id);
                })
                .catch(error => {
                    console.log("Error while loading Payments", {error, response: error?.response})
                });
        }

        get AddonTotalSum(): string {
            let total = 0.00;
            total = this.dataOrderItems.map(item => {
                const price = item.price;
                const qty = item.quantity;
                const tax = item.tax ? +item.tax : 0;
                return price * qty + tax;
            }).reduce((itemTotal, grandTotal) => {
                return itemTotal + grandTotal;
            }, 0);
            return total.toFixed(2);
        }

        backToPreviousPage() {
            let patientId = this.patientId;
            this.$router.push({name: 'PatientDashboard', params: {id: patientId.toString()}});
        }

        get routeOrderId(): number {
            let res = 0;
            res = parseInt(this.$route.params.orderId);
            if (isNaN(res))
                return 0;
            return res
        }

        get patientId(): number {
            let res = 0;
            res = parseInt(this.$route.params.patientId);
            if (isNaN(res))
                return this.order.patientID || 0;
            return res
        }

        get totalSelected() {
            let total = 0;
            for (let i = 0; i < this.selectedStripeCards.length; i++) {
                total += this.selectedStripeCards[i].amount;
            }
            return total;
        }

        get totalSelectedPreview() {
            let total = 0;
            for (let i = 0; i < this.stripeCardList.length; i++) {
                total += this.stripeCardList[i].amount;
            }
            return total;
        }

        get amountOwed() {
            let balance = 0.00;
            for (let i = 0; i < this.dataPrescriptions.length; i++) {
                balance += this.dataPrescriptions[i].patientPay;
            }

            let addonTotal = this.dataOrderItems.map(item => {
                const price = item.price;
                const qty = item.quantity;
                const tax = item.tax ? +item.tax : 0;
                return price * qty + tax;
            }).reduce((itemTotal, grandTotal) => {
                return itemTotal + grandTotal;
            }, 0);
            balance = balance + addonTotal
            
            return Number(balance.toFixed(2));
        }

    }
