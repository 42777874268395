import HasID from "@/models/HasID";
import { PatientPlan, RelationshipCode } from "@/models/PatientPlan"
import { Plan } from "@/models/Plan"
import TrackedObject from "./TrackedObject";

export interface InsuranceUpdateRequest extends HasID, TrackedObject {
    programID        : number
    patientID        : number
    planName         : string
    bin              : string
    pcn              : string
    providerType     : Plan.ProviderType
    groupId          : string
    cardholderId     : string
    personCode       : string
    relationshipCode : RelationshipCode
    comments         : string
    processed        : Date
    processedBy      : string
}

export class InsuranceUpdateRequest {
    
    constructor(obj?:InsuranceUpdateRequest){
        this.providerType = Plan.ProviderType.Unknown;
        this.relationshipCode = RelationshipCode.NotSpecified;

        if(obj) Object.assign(this, obj);
    }

    get providerTypeDesc():string {
        return Plan.ProviderTypeToString(this.providerType);
    }
    get relationshipDesc():string {
        return PatientPlan.RelationshipToString(this.relationshipCode);
    }
}

