
    import Component from "vue-class-component";
    import {Vue, VModel} from "vue-property-decorator";
    import {PatientPlan, RelationshipCode} from "@/models/PatientPlan";
    import EnumSelector from "@/components/EnumSelector.vue";

    @Component({
        components: {
            EnumSelector,
        }
    })
    export default class PatientPlanEditComponent extends Vue {
        @VModel() private patientPlan!: PatientPlan;
        readonly relationshipCodes = RelationshipCode;

    }
