import { VendorDrugMap } from "@/models/VendorDrugMap";
import { DrugStatus } from "@/models/Drug/Drug";
import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";
import DrugStatusEnum = DrugStatus.DrugStatusEnum;

export interface NonGSDDPatEd {
    id: number;
    productID: number;
    description: string;
    contraindications: string;
    administration: string;
    missedDoseInstructions: string;
    monitoring: string;
    sideEffects: string;
    storage: string;
}
export class NonGSDDPatEd {
    constructor(obj?:NonGSDDPatEd){
        if(obj) Object.assign(this, obj);
    }
}

export interface NonGSDDWarningLabels {
    id: number;
    productID: number;
    order: number;
    warning: string;
}
export class NonGSDDWarningLabels {
    constructor(obj?:Array<NonGSDDWarningLabels>){
        if(obj) Object.assign(this, obj);
    }
}

export interface NonGSDDDrug extends DrugProduct {
    productID?: number;
    name: string;
    awp: number|null;
    unitOfMeasure: string;
    minimumDispenseQuantity: number;
    deaClass: string;
    patEd: NonGSDDPatEd;
    warningLabels: Array<NonGSDDWarningLabels> | null;
    vendors?: VendorDrugMap[];
    status: DrugStatusEnum;
    din: string;
    dispensingFee: number|null;
}

export class NonGSDDDrug extends DrugProduct {
    constructor(productId?:number|null, obj?:NonGSDDDrug|DrugProduct){
        super();
        this.productID = productId || 0;
        this.drugId = Number(this.productID);
        this.activeStatus = DrugStatusEnum.Inactive;
        this.source = DrugSource.NonGsddProduct;

        if(obj){
            Object.assign(this, obj);
            this.productID = obj.drugId;
        }
    }
    toString(): string {
        const productName = this.name;
        let preferred = '';
        if (this.status == DrugStatusEnum.ActiveAndPreferred) {
            preferred = '\u2B50 ';
        }
        if (productName)
            return this.ndc ? `${preferred}(NG) ProductID:${this.productID}, NDC:${this.ndc} - ${productName} ${this.packageSize ? ("- PS: " + this.packageSize) : ""}`
                : productName;
        else return '';
    }

    set activeStatus(val: DrugStatusEnum) {
        this.status = val;
    }

    get activeStatus(): DrugStatusEnum {
        return this.status;
    }

    get productNameShort(): string {
        return this.name;
    }
    set productNameShort(val: string){
        this.name = val;
    }

    get isCII():boolean{
        return this.deaClass == "schedule II";
    }

    get NDCFormat542():string{
        return NonGSDDDrug.formatNDC542(this.ndc);
    }

    static formatNDC542(value:string | null) : string {
        if(!value) return '';
        return `${value.substr(0, 5)}-${value.substr(5, 4)}-${value.substr(9)}`;
    }
}

export namespace NonGSDDDrug {
    export class EquivalentNonGSDDDrug{
        productID? : number;
        ndc? : string;
        productName? : string;
        packageSize?: number;
    }
}
