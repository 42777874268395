
import { Component, Mixins } from "vue-property-decorator";
import { DatePicker } from "element-ui";
import moment from "moment";
import DisableAutocompleteMixin from "@/mixins/DisableAutocompleteMixin";
import { Patient } from "@/models/Patient";
import { Prescriber } from "@/models/Prescriber";
import { BvTableCtxObject } from "bootstrap-vue";
import Axios, { AxiosRequestConfig } from "axios";
import { Prescription, RefillStatusCode } from '@/models/Prescription';
import SearchComponent from "@/components/SearchComponent.vue";
import { Drug } from "@/models/Drug/Drug";

@Component({
    name: "DrugDashboard",
    components: {
        [DatePicker.name]: DatePicker,
        SearchComponent,
    }
})
export default class DrugDashboard extends Mixins(DisableAutocompleteMixin) {
    private drug: Drug = new Drug();

    private toDate: any = moment().toDate();
    private fromDate: any = moment().subtract(7, "days").toDate();

    private fields = [
        { key: "productNameShort", label: "Drug", sortable: true },
        { key: "prescriber", formatter: this.prescriberFormatter, sortable: true, },
        { key: "patient", label: "Patient", formatter: this.patientFormatter, sortable: true, class: "patient-col" },
        { key: "storeName", label: "Store", sortable: true },
        { key: "rxId", sortable: true },
        { key: "quantity", sortable: true },
        { key: "writtenDate", formatter: (d: any) => moment(d).format("L"), sortable: true },
        { key: "fillDate", formatter: (d: any) => moment(d).format("L"), sortable: true, },
        { key: "refillDue", },
        { key: "patientPay", formatter: (d: number) => `$${d?.toFixed(2) ?? '0.00'}`, sortable: true },
        { key: "programName", label: "Program", sortable: true },
        { key: "state", },
        { key: "orderStatus", label: "Order Status", sortable: true, },
        { key: "trackingNumber", label: "Tracking#", sortable: true, },
        { key: "shipDate", label: "Ship Date", formatter: (d: any) => d.length > 0 ? moment(d).format("L") : "", },
        { key: "edit", label: "" },
    ];

    prescriberFormatter(dr: Prescriber): string {
        dr = new Prescriber(dr);
        return dr.displayNamesForPerson();
    }

    patientFormatter(pt: Patient): string {
        pt = new Patient(pt);
        return pt.displayNamesForPerson();
    }

    get tableFilter() {
        return {
            searchTerm: this.drug.drugId,
            fromDate: this.fromDate,
            toDate: this.toDate,
        };
    }

    private calculateRefillDue = (item: any) => {
        // (days supply - 21) for first fill, (days supply - 14) on subsequent fills
        console.log("item: ", item);
        let daysToAdd = item.daySupply - 14;
        if (item.rfNumber == 0) daysToAdd -= 7;

        let day = moment.utc(item.dateOfService).add(daysToAdd, "days");
        return day.format('L');
    };

    ctxProvider(ctx: BvTableCtxObject, callback: Function) {
        if (ctx.apiUrl == null) return null;

        const uri = ctx.apiUrl;
        const config: AxiosRequestConfig = {
            params: ctx.filter
        };
        Axios.get<Prescription[]>(uri, config)
            .then(resp => {
                callback(resp.data.map(rx => {
                    const item = new Prescription(rx) as any;
                    item.refillDue = this.calculateRefillDue(rx);
                    return item;
                }));
            }).catch(_err => {
            callback([]);
        });
        return null;
    }

    rxDetailsRoute(item: Prescription): any {
        return {
            name: "PrescriptionDetails",
            params: {
                storeID: item.storeID,
                rxNumber: item.rxNumber,
                rfNumber: item.rfNumber
            }
        };
    }

    colorCodeTable(rxRow: Prescription, type: any) {
        if (!rxRow || type !== 'row') return;
        const rx = new Prescription(rxRow);
        if (rx.status == RefillStatusCode.TransferOut)
            return 'table-info';
        else if (rx.status == RefillStatusCode.Void)
            return 'table-dark';
        else if (!rx.isRefillableStatus)
            return 'table-danger';
    }

    isRefillable(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.isRefillableStatus;
    }

    statusName(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.statusName;
    }
}
