

import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { TextData } from '@/models/TextData';
import { Patient } from '@/models/Patient';
import axios, {AxiosError} from 'axios';

@Component({
    name: "TextMessagesList"
})
export default class TextMessagesList extends Vue {
    @Prop({default: "No text messages found."}) private emptyText!: string;
    @Prop({default: "text-message-modal"}) private modalId! : string;
    @Prop({default: ""}) private patient!: Patient;
    
    private numberType:string = "";
    private messages: Array<TextData> = new Array<TextData>();
    private isLoading: boolean = false;

    openModal(numberType:string){
        if(!(this.patient?.id && numberType)) return;

        this.$bvModal.show('text-message-modal');
        this.numberType = numberType;
        this.isLoading = true
        axios.get<Array<TextData>>(`/Patient/${this.patient.id}/text-message-history/${numberType}`)
            .then(response => {
                this.messages = response.data;
            }).catch((error:AxiosError) => {
                console.log("Error getting text message history", {error, response: error?.response});
            }).finally(() => {
                this.isLoading = false;
            });
    }
    
    get patientNumber():string {
        if(this.numberType == 'phoneNumber') return this.patient.cellNumber;
        else if(this.numberType == 'cellNumber') return this.patient.phoneNumber;
        else return '';
    }
    
}
