
    import Vue from 'vue'
    import {Component, Model, Prop, Watch, Mixins, PropSync} from 'vue-property-decorator';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import AddressFormFields from '@/components/AddressFormFields.vue';

    import { Patient } from '@/models/Patient';

    @Component({
        name: "PatientDemographicsFormComponent",
        components:{
            AddressFormFields
        }
    })
    export default class PatientDemographicsFormComponent extends Vue{
        @Prop() patientId!:number;

        private patient: Patient = new Patient();

        created(){
            this.loadPatient();
        }

        loadPatient(){
            if(!this.patientId) return;

            this.$http.get<Patient>(`/Patient/${this.patientId}`)
                .then(response => {
                    this.patient = Object.assign(new Patient(), response.data);
                })
                .catch(error => {
                    console.error("Problem loading patient demographics", {error, response: error?.response});
                });
        }

        save(){
            if(!this.patient?.id) return;

            this.$http.post<Patient>(`/Patient/patient-demographics/`, this.patient)
                .then(res => {
                    if(res.data?.id){
                        this.patient = Object.assign(new Patient(), res.data);
                        this.$notification(NotificationOptions.successSaveNotificationPreset("Patient Demographics"));
                        this.$emit('saved', this.patient);
                    }
                })
                .catch(error => {
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Patient Demographics"));
                    console.error("Problem saving patient demographics", {error, response: error?.response});
                });
        }
    }
 