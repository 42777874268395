import HasID from "./HasID";
import moment from "moment";

export interface Shipment extends HasID {
    shipmentType: string,
    verified: Date,
    verifiedBy: string,
    shipped: Date,
    shippedBy: string,
    voided: boolean,
    avalaraDocumentId: string,
    trackingNumber: string,
    shippingStation: string,
    shippingCost: number,
    serviceType: string,
    trackingUrl: string,
    shipEngineShipmentId: string,
    delivered: boolean,
    anticipatedDeliveryDate: Date,
    actualDeliveryDate: Date,
    deliveryConfirmationDate: Date,
    deliveryConfirmationIP: string,
    deliveryConfirmationSignature: string
}

export class Shipment {
    constructor(obj?: Shipment) {
        if (obj) {
            Object.assign(this, obj);
            if (obj.verified) this.verified = moment(obj.verified).toDate();
            if (obj.shipped) this.shipped = moment(obj.shipped).toDate();
            if (obj.anticipatedDeliveryDate) this.anticipatedDeliveryDate = moment(obj.anticipatedDeliveryDate).toDate();
            if (obj.actualDeliveryDate) this.actualDeliveryDate = moment(obj.actualDeliveryDate).toDate();
            if (obj.deliveryConfirmationDate) this.deliveryConfirmationDate = moment(obj.deliveryConfirmationDate).toDate();
        }
    }
}
