
    import { Component, Watch, Mixins, Prop } from 'vue-property-decorator';
    import { DatePicker } from "element-ui";
    import axios, { AxiosError } from 'axios';
    import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';

    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import { LockHandler, Lock } from '@/mixins/LockHandler';
    import { DeliveryCode } from '@/models/Prescription';
    import { Program } from '@/models/Program';
    import { Patient, PatientProgram, Language, ResidenceCode } from '@/models/Patient';
    import EnumSelector from '@/components/EnumSelector.vue';
    import NameSearchComponent from '@/components/NameSearchComponent.vue';
    import SearchComponent from '@/components/SearchComponent.vue';
    import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
    import AddressFormFields from '@/components/AddressFormFields.vue';
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import EligibilityComponent from "@/components/Insurance/EligibilityComponent.vue";
    import { GSDDPatientAllergy } from "@/models/GSDD";
    import PatientPlansComponent from "@/components/Insurance/PatientPlansComponent.vue";
    import GSDDAllergyComponent from "@/components/GSDDAllergies/GSDDAllergyComponent.vue";
    import { CombinedPlan } from "@/models/CombinedPlan";

    @Component({
        name: "PatientFormComponent",
        components: {
            NameSearchComponent,
            SearchComponent,
            GSDDAllergyComponent,
            NewClearSaveButtons,
            AddressFormFields,
            EnumSelector,
            Checkbox,
            [DatePicker.name]: DatePicker,
            EligibilityComponent,
            PatientPlansComponent,
        }
    })
    export default class PatientFormComponent extends Mixins(LockHandler, DisableAutocompleteMixin) {

        @Prop({
            default: () => {
                return {
                    new: "New Patient",
                    clear: "Clear",
                    save: "Save Changes",
                    cancel: ""
                };
            }
        }) private buttonTitles!: any;
        @Prop({ type: Boolean, default: false }) private createNewPatient!: boolean;
        @Prop() private escriptResponsePatient?: Patient;
        @Prop({type: Boolean, default: false}) private hideInsurance!: boolean;
        created() {
            console.log("%c Created PatientComponent", "color: green");
            // If route as a PatientId as argument, load its data by default.
            if (this.routePatientId)
                this.fetchPatient({ id: this.routePatientId } as Patient);

            if (this.createNewPatient)
                this.createPatient();
        }

        eligibilityCheck(eligibilityCallback: Function) {
            console.log("eligibilityCheck: ");
            this.submitUserInformation(eligibilityCallback);
        }

        private get _buttonTitles() {
            let res = {};
            if (this.routePatientId)
                res = {
                    save: "Save Changes",
                    clear: "",
                    new: "",
                    cancel: "Back to Dashboard",
                };
            else
                res = this.buttonTitles;

            return res;
        }

        private patient: Patient = new Patient();
        private patientPrograms: PatientProgram[] = [];
        private selectedProgram: Program = new Program();
        private patientAllergies: GSDDPatientAllergy[] = [];
        private selectedAllergy: GSDDPatientAllergy = new GSDDPatientAllergy();

        private combinedPlanToAdd: CombinedPlan | null = null;

        private displayName = "";
        private nameIntroduced = "";
        private showDismissibleAlert = false;

        private patientLock: Lock | null = null;
        private deliveryCodes = DeliveryCode;
        private language = Language;
        private residenceCode = ResidenceCode;

        private newMode = false;
        private updateAllergies = false;

        get routePatientId(): number {
            let res = 0;
            res = parseInt(this.$route.params.id);
            if (isNaN(res))
                return 0;
            return res;
        }

        get disableFields(): boolean {
            const lockedForUser = !this.patientLock;
            const noEntityLoaded = !(this.patient && this.patient.id);
            // Disable fields if
            // ** its locked for the user
            // ** or if there is no object loaded and
            return (lockedForUser || noEntityLoaded) && !this.newMode;
        }

        nameChanged(val: string) {
            this.nameIntroduced = val;
        }

        get isFormValid() {
            return !!(this.nameIntroduced && this.patient.sex);
        }

        // pregnant
        get pregnant(): any {
            return (this.patient && this.patient.pregnant) ? true : false;
        }
        set pregnant(value: any) {
            if (!this.patient) return;
            this.patient.pregnant = value ? new Date() : undefined;
        }
        // lactating
        get lactating(): any {
            return (this.patient && this.patient.lactating) ? true : false;
        }
        set lactating(value: any) {
            if (!this.patient) return;
            this.patient.lactating = value ? new Date() : undefined;
        }
        // smoker
        get smoker(): any {
            return (this.patient && this.patient.smoker) ? true : false;
        }
        set smoker(value: any) {
            if (!this.patient) return;
            this.patient.smoker = value ? new Date() : undefined;
        }

        @Watch("patient")
        onPatientChanged(value: Patient | null, oldValue: Patient | null) {
            if (!value) {
                this.displayName = "";
                return;
            }
            const patient: Patient = value as Patient;
            if (!patient.id) {
                patient.updateNamesForPersonFromDisplayName(this.displayName);
            } else {

                if (!oldValue || oldValue.id != patient.id) {
                    // Get the full patient object instead of the tiny stub we get from backend.
                    this.fetchPatient(patient);
                }

            }
            this.displayName = patient.displayNamesForPerson();
            this.nameIntroduced = patient.displayNamesForPerson();
        }

        @Watch('patient.alert') onAlertChanges(value: string, oldValue: string) {
            this.showDismissibleAlert = (!!value && !oldValue);
        }

        @Watch('selectedProgram') onSelectedProgramChanged(value: Program | null, oldValue: Program | null) {
            if (value?.id) {
                this.addProgram(value);
            }
        }

        fetchPatient(patient: Patient) {
            // first attempt to get a lock
            // then get the patient object
            this.addLockWithURL(`/Patient/${patient.id}/lock`, 60000)
                .then(result => {
                    this.patientLock = result;
                })
                .catch(error => {
                    console.log(`Added lock -- error == ${error}`);
                    if (error.response && error.response.status == 418) {
                        // patient locked
                        console.log(error.response.data);
                        const lockData = error.response.data;
                        const lockedBy = lockData.lockedBy;
                        const expires = lockData.expires;
                        this.$bvModal.msgBoxOk(`The Patient is locked by ${lockedBy} until ${expires}.`);
                    }
                })
                .finally(() => {
                    axios.get(`/Patient/${patient.id}`)
                        .then(response => {
                            this.patient = Object.assign(new Patient(), response.data);
                        })
                        .catch(error => {
                            console.error("Error while loading the Patient", { error, response: error?.response });
                        });

                    //Get programs associated to the patient
                    axios.get(`/Patient/${patient.id}/programs`)
                        .then(response => {
                            this.patientPrograms = response.data;
                        })
                        .catch(error => {
                            console.error("Error while loading the programs", { error, response: error?.response });
                        });

                    //Get GSDD patient Allergies
                    axios.get(`/Allergy/GetGSDDAllergiesForPatient/${patient.id}`)
                        .then(response => {
                            this.patientAllergies = response.data;
                        }).catch(error => {
                            console.error("Error while loading the insurance allergies", { error, response: error?.response });
                        });
                });

        }

        patientUpdated(value: any) {
            this.patient = value;
            this.nameIntroduced = this.patient.displayNamesForPerson();
        }


        showConfirmationModal() {
            this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
                title: 'Confirm',
                okVariant: 'danger',
                centered: true,
            })
                .then(value => {
                    if (!value) return;
                    if (this.patientLock && this.patientLock.refreshURL) {
                        this.releaseLockAtURL(this.patientLock.refreshURL);
                        this.patientLock = null;
                    }
                    this.patient = new Patient();
                    this.displayName = "";
                    this.patientPrograms = [];
                    this.selectedProgram = new Program();
                    this.newMode = false;
                })
                .catch(err => {
                    console.log("Error caught in showConfirmationModal()");
                    console.error(err);
                });
        }

        createPatient() {
            this.newMode = true;
            this.displayName = "";
            this.patient = new Patient();
            if (this.escriptResponsePatient) {
                Object.assign(this.patient, this.escriptResponsePatient);
                this.displayName = this.patient.displayNamesForPerson();
                this.nameIntroduced = this.patient.displayNamesForPerson();
            }
        }

        submitUserInformation(eligibilityCallback: Function | null = null) {
            if (!this.patient) {
                console.warn("Don't have a patient.... we need to make a new one I guess!");
                this.createPatient();
            }

            const patient: Patient = this.patient as Patient;
            patient.updateNamesForPersonFromDisplayName(this.displayName);

            const data = patient;
            data.programs = this.patientPrograms;

            //Save patient
            axios.post<Patient>('/Patient', data)
                .then(response => {
                    if (eligibilityCallback) eligibilityCallback();

                    //Save its allergies
                    axios.post(`/Allergy/SaveGSDDAllergies/${response.data.id}`, this.patientAllergies)
                        .then(allergyResp => {
                            console.log("Patient allergies were saved sucessfully.");
                            this.$notification(NotificationOptions.successSaveNotificationPreset("Patient"));
                            this.patient = Object.assign(new Patient(), response.data);

                            if (!this.createNewPatient)
                                this.backToPatientDashboard();

                            if (this.createNewPatient) {
                                this.$emit("created", this.patient);
                            }
                        })
                        .catch((error: AxiosError) => {
                            const errorMsg = "Error while saving allergies";
                            this.$notification(NotificationOptions.notificationOptionsPreset(errorMsg, NotificationOptions.NotificationTypes.success));
                            console.error(errorMsg, { error, response: error?.response });
                        });
                })
                .catch((error: AxiosError) => {
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Patient"));
                    console.error("Error while saving patient", { error, response: error?.response });
                })
                .finally(() => {
                    this.patientAllergies = [];
                    this.selectedAllergy = new GSDDPatientAllergy();
                });
        }

        backToPatientDashboard() {

            if (this.createNewPatient) {
                this.$emit("close");
                return;
            }

            if (this.routePatientId) {
                this.$router.push({
                    name: 'PatientDashboard',
                    params: { id: this.routePatientId.toString() }
                });
            }
        }

        addProgram(newProgram: Program | null = null) {
            const selectedProgram: Program | null = newProgram || this.selectedProgram;
            if (selectedProgram) {
                const wasAdded = this.patientPrograms.filter(pp => pp.programID == selectedProgram.id)[0];
                if (!wasAdded) {
                    const newPatientProgram = {
                        programID: selectedProgram?.id,
                        patientID: this.patient?.id,
                        programName: selectedProgram?.name,
                        identifier: ''
                    } as PatientProgram;

                    this.patientPrograms.push(newPatientProgram);
                }
            }
        }

        removeProgram(programID: number) {
            this.patientPrograms = this.patientPrograms.filter(p => p.programID != programID);
        }
    }
