export default class TextUtils {    
    static camelCaseToNormal(text: string): any {
        if(text){
            let res = text;
            // insert a space before all caps
            res = res.replace(/([A-Z])/g, ' $1').trim();
            // uppercase the first character
            res = res.replace(/^./, function(str){ return str.toUpperCase(); })
            return res;
        } else return '';
    }
}