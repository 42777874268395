const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:6f5d055b-b90b-42a5-ad7b-c99cbfc9f713",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_sNGXY3KvN",
    "aws_user_pools_web_client_id": "63k2539li3la6a4fmobrtahqsk",
    "oauth": {}
};

export default awsmobile;
