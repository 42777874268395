

    import { Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import { Consultation } from '@/models/Consultation';
    import NRadio from "@/components/Inputs/Radio.vue";

    @Component({
        name: "CompleteConsultationComponent",
        components: {
            NRadio,
        }
    })
    export default class CompleteConsultationComponent extends Vue {
        @Prop() private consultation!: Consultation;
        @Prop() private enableEdit!: boolean;

        private completed = 'false';
        private isClinical = 'false';
        private backUpStatus = 0;

        created() {
            console.log("%c Created CompleteConsultationComponent", "color: green");
            // do some sort of traffic logging here for ease of bug reporting in future
            this.backUpStatus = this.consultation.status
            this.completed = this.consultation.status == 4 ? 'true' : 'false';
            this.isClinical = this.consultation.clinical ? 'true' : 'false';
        }
        beforeDestroy() {
            console.log('%c Destroying CompleteConsultationComponent', 'color: red');
        }

        @Watch('completed')
        updateStatus() {
            this.consultation.status = this.completed == 'true' ? 4 : 2;
        }

        @Watch('isClinical')
        updateClinical() {
            this.consultation.clinical = this.isClinical == 'true';
        }
    }
