
    import Vue from 'vue'
    import moment from 'moment';
    import { Component, Watch, Prop } from 'vue-property-decorator';
    import { AutoRefillSubscription } from '@/models/AutoRefillSubscription'
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    
    import RXSubscriptionForm from '@/components/Prescription/RXSubscriptionForm.vue'
        
    @Component({
        name: "RXSubscriptionsGrid",
        components: { 
            RXSubscriptionForm, Checkbox
        },
    })
    export default class RXSubscriptionsGrid extends Vue {
        @Prop() private value! : number;
        
        private items : Array<AutoRefillSubscription> = new Array<AutoRefillSubscription>();
        private selectedSub: AutoRefillSubscription = new AutoRefillSubscription();
        private isLoading = false;
        private showCanceled = false;
        private fields: any[] = [
            { key: "StoreRx", label: "Rx" },
            { key: "nextProcessDate", label: "Next Process" },
            { key: "drugName", label: "Drug" },
            { key: "updated", label: "Updated" },
            { key: "updatedBy", label: "Updated By", formatter: (c: any) => c.split("@")[0]  },
            { key: "actions", label : '' },
        ];

        created(){
            this.loadData();
        }

        @Watch('showCanceled') showCanceledChanged(){
            this.loadData();
        }
        
        @Watch('value') loadData(){
            if(!this.value) return;
            
            this.isLoading = true;
            this.$http.get<Array<AutoRefillSubscription>>(`/autorefill/subcriptions-by-patient/${this.value}`, {params: { showCanceled : this.showCanceled }})
                .then(res => {
                    this.items = res.data.map(i => new AutoRefillSubscription(i));
                }).catch(err => {
                    console.log("Error Getting auto refill subscriptions", {err, response: err?.response});
                })
                .finally(()=>this.isLoading = false);
        }

        clearData(){
            this.items = [];
        }

        openModal(sub:AutoRefillSubscription){
            this.selectedSub = sub;
            this.$bvModal.show('rx-subscription-ingrid')
        }

        closeSubscriptionModal(){
            this.$bvModal.hide('rx-subscription-ingrid')
            this.loadData();
        }

        rowTitle(row : AutoRefillSubscription){
            let res = '';
            if(row.cancellationDate) return `Canceled by ${row.updatedBy}`;

            return res;
        }

        colorCodeTable(row : AutoRefillSubscription, type: string){
            if (!row || type !== 'row')
                return;

            if(row.cancellationDate || row.isExpired) return 'table-danger';
        }
    }
