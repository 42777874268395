
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import {Shipment} from '@/models/Shipment'
    import {Order} from '@/models/Order'

    import axios from 'axios';
    import moment from "moment";
    import numeral from "numeral";
    import { Prescription } from '@/models/Prescription';

    @Component({
        name: "ShippingTicketComponent"
    })
    export default class ShippingTicketComponent extends Vue {
        @Prop() private value!: Shipment;

        private order:Order = new Order();
        
        private rxFields: any[] = [
            {key: "rxNumber", label: "Rx Nbr"},
            {key: "rfNumber", label: "Rf"},
            {key: "storeName", label: "Store"},
            {key: "productNameShort", label: "Drug"},
            {key: "quantity", label: "Qty", class: "text-center", },
            {key: "patientPay", label: "Copay", class: "text-center", },
            {key: "fillDate", label: "Fill Date", formatter: this.dateFormatter},
            {key: "insurance", label: "PlanNames"}
        ];

        private otcFields: any[] = [
            {key: "otcItemName", label: "Product Name", },
            {key: "otcItemUPC", label: "UPC", },
            {key: "quantity", label: "Qty", },
            {key: "price", label: "Price", formatter: this.moneyFormatter},
            {key: "tax", label: "Tax", },
        ];
        
        created(){
            console.log('numeral',numeral)
            axios.get<Order>(`/order/ShippingTicket/${this.value.id}`)
                .then(response => {
                    this.order = new Order(response.data);
                })
                .catch(error => {
                    console.error("Error while loading order information in shipping ticket",{ error, response: error?.response });
                });
        }

        dateFormatter(value: Date, key: string, item: any) {
            return moment.utc(value).local().format("L");
        }

        moneyFormatter(value: number) {
            return numeral(value).format('$0,0.00');
        }

        get prescriptions():Array<any>{
            if(!this.order?.prescriptions?.length) return [];
            
            return this.order.prescriptions.map(rx => {
                let res:any = rx;
                res.insurance = rx.claims?.length ? rx.claims[0].planName : ''; //Showing 1st claim plan name
                return res;
            });
        }
    }

