
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import axios from 'axios';
    import { Patient } from '../../models/Patient';
    import { IRSStatement } from '../../models/IRSStatement';
    import { DatePicker } from 'element-ui'
    import moment from 'moment'


    @Component({
        name: "IRSReportComponent",
        components: {
            [DatePicker.name]: DatePicker
        }
    })

    export default class IRSReportComponent extends Vue {
        @Prop() patient!: Patient;
        private dateObj = new Date();
        private startDate: Date = new Date("01/01/" + (this.dateObj.getFullYear() - 1).toString());
        private endDate: Date = new Date("12/31/" + (this.dateObj.getFullYear() - 1).toString());
        private deliveryMethod: string = "";
        private sendTo: string = "";

        private sendToPatientOptions: any = [];
        private pdfSource: string|null = null;

        created() {
            console.log(this.patient);
            this.sendToPatientOptions =
                [
                    { value: this.patient.emailAddress, text: this.patient.emailAddress },
                    { value: this.patient.phoneNumber, text:this.patient.phoneNumber},
                    { value: this.patient.cellNumber, text:this.patient.cellNumber}
                ]
            this.sendTo = this.sendToPatientOptions[0].value;
        }

        async viewReport() {
            let url = `reports/embeddedIRS/${this.patient.id}/${moment(this.startDate).format("YYYY-MM-DD")}/${moment(this.endDate).format("YYYY-MM-DD")}`;
            this.pdfSource = URL.createObjectURL((await axios.get(url, { responseType: "blob" })).data);
        }

        sendReport() {
            let data: IRSStatement = new IRSStatement;

            data.patientId = this.patient.id as number;
            data.startDate = this.startDate;
            data.endDate = this.endDate;
            data.deliveryMethod = this.deliveryMethod;
            data.sendTo = this.sendTo;

            axios.post(`/reports/IRSStatement/Send`, data)
                .then(response => {
                    this.$notification({
                        message: "IRS Statement Sent Successfully",
                        timeout: 3000,
                        icon: "now-ui-icons ui-1_bell-53",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "success"
                    });

                })
                .catch(error => {
                    this.$notification({
                        message: "IRS Statement Sending Failed",
                        timeout: 3000,
                        icon: "now-ui-icons ui-1_bell-53",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger"
                    });

                })
        }
    }
