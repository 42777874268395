import HasID from "./HasID";
import TrackedObject from "./TrackedObject";
import { Contact } from "./Contact";
import { FunctionalityFlag } from "./FunctionalityFlag";
import { PriceSchedule } from "./PriceSchedule";
import { Prescriber } from "./Prescriber";
import { DeliveryCode } from "./Prescription";
import { DrugProgram } from '@/models/Drug/Drug';
import HasLabel from "./HasLabel";

export interface Program extends HasID, TrackedObject {
    name: string;
    abbreviation: string;
    memo: string;
    active: boolean;
    forceMemo: boolean;
    customTemplateFileId: number;
    programContact: Contact;
    businessContact: Contact;
    deliveryMethod?: DeliveryCode;
    priceSchedule: PriceSchedule | null;
    flags: FunctionalityFlag[];
    customTemplate?: File;
    customTemplateUpdated: Date
    drugs?: Array<DrugProgram>
    prescribers?: Array<Prescriber>
}

export class Program extends HasLabel{
    constructor(obj?: Program){
        super();
        this.flags = new Array<FunctionalityFlag>();
        if (obj) {
            Object.assign(this, obj);
        }
    }

    toString(): string {
        if(this.name)
            return `Program - Abbr: ${this.name} - ${this.abbreviation || ''}`
        else return ''
    }

}
