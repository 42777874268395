
    import { Vue, Component, VModel, Watch } from "vue-property-decorator";
    import { Claim } from "@/models/Claim";
    import Axios from "axios";

    @Component({
        name: "BillingDetailsComponent",
    })
    export default class BillingDetailsComponent extends Vue {
        @VModel({ required: true }) private claim!: Claim;

        private transmission: any = {};

        get currencyFields() {
            return [
                "totalPriceSubmitted",
                "adjudicated",
                "planPaid",
                "voucherPaid",
                "copay",
                "usualAndCustomary",
                "patientPay",
                "drugCost",
                "ingredientCostSubmitted",
                "dispensingFeeSubmitted",
                "incentiveSubmitted",
                "totalTaxSubmitted",
                "ingredientCostPaid",
                "dispensingFeePaid",
                "incentiveAmountPaid",
                "deliveryAmountPaid",
                "totalTaxPaid",
                "priceDifference",
                "margin"
            ];
        }

        formatField(field: string): string {
            let ret = field.replace(/([a-z])([A-Z])/g, "$1 $2");
            ret = ret.substring(0, 1).toUpperCase() + ret.substring(1);
            return ret;
        }

        created() {
            console.log(`%c Created ${this.$options.name}`, "color: green");
        }
        mounted() {
            this.fetchTransmission();
        }
        beforeDestroy() {
            console.log(`%c Destroying ${this.$options.name}`, "color: red");
        }

        @Watch("claim", { deep: true })
        @Watch("value", { deep: true })
        claimUpdate(cl: Claim, oldCl: Claim) {
            console.log("Claim: ", cl);
            console.log("oldClaim: ", oldCl);
        }

        @Watch("claim.transmissionId")
        @Watch("value.transmissionId")
        transmissionIdUpdate(id: any, oldId: any) {
            console.log(`id: ${id} -- oldId: ${oldId}`);

            this.fetchTransmission();
        }

        fetchTransmission() {
            console.log("fetching transmission?");
            if (!this.claim?.transmissionId) return;
            const transmissionUrl = `Insurance/Transmission/${this.claim.transmissionId}`;
            console.log("fetching transmission");
            Axios.get(transmissionUrl)
                .then(resp => {
                    this.transmission = resp.data;
                })
                .finally(() => { console.log("fetched transmission!"); });
        }
    }
