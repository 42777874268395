import  TrackedObject from "./TrackedObject";
import HasID from "./HasID";
import { Prescription, DeliveryCode } from "./Prescription";
import { Payment } from "./Payment";
import { Patient } from "./Patient";
import { Shipment } from "@/models/Shipment";
import moment from "moment";

export interface Order extends HasID, TrackedObject {
    storeID: number
    patientID: number
    status: OrderStatus
    storeName: string
    rxRfs: string
    PrinterAddress: string
    OverridePriority: boolean
    SaturdayDelivery: boolean
    OverrideAddress: boolean
    SignatureRequired: boolean
    HIPAADocsInBox: boolean
    stripeCustomerId: string

    prescriptions: Array<Prescription>;
    items: Array<OrderOTCItem>
    payments: Array<Payment>;
    deliveryCode: DeliveryCode | null;
    patient:Patient;
    shipment?: Shipment;
}

export enum OrderStatus {
    Error,
    Created,
    Processing,
    Shipped,
    Canceled,
    PartialPayment,
    ReadyForPickup,
    AwaitingPriorAuthorization
}

export class Order {
    constructor(obj?:Order){
        if(obj){
            Object.assign(this, obj);
            this.patient = new Patient(obj.patient);
            this.prescriptions = obj.prescriptions.map(rx => new Prescription(rx));
            this.items = obj.items;
            this.shipment = new Shipment(obj.shipment);
            this.updated = moment(obj.updated).toDate();
        } else {
            this.patient = new Patient();
            this.prescriptions = new Array<Prescription>();
            this.items = new Array<OrderOTCItem>();
            this.payments = new Array<Payment>();
            this.deliveryCode = null;
        }
    }

    get wasShipped():boolean {
        return this.status == OrderStatus.Shipped;
    }

    get trackingNumber(): string {
        return this.shipment?.trackingNumber ?? "";
    }

    get shipDate(): string {
        return this.shipment?.shipped.toString() ?? "";
    }
}

export interface OrderOTCItem{
    orderId: number
    otcItemId: number
    quantity: number
    otcItemName: string
    otcItemUPC?: string
    price: number
    tax: number
}

export interface OrderPrescription extends TrackedObject {
    orderId: number
    storeID: number
    rxNumber: number
    rfNumber: number
}
