

    import Card from "@/components/Cards/Card.vue";
    import { Component, Prop, Vue, Watch } from "vue-property-decorator";
    import { PriorAuthorization, PriorAuthorizationStatusCode } from "@/models/PriorAuthorization";
    import moment from "moment";
    import Axios from 'axios';
import { NotificationOptions } from '../../util/NotificationOptionsPresets';

    @Component({
        name: "PriorAuthorizationDetails",
        components: {
            Card,
        }
    })
    export default class PriorAuthorizationDetails extends Vue {
        @Prop() private value!: PriorAuthorization;
        @Prop() private id!: string;
        private notes: any[] = [];
        private newNoteBody: string = "";

        private templates: any[] = [
            { value: "1", text: "NO PA'S PER MDO" },
            { value: "2", text: "NO RESPONSE FROM MDO" },
            { value: "3", text: "LVM @ MDO TO FOLLOW UP" },
            { value: "4", text: "FAXED MDO TO FOLLOW UP" },
            { value: "5", text: "MDO CLOSED UNABLE LVM" },
            { value: "6", text: "RECEIVED ADDITIONAL INFORMATION, SENT TO PLAN" },
            { value: "7", text: "SENT TO PLAN AS IS" },
            { value: "8", text: "NO RESPONSE FROM PLAN, TEST CLAIM PAYS, MARKING AS APPROVED" },
            { value: "9", text: "NO REPONSE FROM PLAN, TEST CLAIM REJECTS, MARKING AS DENIED" },
            { value: "10", text: "PATIENT DECLINED MEDICATION" },
            { value: "11", text: "NOT A PA REJECTION" },
            //{ value: "", text: ""},
        ];

        private completeReasons: string[] = [
            "No response from MDO",
            "PA was approved",
            "There is no history of previous therapies",
            "Not approved for patient dx",
            "Unknown/other reason",
            "Patient doesn't meat criteria",
            "Other/IT Issue",
            "No patient contact",
            "Patient refused medication",
            "Not PA rejection 75",
            "MDO doesn't do prior auths",
            ];
        completeChosen(item: any) {
            console.log("chosen item: ", item);
            this.value.completeReason = item;
        }
        created() {
            console.log(`%c Created ${this.$options.name}`, "color: green");
        }
        beforeDestroy() {
            console.log(`%c Destroying ${this.$options.name}`, "color: red");
        }

        get cmmEvent(): string {
            if (!this.value) return "";
            if (!this.value.latestCallback) return "";
            return `${this.value.latestCallback.event_key} - ${this.value.latestCallback.message}`;
        }
        get coverMyMedsId(): string {
            if (!this.value) return "";
            return this.value.coverMyMedsId;
        }
        get status(): string {
            if (!this.value) return "";
            return PriorAuthorizationStatusCode[this.value.status];
        }
        get drugName(): string {
            if (!this.value) return "";
            if (!this.value.prescription) return "";
            return this.value.prescription.productNameShort;
        }
        get prescriberName(): string {
            if (!this.value) return "";
            if (!this.value.prescriber) return "";
            return `${this.value.prescriber.lastName}, ${this.value.prescriber.firstName}`
        }
        get storeId(): string {
            if (!this.value) return "";
            if (!this.value.prescription) return "";
            return this.value.prescription.storeID.toString();
        }
        get rxNumber(): string {
            if (!this.value) return "";
            if (!this.value.prescription) return "";
            return this.value.prescription.rxNumber.toString();
        }
        get rfNumber(): string {
            if (!this.value) return "";
            if (!this.value.prescription) return "";
            return this.value.prescription.rfNumber?.toString() ?? "0";
        }
        get createDate(): string {
            if (!this.value) return "";
            return moment.utc(this.value.created).format("L");
        }
        get updateDate(): string {
            if (!this.value) return "";
            return moment.utc(this.value.updated).format("L");
        }

        get noteFields(): any[] {
            return [
                { key: "created", label: "", formatter: (val: any) => moment.utc(val).local().format("L LT") },
            ];
        }

        resetModal() {
            this.notes = [];
            this.newNoteBody = "";
        }

        onShow() {
            this.resetModal();
            this.fetchPaNotes();
        }

        @Watch('value')
        fetchPaNotes() {
            if (!this.value.id) return;
            Axios.get(`PriorAuthorization/${this.value.id}/Note`)
                .then(response => {
                    console.log("fetchNote: ", response.data);
                    this.notes = response.data;
                })
                .catch(err => {
                    console.warn(err);
                });
        }

        savePriorAuthorization() {
            const data = this.value;
            Axios.post(`PriorAuthorization/${this.value.id}/Save`, data)
                .then(response => {
                    console.log(response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Prior Authorization"));
                    this.close();
                })
                .catch(err => {
                    console.warn(err);
                    this.$notification(NotificationOptions.errorSaveNotificationPreset("Prior Authorization"));
                })
        }

        askCompleteReason() {
            this.$bvModal.show("complete-reason-modal");
        }

        completeHide() {
            if (this.value.completeReason?.length > 0) {
                this.savePriorAuthorization();
            } else {
                console.warn("No complete reason given, not saving.");
                this.$notification(NotificationOptions.notificationOptionsPreset("No complete reason given, not saving.", NotificationOptions.NotificationTypes.warning));
            }
        };

        // button calls
        coverMyMedsClick() {
            const url = `https://env5-cmm.integration.covermymeds.com/request/view/${this.value.coverMyMedsId}`;
            window.open(url, "_blank");
        }

        templateClick(val: string) {
            const selectedTemplate = this.templates.find(t => t.value == val);
            this.newNoteBody = selectedTemplate.text;
        }

        cmmOk() {
            this.savePriorAuthorization();
        }

        saveNoteClick() {
            let data = { Body: this.newNoteBody };
            Axios.post(`PriorAuthorization/${this.value.id}/Note`, data)
                .then(response => {
                    console.log("postNote: ", response.data);
                    this.notes.push(response.data)
                    this.newNoteBody = "";
                })
                .catch(err => {
                    console.warn(err);
                });
        }

        submittedClick() {
            this.value.status = PriorAuthorizationStatusCode.SubmittedToInsurance;
            this.savePriorAuthorization();
        }
        sentToPrescriberClick() {
            this.value.status = PriorAuthorizationStatusCode.SentToPrescriber;
            this.savePriorAuthorization();
        }

        approvedClick() {
            this.value.status = PriorAuthorizationStatusCode.Approved;
            this.savePriorAuthorization();
        }

        deniedClick() {
            this.value.status = PriorAuthorizationStatusCode.Denied;
            this.askCompleteReason();
        }
        abandonedClick() {
            this.value.status = PriorAuthorizationStatusCode.Abandoned;
            this.askCompleteReason();
        }
        cancelledClick() {
            this.value.status = PriorAuthorizationStatusCode.Cancelled;
            this.askCompleteReason();
        }

        close() {
            this.$bvModal.hide(this.id);
        }
    }
