import HasID from './HasID';
import { Note } from './Note';

export interface RxTransfer extends HasID{
    requestedBy: string,
    timeStamp: Date,
    inbound: boolean,
    patientId: number,
    externalPharmacyName: string,
    externalPharmacyPhone: string,
    transferringPharmacist: string,
    transferStatus: transferStatus,
    transferStatusReason: string,
    transferStatusDate: Date,
    transferStatusSetBy: string,
    notes: Note[]
    contents: RxTransferContents[]

    //patientInfo only displayed on frontEnd in RxTransferQueue
    patientName: string,
    patientDOB: Date,
    patientState: string
}

export interface RxTransferContents extends HasID {
    rxTransferId: number,
    medicationName: string,
    rxNumber: number | null
}

export enum transferStatus {
    New,
    Open,
    Cancelled,
    Completed
}
