
    import {VModel, Vue, Component, Prop} from "vue-property-decorator";
    import {CoverMyMedsResponse} from "@/models/PriorAuthorization";
    import CoverMyMedsButtonsComponent from "@/components/PriorAuthorization/CoverMyMedsButtonsComponent.vue";

    @Component({
        components: {
            CoverMyMedsButtonsComponent,
        }
    })
    export default class CoverMyMedsResponseComponent extends Vue {
        @VModel() private cmmResponse!: CoverMyMedsResponse;
        @Prop({default: "", type: String}) private displayValue!: string;

        get status() {
            return this.cmmResponse?.status ?? "";
        }
        get message() {
            return this.cmmResponse?.message ?? "";
        }
    }
