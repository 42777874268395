
    import { Vue, Component, Prop } from "vue-property-decorator";
    import Axios from 'axios';

    @Component({
        name: "DirectionsComponent",
        components: {

        }
    })
    export default class DirectionsComponent extends Vue {
        @Prop({ default: "" }) private value!: string;
        @Prop({ default: false }) private disabled!: boolean;

        private oldValue: string = "";

        get text(): string {
            return this.value;
        }

        set text(val: string) {
            this.$emit('input', val);
        }

        created(): void {
            console.log(`%c Created ${this.$options.name}`, "color: green");
        }

        beforeDestroy(): void {
            console.log(`%c Destroying ${this.$options.name}`, "color: red");
        }

        handleBlur() {
            Axios.get<string>(`/SigTranslation/Translate`, { params: { instructions: this.text } })
                .then(resp => {
                    console.log("translate: ", resp);
                    if (this.text != resp.data) {
                        this.oldValue = this.text;
                        this.text = resp.data;
                    }
                });
        }
        undoTranslation() {
            this.text = this.oldValue;
            this.oldValue = "";
        }
    }
