

    import { EscriptResponse } from '@/models/Escript';

    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import Axios, { AxiosResponse, AxiosError } from 'axios';
    import PDFObject from 'pdfobject';

    @Component({
        name: "PrescriptionImageComponent",
    })
    export default class PrescriptionImageComponent extends Vue {
        @Prop() private imageID!: number;
        @Prop() private escriptID!: number;
        @Prop() private rxNumber!: number;

        private imageSrc?: any = null;

        created() {
            console.log("%c Created PrescriptionImageComponent", "color: green;");
            if (this.imageID)
                this.fetchPrescriptionImageSource(`image/${this.imageID}`);
            else if (this.escriptID)
                this.fetchPrescriptionEscriptResponse(this.escriptID);
        }

        beforeDestroy() {
            console.log('%c Destroying PrescriptionImageComponent', 'color: red');
        }

        @Watch('imageID') imageIDUpdated(value: number) {
            if (value)
                this.fetchPrescriptionImageSource(`prescription/image/${value}`);
        }

        @Watch('escriptID') escriptIDUpdated(value: any) {
            if (value) {
                this.fetchPrescriptionEscriptResponse(value);
            }
        }

        fetchPrescriptionEscriptResponse(value: number) {
            Axios.get<EscriptResponse>(`escript/${value}`)
                .then(response => {
                    const escript = response.data.eScript;
                    this.fetchPrescriptionImageSource(`escript/${escript.id}/image`);
                });
        }

        fetchPrescriptionImageSource(imageUrl?: string) {
            if (imageUrl) {

                Axios.get(imageUrl).then((response: AxiosResponse<any>) => {
                    const url = response.request.responseURL;
                    Axios.get(url, { responseType: "blob" }).then(r => {
                        const objectUrl = URL.createObjectURL(r.data);
                        if (response.headers["content-type"] == "application/pdf") {
                            const options = {
                                pdfOpenParams: {
                                    view: 'FitW',
                                    pagemode: 'thumbs',
                                    toolbar: 1,
                                    scrollbar: 0,
                                },
                            };
                            PDFObject.embed(objectUrl, '#pdf', options);
                        } else {
                            this.imageSrc = objectUrl;
                            this.$nextTick(() => {
                                this.$forceUpdate();
                            });
                        }
                    });
                });
            }
        }

    }
