
    import {VModel, Component, Vue} from "vue-property-decorator";
    import {CoverMyMedsResponse} from "@/models/PriorAuthorization";

    @Component({
        name: "CoverMyMedsButtonsComponent"
    })
    export default class CoverMyMedsButtonsComponent extends Vue {
        @VModel() private cmmResponse!: CoverMyMedsResponse;

        get infoLabel(): string {return this.cmmResponse.actions?.info?.buttonLabel ?? "Info";}
        get startLabel(): string {return this.cmmResponse.actions?.start?.buttonLabel ?? "Start";}
        get cancelLabel(): string {return this.cmmResponse.actions?.cancel?.buttonLabel ?? "Cancel";}

        infoClick() {
            if (!this.cmmResponse?.actions?.info?.url) return console.warn("no info url");
            window.open(this.cmmResponse.actions.info.url, "_blank");
        }
        startClick() {
            if (!this.cmmResponse?.actions?.start?.url) return console.warn("No start url.");
            window.open(this.cmmResponse.actions.start.url, "_blank");
        }
        cancelClick() {
            if (!this.cmmResponse?.actions?.cancel?.url) return console.warn("No cancel url.");
            window.open(this.cmmResponse.actions.cancel.url, "_blank");
        }
    }
