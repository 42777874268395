
import { Component, Prop, Vue, Watch, Emit, Ref } from 'vue-property-decorator';
    import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
    import EnumSelector from '@/components/EnumSelector.vue';
    import { ClaimRejects } from '@/data/ClaimRejects';
    import PatientPlansComponent from '@/components/Insurance/PatientPlansComponent.vue';
    import CoverMyMedsResponseComponent from "@/components/PriorAuthorization/CoverMyMedsResponseComponent.vue";
    import { Prescription, RefillStatusCode } from '@/models/Prescription';
    import { Claim } from '@/models/Claim';
    import { CoverMyMedsResponse, PriorAuthorization } from "@/models/PriorAuthorization";
    import BillingDetailsComponent from "@/components/Insurance/BillingDetailsComponent.vue";
    import { PatientPlan, PriorAuthorizationTypeCode, SubmissionClarificationCode } from '@/models/PatientPlan';
    import { Patient } from '@/models/Patient';
    import { PriceSchedule } from '@/models/PriceSchedule';
    import { CombinedPlan } from "@/models/CombinedPlan";
    import PatientPlanEditComponent from "@/components/Insurance/PatientPlanEditComponent.vue";
    import SearchComponent from '@/components/SearchComponent.vue';
    import moment from "moment";
    import { BvModalEvent, BModal } from "bootstrap-vue";
    import EligibilityComponent from '@/components/Insurance/EligibilityComponent.vue';
import { isNull } from 'util';


    @Component({
        name: "InsuranceBillingComponent",
        components: {
            EnumSelector,
            PatientPlansComponent,
            CoverMyMedsResponseComponent,
            BillingDetailsComponent,
            PatientPlanEditComponent,
            SearchComponent,
            EligibilityComponent
        },
    })
    export default class InsuranceBillingComponent extends Vue {
        @Prop({ default: null }) private prescription!: Prescription;
        @Prop({ default: null }) private newMode!: boolean;
        @Prop({ default: false }) private disabled!: boolean;
        @Prop({ default: false }) private readOnly!: boolean;
        @Prop({ default: false }) private billOnlyMultilfills!: boolean;
        @Prop({ default: null }) private drugLicenseType!: string;
        @Prop({ default: null }) private patient!: Patient;
        @Prop({ default: null }) private defaultPriceSchedule!: PriceSchedule;

        private cmmResponse: CoverMyMedsResponse = {
            actions: {}
        } as CoverMyMedsResponse;

        private combinedPlanToAdd: CombinedPlan | null = null;
        private detailsClaim: Claim = {} as Claim;
        private showOverrides: boolean = false;

        private paOverride: string = "";
        private tsn: string = "";
        private paTypeCode: string = "";
        private durPpsOverrides: { reasonForServiceCode: string, professionalServiceCode: string, resultOfServiceCode: string, levelOfEffort: string; }[] = [];
        private submissionClarificationCode: string = "";

        private paTypeCodes = PriorAuthorizationTypeCode;
        private submissionClarificationCodes = SubmissionClarificationCode;

        private billedClaims: Claim[] = [];
        private selectedPlan: CombinedPlan | null = null;
        private priceSchedules: Array<PriceSchedule> = [];
        private rxPriceSchedule: PriceSchedule = new PriceSchedule();
        private priceSchedulesOptions: Array<any> = [];
        private selPriceScheduleId: any = null;
        private processingInsurance = false;

        private rejects: Array<any> = ClaimRejects.codes;
        private patientPlanForEdit: PatientPlan | null = null;
        showEditModal() {
            this.patientPlanForEdit = new PatientPlan(this.selectedPlan?.patientPlan);
        }

        clearEditedPatientPlan() {
            this.patientPlanForEdit = new PatientPlan();
        }

        created() {
            if (this.prescription) {
                this.fetchInsuranceInformation();
            }
        }

        get isDermaDash():boolean{
            return this.$userProgram == 'DERMADASH' || this.$userProgram == 'DERMADASH200';
        }

        get totalPrice(): number {
            if (!this.prescription) return 0;
            console.log(this.billedClaims);
            if (this.billedClaims.length > 0) {
                let totalPlanPaid = this.billedClaims.reduce((a, b) => {
                    return a + b.planPaid + b.voucherPaid;
                }, 0);
                return totalPlanPaid + this.prescription.patientPay;
            }
            else {
                return this.prescription.patientPay;
            }
        }

        get margin(): number {
            if (!this.prescription) return 0;
            console.log(this.billedClaims);
            if (this.billedClaims.length > 0) {
                let totalPlanPaid = this.billedClaims.reduce((a, b) => {
                    return a + b.planPaid + b.voucherPaid;
                }, 0);
                return totalPlanPaid + this.prescription.patientPay - this.prescription.cost - this.prescription.tax;
            }
            else {
                return this.prescription.patientPay - this.prescription.cost - this.prescription.tax;
            }
        }

        private editCopay = false;

        fetchRxPriceShedule(priceScheduleID?: number) {
            if (!priceScheduleID && !this.prescription?.priceScheduleID) return;
            priceScheduleID = priceScheduleID || this.prescription?.priceScheduleID;
            Axios.get<PriceSchedule>(`PriceSchedule/${priceScheduleID}`)
                .then(response => {
                    this.rxPriceSchedule = response.data;
                })
                .catch(error => {
                    console.error('Error while pulling prescription Rx Schedule', { error, response: error.response });
                });
        }

        addDur() {
            const newDur = { reasonForServiceCode: "", professionalServiceCode: "", resultOfServiceCode: "", levelOfEffort: "" };
            this.durPpsOverrides.push(newDur);
        }

        @Watch("prescription")
        prescriptionUpdate(rxNew: Prescription, rxOld: Prescription) {
            const rxID1 = rxNew.rxID;
            const rxID2 = rxOld.rxID;

            if (rxID1 != rxID2) {
                this.fetchInsuranceInformation();
            }
        }

        @Watch("prescription.patientID")
        patientIDUpdate(newPatId: number | null, oldPatId: number | null) {

            if (newPatId != oldPatId) {
                this.fetchInsuranceInformation();
            }
        }

        fetchInsuranceInformation() {
            this.clearComponent();
            this.fetchPatientClaims();
            this.fetchRxPriceShedule();
        }


        @Emit("clearRx")
        clearRx() { }

        get claimIsPaid(): boolean {
            const res = this.billedClaims?.some(cl => cl.transactionStatus == "Paid");
            return res;
        }


        clearComponent() {
            this.billedClaims = [];
            this.selectedPlan = null;
            this.cmmResponse = { actions: {} } as CoverMyMedsResponse;
            this.durPpsOverrides = [];
            this.paOverride = "";
            this.paTypeCode = "";
            this.submissionClarificationCode = "";
        }

        selectPlan(cp: CombinedPlan) {
            this.selectedPlan = cp;
        }

        fetchPatientClaims() {
            if (!this.prescription.hasFullId) return;

            Axios.get(`prescription/GetClaims/${this.prescription.storeID}/${this.prescription.rxNumber}/${this.prescription.rfNumber}`)
                .then(response => {
                    this.billedClaims = response.data;
                    // if (this.billedClaims.length > 0) {
                    //     this.fetchPriorAuthorizations();
                    // }
                    this.$emit('onClaimsLoaded', this.billedClaims, this.prescription);
                })
                .catch(err => {
                    console.error('Error while getting Rx Claims', { err, response: err?.response });
                });
        }

        // fetchPriorAuthorizations() {
        //     Axios.get<PriorAuthorization>(`PriorAuthorization/ByPrescription/${this.prescription.storeID}/${this.prescription.rxNumber}/${this.prescription.rfNumber}`)
        //         .then(resp => {
        //             if (resp.data?.responses?.length > 0)
        //                 this.cmmResponse = resp.data.responses[0];
        //         });
        // }

        get disableButtons(): boolean {
            const invalidPrescription = !!(!this.prescription.storeID && !this.prescription.rxNumber && !(this.prescription.rxNumber || this.prescription.rxNumber == 0) && !this.newMode);
            //const noPlans = !(this.availableCombinedPlans?.length);
            return invalidPrescription || this.disabled || (this.prescription?.hasBeenShipped && !this.newMode);
        }

        get disableAdditionalBillingOptions(): boolean {
            const invalidPrescription = !!(!this.prescription.storeID && !this.prescription.rxNumber && !(this.prescription.rxNumber || this.prescription.rxNumber == 0) && !this.newMode);
            //const noPlans = !(this.availableCombinedPlans?.length || this.newMode);
            return invalidPrescription || this.disabled || (this.prescription?.hasBeenShipped && !this.newMode);
        }

        get is510k(): boolean {
            return (this.drugLicenseType == '510(k)' && this.billedClaims && this.billedClaims.reduce(function (i, c) { if (c.transactionStatus == "Paid") return i + 1; else return i; }, 0) > 0);
        }
        get disableBilling(): boolean {
            if (this.disableButtons) {
                return true;
            }
            return this.is510k;
        }

        getRejectReason(reject: string): string {
            return this.rejects.find(r => r.Code == reject.trim())?.Value ?? "Contact IT.";
        }

        dateFormatter(value: Date, key: string, item: any) {
            return moment.utc(value).format("L");
        }

        get disableIfMultiFill():boolean{
            return this.billOnlyMultilfills && !(this.selectedPlan?.plan.doesAllowsMultifill || this.selectedPlan?.plan.isOffline);
        }

        get billPlanToolTipText():string{
            let res = '';
            if(this.disableIfMultiFill)
                res = 'Multifills not allowed for selected plan';

            return res;
        }

    }

